import StoreState from '../storeState';
import EntityStatus from '../entityStatus';
import { patternCategoryAssignmentEquals } from './patternCategoryAssignmentsReducer';
import { createSelector } from 'reselect';
import { activeGlassTypeEntitySelector } from '../glass/glassTypesSelectors';
import { activeGlassAdjustmentEntitySelector } from '../glass/glassAdjustmentsSelectors';
import { activeGlassColorEntitySelector } from '../glass/glassColorsSelectors';
import { activeSceneThemeEntitySelector } from '../scene/sceneThemesSelectors';

/*
 * Shared selectors for pattern categories
 */
export const activePatternCategorySelector = (state: StoreState) => state.pattern.patternCategories.activePatternCategory;
const patternCategoryAssignments = (state: StoreState) => state.pattern.patternCategoryAssignments.patternCategoryAssignments;
export const patternCategoriesSelector = (state: StoreState) => state.pattern.patternCategories.patternCategories;
export const patternCategoriesStatusSelector = (state: StoreState) => state.pattern.patternCategories.status;
export const patternCategoryAssignmentsSelector = createSelector(
  [
    activeGlassAdjustmentEntitySelector,
    activeGlassColorEntitySelector,
    activeGlassTypeEntitySelector,
    activeSceneThemeEntitySelector,
    patternCategoryAssignments],
  (glassAdjustment, glassColor, glassType, sceneTheme, categoryAssignments) => {
    if (!glassAdjustment?.id || !glassColor?.id || !glassType?.id || !sceneTheme?.id) {
      return;
    }
    return categoryAssignments.find(
      assignment => assignment.status === EntityStatus.LOAD_SUCCESS &&
        patternCategoryAssignmentEquals(assignment.patternCategoryAssignments, {
          sceneThemeId: sceneTheme?.id as number,
          glassAdjustmentId: glassAdjustment?.id as number,
          glassColorId: glassColor?.id as number,
          glassTypeId: glassType?.id as number
        }));
  });