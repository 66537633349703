import LayoutState from './layoutState';
import { LayoutAction, LayoutActions } from './layoutActions';

const defaultState = {
  sidebarMinimized: false,
  menuItemOpened: undefined
};
export default (state: LayoutState = defaultState, action: LayoutActions) => {
  switch (action.type) {
    case LayoutAction.TOGGLE_VISIBILITY:
      return {
        ...state,
        sidebarMinimized: action.minimized
      };
    case LayoutAction.MENU_ITEM_OPENED: {
      return {
        ...state,
        menuItemOpened: action.menuItem
      };
    }
    default:
      return state;
  }
}