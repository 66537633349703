import React from 'react';
import progressImage from './ball-triangle.svg';
import styles from './Progress.module.scss';

interface Props {
  title?: string
}

const Progress: React.FC<Props> = (props) => {
  return <div className={styles.progress}><img src={progressImage} className={styles.progressImage} alt={props.title ?? ''} /></div>;
};

export default Progress;
