import React from 'react';
import styles from './SceneThemeSwitch.module.scss';
import IconBox from '../icons/IconBox';

interface Props {
  label: string,
  currentIndex: number,
  size: number,
  onClick: () => void
}

const SceneThemeSwitch: React.FC<Props> = (props) => {
  return <div onClick={props.onClick} className={styles.wrapper}>
    <div className={styles.inner}>
      <span className={styles.icon}><IconBox /></span><span>{props.label} {`${props.currentIndex + 1}`}</span><span
      className={styles.allSize}> /{props.size}</span></div>
  </div>;
};

export default SceneThemeSwitch;
