import React from 'react';
import { MenuItemProperties } from './Sidebar';
import MainMenuItem, { IconDirection } from '../../component/configurer/sidebar/MainMenuItem';
import useLocalizeMessage from '../../localization/localizeMessage';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../store/layout/layoutSelector';
import { activePatternSelector } from '../../store/pattern/patternSelectors';
import EntityStatus from '../../store/entityStatus';
import { activeGlassAdjustmentSelector } from '../../store/glass/glassAdjustmentsSelectors';

const MenuItemPattern: React.FC<MenuItemProperties> = (props) => {
  const localizeMessage = useLocalizeMessage();
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);
  const activePattern = useSelector(activePatternSelector);
  const activeGlassAdjustment = useSelector(activeGlassAdjustmentSelector);

  const menuItemVisible = !!activePattern.entity && activePattern.status === EntityStatus.LOAD_SUCCESS;
  return <>
    <MainMenuItem
      visible={menuItemVisible}
      active={false}
      label={localizeMessage(`sidebar.memuItem.label.PATTERN.${activeGlassAdjustment.entity?.alias.toUpperCase()}`)}
      subLabel={activePattern.entity?.title ?? ''}
      showLabel={!sidebarMinimized}
      showIcon={false}
      itemStatus={activePattern.status}
      onclick={props.onClick}
      iconDirection={IconDirection.DOWN}
      hasAction={false}
      imagePath={activePattern.entity?.thumbnail} />
  </>;
};
export default MenuItemPattern;
