import React, { useEffect } from 'react';
import StoreState from './../../../store/storeState';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setActiveAdjustmentAction } from '../../../store/glass/glassAdjustmentsActions';
import { loadAdjustmentAssignmentsRequestAction } from '../../../store/glass/glassAdjustmentsAssignmentsActions';
import {
  activeGlassAdjustmentSelector,
  adjustmentAssignmentsSelector,
  adjustmentsSelector,
  adjustmentsStatusSelector
} from '../../../store/glass/glassAdjustmentsSelectors';
import EntityStatus from '../../../store/entityStatus';
import activeAdjustmentResolver from './activeGlassAdjustmentResolver';
import { activeSceneVariantSelector } from '../../../store/scene/sceneVariantsSelectors';
import { loadGlassRequestAction } from '../../../store/glass/glassActions';
import { arraysEqual } from '../../../store/utils';
import { activeSceneThemeGroupSelector, activeSceneThemeStatusSelector } from '../../../store/scene/sceneThemesSelectors';

const adjustmentUrlAliasSelector = (state: StoreState) => state.glass.adjustments.adjustmentUrlAlias;

const ActiveAdjustmentProvider: React.FC = () => {
  const activeThemeStatus = useSelector(activeSceneThemeStatusSelector);
  const { status: activeSceneVariantStatus } = useSelector(activeSceneVariantSelector);
  const adjustmentAssignments = useSelector(adjustmentAssignmentsSelector);
  const adjustmentStatus = useSelector(adjustmentsStatusSelector);
  const adjustments = useSelector(adjustmentsSelector, arraysEqual);
  const adjustmentUrlAlias = useSelector(adjustmentUrlAliasSelector);
  const activeAdjustment = useSelector(activeGlassAdjustmentSelector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeSceneVariantStatus === EntityStatus.LOAD_SUCCESS) {
      const newActiveAdjustment = activeAdjustmentResolver(adjustmentStatus, activeAdjustment, adjustments, adjustmentAssignments, adjustmentUrlAlias);
      if (activeAdjustment.entity?.id !== newActiveAdjustment.entity?.id || activeAdjustment.status !== newActiveAdjustment.status) {
        dispatch(setActiveAdjustmentAction(newActiveAdjustment));
      }
    }
  }, [adjustmentStatus, activeAdjustment, adjustments, adjustmentAssignments, adjustmentUrlAlias, activeThemeStatus, activeSceneVariantStatus, dispatch]);

  return null;
};

const AdjustmentAssignmentsProvider: React.FC = () => {
  const activeThemeGroups = useSelector(activeSceneThemeGroupSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeThemeGroups.entity && activeThemeGroups.status === EntityStatus.LOAD_SUCCESS) {
      dispatch(loadAdjustmentAssignmentsRequestAction(activeThemeGroups.entity));
    }
  }, [activeThemeGroups, dispatch]);

  return null;
};

const GlassProvider: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadGlassRequestAction());
  }, [dispatch]);

  return null;
};

const GlassAdjustmentProviders: React.FC = () => {
  return <>
    <GlassProvider />
    <AdjustmentAssignmentsProvider />
    <ActiveAdjustmentProvider />
  </>;
};

export default GlassAdjustmentProviders;
