import { SceneVariantsActions, SceneVariantsActionType } from './sceneVariantsActions';
import SceneVariant from '../../service/domain/SceneVariant';
import { StateEntity } from '../StateEntity';
import EntityStatus from '../entityStatus';

export interface SceneVariantsState {
  activeVariant: StateEntity<SceneVariant>,
  sceneVariantUrlAlias?: string
}

const initialState = {
  activeVariant: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  sceneVariantUrlAlias: undefined
};

export default (state: SceneVariantsState = initialState, action: SceneVariantsActions): SceneVariantsState => {
  switch (action.type) {
    case SceneVariantsActionType.SET_ACTIVE:
      return {
        ...state,
        activeVariant: action.sceneVariant
      };
    case SceneVariantsActionType.SET_URL_ALIAS:
      return {
        ...state,
        sceneVariantUrlAlias: action.alias
      };
    default:
      return state;
  }
}