import React, { useEffect } from 'react';
import ConfigurerComponent from '../../component/configurer/Configurer';
import { ConfigurerUrlParameters } from '../ConfigurerRoute';
import Sidebar from './Sidebar';
import { useDispatch } from 'react-redux';
import { setSceneVariantsVariantUrlAction } from '../../store/scene/sceneVariantsActions';
import { setScenesSceneUrlAliasAction } from '../../store/scene/scenesActions';
import { IntlProvider } from 'react-intl';
import localeResolver, { findDefaultLocale, localeSupported } from '../../localization/localeResolver';
import localeMessageSourcesProvider from '../../localization/localeMessageSourcesProvider';
import { setAdjustmentUrlAliasAction } from '../../store/glass/glassAdjustmentsActions';
import { setPatternCategoryUrlAliasAction } from '../../store/pattern/patternCategoriesActions';
import { setPatternUrlAliasAction } from '../../store/pattern/patternsActions';
import { setSceneThemesThemeUrlAction } from '../../store/scene/sceneThemesActions';
import { setGlassTypeUrlAliasAction } from '../../store/glass/glassTypeActions';
import { setGlassColorUrlAliasAction } from '../../store/glass/glassColorsActions';
import Header from '../../component/configurer/header/Header';
import SceneCanvas from './canvas/Canvas';
import { useHistory } from 'react-router-dom';
import Scenes from './provider/Scenes';
import Adjustments from './provider/GlassAdjustments';
import GlassTypes from './provider/GlassTypes';
import GlassColors from './provider/GlassColors';
import PatternCategories from './provider/PatternCategories';
import Patterns from './provider/Patterns';
import SceneReportingProvider from './provider/SceneReportingProvider';

interface OwnProps {
  urlParameters: ConfigurerUrlParameters
}

type Props = OwnProps;

const useDispatchIfNotBlank = () => {
  const dispatch = useDispatch();
  return (callback: (url?: string) => void, value?: string) => {
    if (value?.trim()) {
      dispatch(callback(value));
    }
  };
};

const Configurer: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatchIfNotBlank();
  const history = useHistory();
  useEffect(() => {
    dispatch(setSceneVariantsVariantUrlAction, props.urlParameters.sceneVariant);
    dispatch(setScenesSceneUrlAliasAction, props.urlParameters.scene);
    dispatch(setAdjustmentUrlAliasAction, props.urlParameters.glassAdjustment);
    dispatch(setPatternCategoryUrlAliasAction, props.urlParameters.patternCategory);
    dispatch(setPatternUrlAliasAction, props.urlParameters.pattern);
    dispatch(setSceneThemesThemeUrlAction, props.urlParameters.sceneTheme);
    dispatch(setGlassTypeUrlAliasAction, props.urlParameters.glassType);
    dispatch(setGlassColorUrlAliasAction, props.urlParameters.glassColor);
  }, [props.urlParameters, dispatch]);

  useEffect(() => {
    const urlLocaleParameter = props.urlParameters.locale;
    if (urlLocaleParameter) {
      const hasCorrectLocaleUrl = history.location.pathname === `/${urlLocaleParameter}/`;
      const urlLocaleSupported = localeSupported(urlLocaleParameter);
      if (!hasCorrectLocaleUrl && urlLocaleSupported) {
        console.info(`Redirecting from correct locale location`, history.location.pathname, props.urlParameters.locale);
        history.push(`/${props.urlParameters.locale}/`);
      } else if (!urlLocaleSupported) {
        const defaultLocale = findDefaultLocale();
        console.info(`Redirecting from unsupported locale to supported`, history.location.pathname, props.urlParameters.locale, defaultLocale);
        history.push(`/${defaultLocale}/`);
      }
    }
  });

  const locale = localeResolver(props.urlParameters.locale);
  const localeMessageSourcesProviderElement = localeMessageSourcesProvider[locale];
  const dataProviders = <>
    <Scenes />
    <Adjustments />
    <GlassTypes />
    <GlassColors />
    <PatternCategories />
    <Patterns />
    <SceneReportingProvider />
  </>;

  return (
    <IntlProvider locale={locale} messages={localeMessageSourcesProviderElement}>
      {dataProviders}
      <ConfigurerComponent>
        <Header />
        <Sidebar />
        <SceneCanvas />
      </ConfigurerComponent>
    </IntlProvider>);
};

export default Configurer;
