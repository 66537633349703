import React from 'react';
import { MenuItemProperties } from './Sidebar';
import MainMenuItem, { IconDirection } from '../../component/configurer/sidebar/MainMenuItem';
import useLocalizeMessage from '../../localization/localizeMessage';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../store/layout/layoutSelector';
import useLocalizeEntityTitle from '../../localization/localizeEntityTitle';
import SidebarSubmenu, { Width } from '../../component/configurer/sidebar/SidebarSubmenu';
import { activeGlassAdjustmentSelector } from '../../store/glass/glassAdjustmentsSelectors';
import { activeGlassColorSelector } from '../../store/glass/glassColorsSelectors';
import { activeGlassTypeSelector } from '../../store/glass/glassTypesSelectors';
import EntityStatus from '../../store/entityStatus';
import GlassAdjustmentsSubMenu from './GlassAdjustmentsSubMenu';

const MenuItemGlass: React.FC<MenuItemProperties> = (props) => {
  const localizeMessage = useLocalizeMessage();
  const localizedEntityTitle = useLocalizeEntityTitle();
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);
  const activeGlassAdjustment = useSelector(activeGlassAdjustmentSelector);
  const activeGlassColor = useSelector(activeGlassColorSelector);
  const activeGlassType = useSelector(activeGlassTypeSelector);

  const allEntitiesLoading =
    activeGlassAdjustment.status !== EntityStatus.LOAD_FAILED &&
    activeGlassColor.status !== EntityStatus.LOAD_FAILED &&
    activeGlassType.status !== EntityStatus.LOAD_FAILED;
  const allEntitiesFilled = !!activeGlassAdjustment.entity && !!activeGlassColor.entity && !!activeGlassType.entity;
  return <>
    <MainMenuItem
      visible={allEntitiesLoading && allEntitiesFilled}
      active={props.subMenuVisible}
      label={localizeMessage('sidebar.memuItem.label.GLASS')}
      subLabel={localizedEntityTitle(activeGlassAdjustment.entity)}
      imagePath={activeGlassAdjustment.entity?.thumbnailImage}
      showLabel={!sidebarMinimized}
      showIcon={true}
      itemStatus={activeGlassAdjustment.status}
      iconDirection={IconDirection.RIGHT}
      hasAction={true}
      onclick={props.onClick} />
    <SidebarSubmenu onClick={props.onClick}
      visible={props.subMenuVisible}
      title={localizeMessage('sidebar.memuItem.submenu.label.GLASS')}
      width={Width.THIN}>
      <GlassAdjustmentsSubMenu
        visible={true}
        onClick={props.onClick}
        status={EntityStatus.LOAD_SUCCESS}
        activeGlassAdjustment={activeGlassAdjustment}
        activeGlassType={activeGlassType} />
    </SidebarSubmenu>
  </>;
};

export default MenuItemGlass;
