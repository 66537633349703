import React from 'react';
import styles from './Sidebar.module.scss';

const Sidebar: React.FC = (props) => {
  return (<div className={styles.sidebar}>
      <>{props.children}</>
  </div>);
};

export default Sidebar;
