import { createSelector } from 'reselect';
import { activeSceneThemeGroupEntitySelector } from '../scene/sceneThemesSelectors';
import { allScenesSelector } from '../scene/sceneSelectors';
import EntityStatus from '../entityStatus';
import { activeGlassAdjustmentSelector, adjustmentAssignmentSelector } from './glassAdjustmentsSelectors';
import { GlassAdjustmentAssignmentsItemState } from './glassState';

export const themesForSceneThemeGroupAndActiveGlassAdjustmentSelector = createSelector(
  [activeGlassAdjustmentSelector, adjustmentAssignmentSelector, allScenesSelector, activeSceneThemeGroupEntitySelector],
  (activeGlassAdjustment, adjustmentAssignments, allScenes, activeThemeGroup) => {
    const groupId = activeThemeGroup?.id;
    const activeAdjustmentId = activeGlassAdjustment.entity?.id;
    if (!groupId || !activeAdjustmentId) {
      return [];
    }
    const variantIdSet = findVariantsForActiveAdjustment(adjustmentAssignments, activeAdjustmentId);
    return allScenes
      .flatMap(scene => scene.variants)
      .filter(variant => variantIdSet.has(variant.id))
      .flatMap(variant => variant.themes)
      .filter(theme => theme.sceneThemeGroupId === groupId);
  });

function findVariantsForActiveAdjustment(adjustmentAssignments: GlassAdjustmentAssignmentsItemState[], activeAdjustmentId: number) {
  const variantIds = adjustmentAssignments
    .filter(assignment => assignment.status === EntityStatus.LOAD_SUCCESS)
    .flatMap(assiggnment => assiggnment.adjustmentAssignments)
    .filter(assiggnment => assiggnment.adjustmentId === activeAdjustmentId)
    .map(adjustment => adjustment.sceneVariantId);
  return new Set<number>(variantIds);
}