import React from 'react';
import sidebarStyles from './SidebarFooter.module.scss';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../../store/layout/layoutSelector';

const SidebarFooter: React.FC = () => {
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);
  const rootClass = sidebarMinimized ? sidebarStyles.linksContainerMinimized : sidebarStyles.linksContainer;
  return (
    <div className={rootClass}>
      <div className={sidebarStyles.links}>
        <div className={sidebarStyles.linkItem}>Developed by <a href="http://www.cimbalek.net" title="Přemysl Cimbálek"
          className={sidebarStyles.link}>Cimbálek</a></div>
        <div className={sidebarStyles.linkItem}>Designed by <a href="https://www.studio9.cz/" title="Studio 9" className={sidebarStyles.link}>Studio 9</a>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
