import React from 'react';
import Scene from '../../service/domain/Scene';
import EntityStatus from '../../store/entityStatus';
import { useDispatch, useSelector } from 'react-redux';
import SubmenuItems, { ItemType, SidebarItemsMenuSize } from '../../component/configurer/sidebar/SubmenuItems';
import { setActiveSceneAction } from '../../store/scene/scenesActions';
import { allScenesSelector } from '../../store/scene/sceneSelectors';
import { StateEntity } from '../../store/StateEntity';

interface Props {
  activeScene: StateEntity<Scene>,
  onClick: () => void,
  visible: boolean
}

const SceneSubMenu: React.FC<Props> = (props) => {
  const scenes = useSelector(allScenesSelector);
  const dispatch = useDispatch();
  const onItemClick = (item: ItemType) => () => {
    const scene = item as Scene;
    if (scene !== props.activeScene.entity) {
      dispatch(setActiveSceneAction({
        entity: scene,
        status: EntityStatus.LOAD_SUCCESS
      }));
    }
    props.onClick();
  };

  return <SubmenuItems items={scenes}
    showItemTitle={true}
    activeItem={props.activeScene.entity}
    onItemClick={onItemClick}
    visible={props.visible}
    onClick={props.onClick}
    itemsStatus={props.activeScene.status}
    itemsSize={SidebarItemsMenuSize.LARGE}
    level={1} />;
};

export default SceneSubMenu;
