import StoreState from '../storeState';
import { createSelector } from 'reselect';
import { activeSceneVariantEntitySelector, variantsForSceneThemeGroupSelector } from '../scene/sceneVariantsSelectors';

/*
 * Shared selectors for scene
 */
export const adjustmentAssignmentSelector = (state: StoreState) => state.glass.adjustmentAssignments.adjustmentAssignments;
export const activeGlassAdjustmentSelector = (state: StoreState) => state.glass.adjustments.activeAdjustment;
export const activeGlassAdjustmentStatusSelector = (state: StoreState) => state.glass.adjustments.activeAdjustment.status;
export const activeGlassAdjustmentEntitySelector = (state: StoreState) => state.glass.adjustments.activeAdjustment.entity;
export const adjustmentsSelector = (state: StoreState) => state.glass.adjustments.adjustments;
export const adjustmentsStatusSelector = (state: StoreState) => state.glass.adjustments.status;
export const adjustmentAssignmentsSelector = createSelector([activeSceneVariantEntitySelector, adjustmentAssignmentSelector],
  (activeSceneVariant, adjustmentAssignment) => {
    if (!activeSceneVariant) {
      return;
    }
    return adjustmentAssignment.find(assignment => assignment.sceneVariantId === activeSceneVariant?.id);
  });

export const adjustmentAssignmentsForThemeGroupSelector = createSelector([variantsForSceneThemeGroupSelector, adjustmentAssignmentSelector],
  (variants, adjustmentAssignments) => {
    if (!variants || variants.length < 1) {
      return [];
    }
    const variantIds = new Set<number>(variants.map(item => item.id));
    return adjustmentAssignments
      .filter(assignment => variantIds.has(assignment.sceneVariantId))
      .flatMap(item => item.adjustmentAssignments);
  });