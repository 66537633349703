export class UrlBuilder {
  private readonly url: string;
  private readonly parameters: Map<string, any>;

  private constructor(urlBase: string, url?: string) {
    this.url = urlBase + (url ?? '');
    this.parameters = new Map<string, any>();
  }

  static fromBaseUrl(urlBase: string, url?: string): UrlBuilder {
    return new UrlBuilder(urlBase, url);
  }

  withParameter(name: string, value: any | undefined): UrlBuilder {
    this.parameters.set(name, value);
    return this;
  }

  build(): string {
    let result = this.url;
    const containsParameter = Array.from(this.parameters.values()).filter(value => value !== undefined).length;
    if (containsParameter) {
      result = result + '?';
    }
    const parameters = Array.from(this.parameters.entries())
      .filter(entry => entry[1] !== undefined)
      .map(entry => `${entry[0]}=${entry[1]}`)
      .join('&');

    return result + parameters;
  }
}

export class FormDataBuilder {
  private readonly data = new Map<string, any>();

  private constructor() {};

  static newInstance(): FormDataBuilder {
    return new FormDataBuilder();
  }

  withData(name: string, value: any | undefined): FormDataBuilder {
    this.data.set(name, value);
    return this;
  }

  build(): FormData {
    const formData = new FormData();
    this.data.forEach((value, key) => {
      if (value !== undefined) {
        formData.set(key, value);
      }
    });
    return formData;
  }
}