import SceneVariant from '../../service/domain/SceneVariant';
import GlassColorAssignment from '../../service/domain/GlassColorAssignment';

export enum GlassColorAssignmentsActionType {
  LOAD_SUCCESS = 'GLASS_COLORS_ASSIGNMENTS_LOAD_SUCCESS',
  LOAD_FAILED = 'GLASS_COLORS_ASSIGNMENTS_LOAD_FAILED',
  LOAD_REQUEST = 'GLASS_COLORS_ASSIGNMENTS_LOAD_REQUEST',
  LOAD_PROGRESS = 'GLASS_COLORS_ASSIGNMENTS_LOAD_PROGRESS'
}

export type GlassColorsAssignmentsActions =
  GLassColorAssignmentsLoadRequestAction
  | GLassColorAssignmentsLoadFailedAction
  | GLassColorAssignmentsLoadSuccessAction
  | GLassColorAssignmentsLoadProgressAction;

export function loadGLassColorAssignmentsRequestAction(sceneVariant: SceneVariant): GLassColorAssignmentsLoadRequestAction {
  return {
    type: GlassColorAssignmentsActionType.LOAD_REQUEST,
    sceneVariant
  };
}

export function loadGLassColorAssignmentsFailedAction(sceneVariant: SceneVariant): GLassColorAssignmentsLoadFailedAction {
  return {
    type: GlassColorAssignmentsActionType.LOAD_FAILED,
    sceneVariant
  };
}

export function loadGLassColorAssignmentsSuccessAction(
  glassColorAssignments: GlassColorAssignment[],
  sceneVariant: SceneVariant): GLassColorAssignmentsLoadSuccessAction {
  return {
    type: GlassColorAssignmentsActionType.LOAD_SUCCESS,
    sceneVariant,
    glassColorAssignments
  };
}

export function loadGLassColorAssignmentsProgressAction(sceneVariant: SceneVariant): GLassColorAssignmentsLoadProgressAction {
  return {
    type: GlassColorAssignmentsActionType.LOAD_PROGRESS,
    sceneVariant
  };
}

interface GLassColorAssignmentsAction {
  sceneVariant: SceneVariant
}

export interface GLassColorAssignmentsLoadRequestAction extends GLassColorAssignmentsAction {
  type: typeof GlassColorAssignmentsActionType.LOAD_REQUEST
}

interface GLassColorAssignmentsLoadProgressAction extends GLassColorAssignmentsAction {
  type: typeof GlassColorAssignmentsActionType.LOAD_PROGRESS
}

interface GLassColorAssignmentsLoadFailedAction extends GLassColorAssignmentsAction {
  type: typeof GlassColorAssignmentsActionType.LOAD_FAILED
}

interface GLassColorAssignmentsLoadSuccessAction extends GLassColorAssignmentsAction {
  type: typeof GlassColorAssignmentsActionType.LOAD_SUCCESS,
  glassColorAssignments: GlassColorAssignment[]
}