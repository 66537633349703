import GlassAdjustmentAssignment from './domain/GlassAdjustmentAssignment';
import configuration from './configuration';
import { SearchParameters } from './dto/SearchParameters';
import GlassAdjustment from './domain/GlassAdjustment';
import { UrlBuilder } from './fetchHelper';
import SceneThemeGroup from './domain/SceneThemeGroup';

interface GlassAdjustmentSearchParameters extends SearchParameters {
  sceneVariantId?: number,
  onlyActive: boolean
}

class GlassAdjustmentService {
  findAssignmentsForScene(sceneVariantId: number): Promise<GlassAdjustmentAssignment[]> {
    const url = UrlBuilder.fromBaseUrl(configuration.publicApiUrl, '/glass-adjustment-assignment')
      .withParameter('sceneVariantId', sceneVariantId)
      .build();
    return fetch(url).then(value => value.json());
  }

  findAssignmentsForThemeGroup(sceneThemeGroup: SceneThemeGroup): Promise<GlassAdjustmentAssignment[]> {
    const url = UrlBuilder.fromBaseUrl(configuration.publicApiUrl, '/glass-adjustment-assignment-group')
      .withParameter('sceneThemeGroupId', sceneThemeGroup.id)
      .build();
    return fetch(url).then(value => value.json());
  }

  findAssignments(glassAdjustmentId?: number, sceneVariantId?: number, onlyActive?: boolean): Promise<GlassAdjustmentAssignment[]> {
    const url = UrlBuilder.fromBaseUrl(configuration.adminApiUrl, '/glass-adjustment-assignments')
      .withParameter('sceneVariantId', sceneVariantId)
      .withParameter('glassAdjustmentId', glassAdjustmentId)
      .withParameter('onlyActive', onlyActive)
      .build();

    return fetch(url).then(value => value.json());
  }

  saveAssignments(assignments: GlassAdjustmentAssignment[], sceneVariantId: number): Promise<GlassAdjustmentAssignment[]> {
    return fetch(`${configuration.adminApiUrl}/glass-adjustment-assignments/save?sceneVariantId=${sceneVariantId}`, {
      method: 'POST',
      body: JSON.stringify(assignments)
    }).then(value => {
      return value.json();
    });
  };

  search(searchParameters: GlassAdjustmentSearchParameters) {
    return searchParameters.onlyActive
      ? fetch(`${configuration.publicApiUrl}/glass-adjustments?sceneVariantId=${searchParameters.sceneVariantId}`).then(value => {
        return value.json();
      })
      : fetch(`${configuration.adminApiUrl}/glass-adjustments?onlyActive=${searchParameters.onlyActive}`).then(value => {
        return value.json();
      });
  }

  findById(id: number): Promise<GlassAdjustment> {
    return fetch(`${configuration.adminApiUrl}/glass-adjustments/${id}`).then(value => {
      return value.json();
    });
  }

  save(glassAdjustment: GlassAdjustment): Promise<GlassAdjustment> {
    return fetch(`${configuration.adminApiUrl}/glass-adjustments/save/${glassAdjustment.id}`, {
      method: 'POST',
      body: JSON.stringify(glassAdjustment)
    }).then(value => {
      return value.json();
    });
  };

  findAll(onlyActive: boolean): Promise<Array<GlassAdjustment>> {
    return fetch(`${configuration.adminApiUrl}/glass-adjustments?onlyActive=${onlyActive}`).then(value => {
      return value.json();
    });
  }
}

export default new GlassAdjustmentService();