/**
 * Status of the entity in the store from technical point of view
 */
import { LoadingStatus } from '../service/domain/admin/loadingStatus';

enum EntityStatus {
  NOT_LOADED = 'NOT_LOADED', //entity is not loaded or saved yet
  LOAD_IN_PROGRESS = 'LOAD_IN_PROGRESS', //loading is in progress
  LOAD_FAILED = 'LOAD_FAILED', //load failed
  LOAD_SUCCESS = 'LOAD_SUCCESS' //load succeeded
}

export const toLoadingStatus = (entityStatus: EntityStatus): LoadingStatus => {
  switch (entityStatus) {
    case EntityStatus.LOAD_FAILED:
      return LoadingStatus.ERROR;
    case EntityStatus.NOT_LOADED:
      return LoadingStatus.NOT_LOADING;
    case EntityStatus.LOAD_IN_PROGRESS:
      return LoadingStatus.IN_PROGRESS;
    case EntityStatus.LOAD_SUCCESS:
      return LoadingStatus.SUCCESSFUL;
  }
};

export default EntityStatus;