import React from 'react';
import { MenuItemProperties } from './Sidebar';
import MainMenuItem, { IconDirection } from '../../component/configurer/sidebar/MainMenuItem';
import useLocalizeMessage from '../../localization/localizeMessage';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../store/layout/layoutSelector';
import { activeSceneVariantSelector, activeSceneVariantsSelector } from '../../store/scene/sceneVariantsSelectors';
import { activeSceneThemeGroupSelector, sceneThemesSelector } from '../../store/scene/sceneThemesSelectors';
import useLocalizeEntityTitle from '../../localization/localizeEntityTitle';
import { activeSceneSelector, activeSceneStatusSelector } from '../../store/scene/sceneSelectors';
import SidebarSubmenu, { Width } from '../../component/configurer/sidebar/SidebarSubmenu';
import SceneColorsSubMenu from './SceneColorsSubMenu';
import EntityStatus from '../../store/entityStatus';

const MenuItemSceneColors: React.FC<MenuItemProperties> = (props) => {
  const localizeMessage = useLocalizeMessage();
  const localizedEntityTitle = useLocalizeEntityTitle();
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);
  const activeScene = useSelector(activeSceneSelector);
  const activeSceneVariant = useSelector(activeSceneVariantSelector);
  const activeSceneStatus = useSelector(activeSceneStatusSelector);
  const activeThemeGroup = useSelector(activeSceneThemeGroupSelector);
  const allEntitiesLoading = (activeSceneVariant.status === EntityStatus.LOAD_SUCCESS || activeSceneVariant.status === EntityStatus.LOAD_IN_PROGRESS) &&
    (activeThemeGroup.status === EntityStatus.LOAD_SUCCESS || activeThemeGroup.status === EntityStatus.LOAD_IN_PROGRESS);
  const allEntitiesFilled = !!activeSceneVariant.entity && !!activeThemeGroup.entity;
  const themes = useSelector(sceneThemesSelector);
  const variants = useSelector(activeSceneVariantsSelector);
  const localizationPostfix = activeScene.entity?.alias.toUpperCase();
  return <>
    <MainMenuItem
      visible={allEntitiesLoading && allEntitiesFilled && (variants.length > 1 || themes.length > 1)}
      active={props.subMenuVisible}
      label={localizeMessage(`sidebar.memuItem.label.${localizationPostfix}`)}
      imagePath={activeThemeGroup.entity?.thumbnailImage}
      subLabel={localizedEntityTitle(activeThemeGroup.entity)}
      showLabel={!sidebarMinimized}
      showIcon={true}
      iconDirection={IconDirection.RIGHT}
      itemStatus={activeThemeGroup.status}
      hasAction={true}
      onclick={props.onClick} />
    <SidebarSubmenu
      onClick={props.onClick}
      visible={props.subMenuVisible}
      title={localizeMessage(`sidebar.memuItem.detailmenu.label.${localizationPostfix}`)}
      width={Width.WIDE}>
      <SceneColorsSubMenu
        sceneStatus={activeSceneStatus}
        onClick={props.onClick}
        visible={true}
        activeThemeGroup={activeThemeGroup}
        activeScene={activeScene} />
    </SidebarSubmenu>
  </>;
};

export default MenuItemSceneColors;
