import { ImageSize, SupportedImageSize } from './dto/imageSize';

export default {
  publicApiUrl: '/api/public',
  adminApiUrl: '/api/admin',
  publicImageApiUrl: '/api/public/images',
  productionReportingTrackerId: 'UA-168611621-1',
  imageSizes: [
    {
      sizeType: SupportedImageSize.CGA,
      priority: 100,
      width: 346,
      height: 195
    },
    {
      sizeType: SupportedImageSize.FWVGA,
      priority: 100,
      width: 854,
      height: 480
    },
    {
      sizeType: SupportedImageSize.HD720,
      priority: 90,
      width: 1280,
      height: 720
    },
    {
      sizeType: SupportedImageSize.HD768,
      priority: 80,
      width: 1366,
      height: 768
    },
    {
      sizeType: SupportedImageSize.HD1080,
      priority: 70,
      width: 1920,
      height: 1080
    }
  ] as ImageSize[],
  defaultImageSize: function (): ImageSize {return this.imageSizes.find(item => item.sizeType === SupportedImageSize.HD1080) as ImageSize;}
};