import React, { useEffect, useRef, useState } from 'react';
import style from './DetailMenu.module.scss';
import IconCross from '../icons/IconCross';

export interface DetailMenuProps {
  title?: string,
  visible: boolean,
  onCloseClick: () => void
}

const DetailMenu: React.FC<DetailMenuProps> = (props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [height, setHeight] = useState(0);
  const [remainingRefreshes, setRemainingRefreshes] = useState(10);

  useEffect(() => {
    if (remainingRefreshes > 0) {
      window.setTimeout(() => {
        if (ref != null && ref.current && ref.current.offsetHeight > 0) {
          setRemainingRefreshes(remainingRefreshes - 1);
          setHeight(ref.current.offsetHeight + 25);
        }
      }, 100);
    }
  }, [setHeight, ref, remainingRefreshes]);

  return <>
    {props.visible && <>
      <div className={style.arrow} />
      <div className={style.detailMenu} ref={ref}>
        <div className={style.detailMenuHeader}>
          {props.title && <div className={style.detailMenuHeaderTitle}>{props.title}
            <div className={style.detailMenuHeaderIcon} onClick={props.onCloseClick}>
              <IconCross />
            </div>
          </div>}
        </div>
        {props.children}
      </div>
      <div style={{ height: height }} />
    </>
    }
  </>;
};

export default DetailMenu;
