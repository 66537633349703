import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { activeGlassAdjustmentSelector } from '../../../store/glass/glassAdjustmentsSelectors';
import { activePatternSelector, loadedPatternsSelector, patternsStatusSelector, patternUrlAliasSelector } from '../../../store/pattern/patternSelectors';
import { loadPatternsRequestAction, setActivePatternAction, setPrevNextPatternAction } from '../../../store/pattern/patternsActions';
import { resolveActivePattern } from './activePatternResolver';
import { activeGlassTypeSelector } from '../../../store/glass/glassTypesSelectors';
import { activeGlassColorSelector } from '../../../store/glass/glassColorsSelectors';
import Pattern from '../../../service/domain/Pattern';
import { activeSceneThemeSelector } from '../../../store/scene/sceneThemesSelectors';
import { arraysEqual } from '../../../store/utils';
import EntityStatus from '../../../store/entityStatus';

interface PrevNextPatterns {
  previous: Pattern[],
  next: Pattern[]
}

function findPrevNextPattern(patterns: Pattern[], activePattern?: Pattern): PrevNextPatterns {
  const activeItemIndex = patterns.findIndex(item => item.id === (activePattern as Pattern).id);
  const isActiveLastItem = activeItemIndex === patterns.length - 1;
  return activeItemIndex > 0
    ? isActiveLastItem
      ? {
        previous: patterns.slice(activeItemIndex - 1, activeItemIndex).reverse(),
        next: []
      }
      : {
        previous: patterns.slice(activeItemIndex - 1, activeItemIndex).reverse(),
        next: patterns.slice(activeItemIndex + 1, activeItemIndex + 2)
      }
    : activeItemIndex === 0
      ? {
        previous: [],
        next: patterns.slice(activeItemIndex + 1, activeItemIndex + 2)
      }
      : {
        previous: [],
        next: []
      };
}

const PatternsNextPrevProvider: React.FC = () => {
  const patterns = useSelector(loadedPatternsSelector, arraysEqual);
  const activePattern = useSelector(activePatternSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (patterns && activePattern.entity) {
      const prevNextPatterns = findPrevNextPattern(patterns, activePattern.entity);
      dispatch(setPrevNextPatternAction(prevNextPatterns.previous, prevNextPatterns.next));
    }
  }, [patterns, activePattern.entity, dispatch]);
  return <></>;
};

const PatternsActiveProvider: React.FC = () => {
  const patternsStatus = useSelector(patternsStatusSelector);
  const patterns = useSelector(loadedPatternsSelector, arraysEqual);
  const activePattern = useSelector(activePatternSelector, shallowEqual);
  const activePatternUrlAlias = useSelector(patternUrlAliasSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    const newActivePattern = resolveActivePattern(patternsStatus, patterns, activePattern, activePatternUrlAlias);
    if (activePattern.entity?.id !== newActivePattern.entity?.id || activePattern.status !== newActivePattern.status) {
      dispatch(setActivePatternAction(newActivePattern));
    }
  }, [patternsStatus, patterns, activePattern, activePatternUrlAlias, dispatch]);
  return <></>;
};

const PatternsProvider: React.FC = () => {
  const activeGlassType = useSelector(activeGlassTypeSelector, shallowEqual);
  const activeGlassColor = useSelector(activeGlassColorSelector, shallowEqual);
  const activeGlassAdjustment = useSelector(activeGlassAdjustmentSelector, shallowEqual);
  const activeSceneTheme = useSelector(activeSceneThemeSelector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    const allEntitiesLoaded =
      activeGlassType.status === EntityStatus.LOAD_SUCCESS &&
      activeGlassColor.status === EntityStatus.LOAD_SUCCESS &&
      activeSceneTheme.status === EntityStatus.LOAD_SUCCESS &&
      activeGlassAdjustment.status === EntityStatus.LOAD_SUCCESS;
    if (allEntitiesLoaded && !!activeGlassType.entity && !!activeGlassColor.entity && !!activeGlassAdjustment.entity &&
      !!activeSceneTheme.entity) {
      dispatch(loadPatternsRequestAction({
        sceneThemeId: activeSceneTheme.entity.id,
        glassTypeId: activeGlassType.entity.id,
        glassColorId: activeGlassColor.entity.id,
        glassAdjustmentId: activeGlassAdjustment.entity.id,
        size: 40
      }));
    }
  }, [activeGlassType, activeGlassColor, activeGlassAdjustment, activeSceneTheme, dispatch]);
  return (<></>);
};

const Patterns: React.FC = () => {
  return <>
    <PatternsProvider />
    <PatternsActiveProvider />
    <PatternsNextPrevProvider />
  </>;
};

export default Patterns;
