import GlassColor from '../../service/domain/GlassColor';
import { StateEntity } from '../StateEntity';

export enum GlassColorsActionType {
  LOAD_SUCCESS = 'GLASS_COLORS_LOAD_SUCCESS',
  LOAD_FAILED = 'GLASS_COLORS_LOAD_FAILED',
  LOAD_REQUEST = 'GLASS_COLORS_LOAD_REQUEST',
  LOAD_PROGRESS = 'GLASS_COLORS_LOAD_PROGRESS',
  SET_ACTIVE = 'GLASS_COLORS_SET_ACTIVE',
  SET_URL_ALIAS = 'GLASS_COLORS_URL_ALIAS'
}

export type GlassColorsActionTypes =
  GlassColorLoadRequestAction
  | GlassColorLoadFailedAction
  | GlassColorLoadSuccessAction
  | GlassColorLoadProgressAction
  | SetActiveGlassColorAction
  | SetGlassColorUrlAliasAction;

export function loadGlassColorRequestAction(): GlassColorLoadRequestAction {
  return {
    type: GlassColorsActionType.LOAD_REQUEST
  };
}

export function loadGlassColorFailedAction(): GlassColorLoadFailedAction {
  return {
    type: GlassColorsActionType.LOAD_FAILED
  };
}

export function loadGlassColorSuccessAction(glassColors: GlassColor[]): GlassColorLoadSuccessAction {
  return {
    type: GlassColorsActionType.LOAD_SUCCESS,
    glassColors
  };
}

export function loadGlassColorProgressAction(): GlassColorLoadProgressAction {
  return {
    type: GlassColorsActionType.LOAD_PROGRESS
  };
}

export function setActiveGlassColorAction(glassColor: StateEntity<GlassColor>): SetActiveGlassColorAction {
  return {
    type: GlassColorsActionType.SET_ACTIVE,
    glassColor
  };
}

export function setGlassColorUrlAliasAction(alias?: string): SetGlassColorUrlAliasAction {
  return {
    type: GlassColorsActionType.SET_URL_ALIAS,
    alias
  };
}

interface GlassColorLoadRequestAction {
  type: typeof GlassColorsActionType.LOAD_REQUEST,
}

interface GlassColorLoadProgressAction {
  type: typeof GlassColorsActionType.LOAD_PROGRESS,
}

interface GlassColorLoadFailedAction {
  type: typeof GlassColorsActionType.LOAD_FAILED,
}

interface GlassColorLoadSuccessAction {
  type: typeof GlassColorsActionType.LOAD_SUCCESS,
  glassColors: GlassColor[]
}

export interface SetActiveGlassColorAction {
  type: typeof GlassColorsActionType.SET_ACTIVE,
  glassColor: StateEntity<GlassColor>
}

interface SetGlassColorUrlAliasAction {
  type: typeof GlassColorsActionType.SET_URL_ALIAS,
  alias?: string
}