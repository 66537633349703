import { combineReducers } from 'redux';
import adjustmentAssignmentsReducer from './glassAdjustmentsAssignmentsReducer';
import glassAdjustmentsReducer from './glassAdjustmentsReducer';
import glassTypeReducer from './glassTypesReducer';
import glassTypesAssignmentsReducer from './glassTypesAssignmentsReducer';
import glassColorsReducer from './glassColorsReducer';
import glassColorsAssignmentsReducer from './glassColorsAssignmentsReducer';

export default combineReducers({
  adjustments: glassAdjustmentsReducer,
  adjustmentAssignments: adjustmentAssignmentsReducer,
  types: glassTypeReducer,
  typeAssignments: glassTypesAssignmentsReducer,
  colors: glassColorsReducer,
  colorAssignments: glassColorsAssignmentsReducer
});