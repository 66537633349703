import React from 'react';

const IconShare: React.FC = () => {
  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 22 26">
    <g transform="translate(0,-952.36218)">
      <path fill="currentColor" d="M17.724,952.537c-2.288,0-4.163,1.875-4.163,4.163c0,0.443,0.07,0.879,0.2,1.281l-6.874,3.823
  c-0.716-0.582-1.624-0.941-2.612-0.941c-2.288,0-4.163,1.875-4.163,4.163c0,2.288,1.875,4.163,4.163,4.163
  c0.988,0,1.895-0.349,2.612-0.931l6.884,3.822c-0.131,0.403-0.21,0.826-0.21,1.271c0,2.288,1.875,4.163,4.163,4.163
  s4.163-1.875,4.163-4.163s-1.875-4.163-4.163-4.163c-1.149,0-2.196,0.48-2.952,1.241l-6.694-3.722
  c0.229-0.514,0.36-1.085,0.36-1.681c0-0.599-0.129-1.165-0.36-1.681l6.684-3.722c0.756,0.767,1.808,1.241,2.962,1.241
  c2.288,0,4.163-1.875,4.163-4.163C21.887,954.412,20.012,952.537,17.724,952.537L17.724,952.537z M17.724,954.458
  c1.249,0,2.241,0.992,2.241,2.241c0,1.249-0.992,2.241-2.241,2.241s-2.241-0.992-2.241-2.241
  C15.483,955.45,16.475,954.458,17.724,954.458z M4.276,962.783c1.249,0,2.241,0.992,2.241,2.241s-0.992,2.242-2.241,2.242
  s-2.241-0.992-2.241-2.242S3.026,962.783,4.276,962.783z M17.724,971.109c1.249,0,2.241,0.992,2.241,2.241
  s-0.992,2.241-2.241,2.241s-2.241-0.992-2.241-2.241S16.475,971.109,17.724,971.109z" />
    </g>
  </svg>;
};

export default IconShare;
