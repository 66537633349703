import { GlassTypesState } from './glassState';
import EntityStatus from '../entityStatus';
import { GlassTypesActions, GlassTypesActionType } from './glassTypeActions';

const initialState: GlassTypesState = {
  types: [],
  status: EntityStatus.NOT_LOADED,
  activeType: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  typeUrlAlias: undefined
};

export default (state: GlassTypesState = initialState, action: GlassTypesActions): GlassTypesState => {
  switch (action.type) {
    case GlassTypesActionType.LOAD_SUCCESS:
      return {
        ...state,
        types: action.glassTypes,
        status: EntityStatus.LOAD_SUCCESS
      };
    case GlassTypesActionType.LOAD_FAILED:
      return {
        ...state,
        types: [],
        status: EntityStatus.LOAD_FAILED
      };
    case GlassTypesActionType.LOAD_REQUEST:
      return {
        ...state,
        types: [],
        status: EntityStatus.NOT_LOADED
      };
    case GlassTypesActionType.LOAD_PROGRESS:
      return {
        ...state,
        types: [],
        status: EntityStatus.LOAD_IN_PROGRESS
      };
    case GlassTypesActionType.SET_ACTIVE:
      return {
        ...state,
        activeType: action.glassType
      };
    case GlassTypesActionType.SET_URL_ALIAS:
      return {
        ...state,
        typeUrlAlias: action.alias
      };
    default:
      return state;
  }
}