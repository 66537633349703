import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { patternsPagingSelector, patternsPagingStateEquals } from '../../../store/pattern/patternSelectors';
import { useSwipeable } from 'react-swipeable';
import styles from '../../../component/configurer/canvas/Canvas.module.scss';
import Pattern from '../../../service/domain/Pattern';
import { setActivePatternAction } from '../../../store/pattern/patternsActions';
import EntityStatus from '../../../store/entityStatus';

const usePrevNextCallbacks = (previousPatterns: Pattern[], nextPatterns: Pattern[]) => {
  const dispatch = useDispatch();
  const rightCallback = previousPatterns.length ? () => dispatch(setActivePatternAction({
    entity: previousPatterns[0],
    status: EntityStatus.LOAD_SUCCESS
  })) : undefined;
  const leftCallback = nextPatterns.length ? () => dispatch(setActivePatternAction({
    entity: nextPatterns[0],
    status: EntityStatus.LOAD_SUCCESS
  })) : undefined;
  return {
    rightCallback,
    leftCallback
  };
};

const CanvasSwipeHandler: React.FC = React.memo(() => {
  const patternsPaging = useSelector(patternsPagingSelector, patternsPagingStateEquals);
  const callbacks = usePrevNextCallbacks(patternsPaging.previousPatterns, patternsPaging.nextPatterns);

  const swipe = useSwipeable({
    onSwipedLeft: callbacks.leftCallback,
    onSwipedRight: callbacks.rightCallback
  });
  return <div {...swipe} className={styles.swipeArea} />;
});

const CanvasKeySwipeHandler: React.FC = React.memo(() => {
  const eventType = 'keydown';
  const patternsPaging = useSelector(patternsPagingSelector, patternsPagingStateEquals);

  const callbacks = usePrevNextCallbacks(patternsPaging.nextPatterns, patternsPaging.previousPatterns);

  const keyEventListener = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        callbacks.leftCallback && callbacks.leftCallback();
        break;
      case 'ArrowRight':
        callbacks.rightCallback && callbacks.rightCallback();
        break;
    }
  };
  useEffect(() => {
    document.addEventListener(eventType, keyEventListener);
    return () => {
      document.removeEventListener(eventType, keyEventListener);
    };
  });
  return <></>;
});

const Swipe: React.FC = React.memo(() => {
  return <> <CanvasSwipeHandler />
    <CanvasKeySwipeHandler /></>;
});
export default Swipe;