import { ScenesActions, ScenesActionType } from './scenesActions';
import Scene from '../../service/domain/Scene';
import EntityStatus from './../entityStatus';
import { combineReducers } from 'redux';
import sceneVariantsReducer from './sceneVariantsReducer';
import sceneThemesReducer from './sceneThemesReducer';
import { StateEntity } from '../StateEntity';

export interface ScenesState {
  scenes: Scene[],
  activeScene: StateEntity<Scene>,
  status: EntityStatus,
  sceneUrlAlias?: string
}

const initialState = {
  scenes: [],
  status: EntityStatus.NOT_LOADED,
  activeScene: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  sceneUrlAlias: undefined
};

const scenesReducer = (state: ScenesState = initialState, action: ScenesActions): ScenesState => {
  switch (action.type) {
    case ScenesActionType.LOAD_SUCCESS:
      return {
        ...state,
        scenes: [...action.scenes],
        status: EntityStatus.LOAD_SUCCESS
      };
    case ScenesActionType.LOAD_FAILED:
      return {
        ...state,
        scenes: [],
        status: EntityStatus.LOAD_FAILED
      };
    case ScenesActionType.LOAD_REQUEST:
      return {
        ...state,
        scenes: [],
        status: EntityStatus.NOT_LOADED
      };
    case ScenesActionType.LOAD_PROGRESS:
      return {
        ...state,
        scenes: [],
        status: EntityStatus.LOAD_IN_PROGRESS
      };
    case ScenesActionType.SET_ACTIVE:
      return {
        ...state,
        activeScene: action.scene
      };
    case ScenesActionType.SET_SCENE_URL_ALIAS:
      return {
        ...state,
        sceneUrlAlias: action.alias
      };
    default:
      return state;
  }
};

export default combineReducers({
  scenes: scenesReducer,
  sceneVariants: sceneVariantsReducer,
  sceneThemes: sceneThemesReducer
});
