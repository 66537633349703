import GlassAdjustment from '../../service/domain/GlassAdjustment';
import { StateEntity } from '../StateEntity';

export enum GlassAdjustmentsActionType {
  LOAD_SUCCESS = 'ADJUSTMENTS_LOAD_SUCCESS',
  LOAD_FAILED = 'ADJUSTMENTS_LOAD_FAILED',
  LOAD_REQUEST = 'ADJUSTMENTS_LOAD_REQUEST',
  LOAD_PROGRESS = 'ADJUSTMENTS_LOAD_PROGRESS',
  SET_ACTIVE = 'ADJUSTMENTS_SET_ACTIVE',
  SET_URL_ALIAS = 'ADJUSTMENTS_URL_ALIAS'
}

export type GlassAdjustmentsActions =
  AdjustmentsLoadRequestAction
  | AdjustmentsLoadFailedAction
  | AdjustmentsLoadSuccessAction
  | AdjustmentsLoadProgressAction
  | SetActiveAdjustmentAction
  | SetAdjustmentUrlAliasAction;

export function loadAdjustmentsRequestAction(): AdjustmentsLoadRequestAction {
  return {
    type: GlassAdjustmentsActionType.LOAD_REQUEST
  };
}

export function loadAdjustmentsFailedAction(): AdjustmentsLoadFailedAction {
  return {
    type: GlassAdjustmentsActionType.LOAD_FAILED
  };
}

export function loadAdjustmentsSuccessAction(adjustments: GlassAdjustment[]): AdjustmentsLoadSuccessAction {
  return {
    type: GlassAdjustmentsActionType.LOAD_SUCCESS,
    adjustments
  };
}

export function loadAdjustmentsProgressAction(): AdjustmentsLoadProgressAction {
  return {
    type: GlassAdjustmentsActionType.LOAD_PROGRESS
  };
}

export function setActiveAdjustmentAction(adjustment: StateEntity<GlassAdjustment>): SetActiveAdjustmentAction {
  return {
    type: GlassAdjustmentsActionType.SET_ACTIVE,
    adjustment
  };
}

export function setAdjustmentUrlAliasAction(alias?: string): SetAdjustmentUrlAliasAction {
  return {
    type: GlassAdjustmentsActionType.SET_URL_ALIAS,
    alias
  };
}

interface AdjustmentsLoadRequestAction {
  type: typeof GlassAdjustmentsActionType.LOAD_REQUEST,
}

interface AdjustmentsLoadProgressAction {
  type: typeof GlassAdjustmentsActionType.LOAD_PROGRESS,
}

interface AdjustmentsLoadFailedAction {
  type: typeof GlassAdjustmentsActionType.LOAD_FAILED,
}

interface AdjustmentsLoadSuccessAction {
  type: typeof GlassAdjustmentsActionType.LOAD_SUCCESS,
  adjustments: GlassAdjustment[]
}

export interface SetActiveAdjustmentAction {
  type: typeof GlassAdjustmentsActionType.SET_ACTIVE,
  adjustment: StateEntity<GlassAdjustment>
}

interface SetAdjustmentUrlAliasAction {
  type: typeof GlassAdjustmentsActionType.SET_URL_ALIAS,
  alias?: string
}