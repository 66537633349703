import PatternCategoryAssignments from '../../service/domain/PatternCategoryAssignments';
import PatternCategoryChangeParameters from '../../service/dto/PatternCategoryChangeParameters';

export enum PatternCategoryAssignmentActionType {
  LOAD_SUCCESS = 'PATTERN_CATEGORY_ASSIGNMENTS_LOAD_SUCCESS',
  LOAD_FAILED = 'PATTERN_CATEGORY_ASSIGNMENTS_LOAD_FAILED',
  LOAD_REQUEST = 'PATTERN_CATEGORY_ASSIGNMENTS_LOAD_REQUEST',
  LOAD_PROGRESS = 'PATTERN_CATEGORY_ASSIGNMENTS_LOAD_PROGRESS'
}

export type PatternCategoryAssignmentsActions =
  PatternCategoryAssignmentsLoadRequestAction
  | PatternCategoryAssignmentsLoadFailedAction
  | PatternCategoryAssignmentsLoadSuccessAction
  | PatternCategoryAssignmentsLoadProgressAction;

export function loadPatternCategoryAssignmentsRequestAction(patternChangeParameters: PatternCategoryChangeParameters): PatternCategoryAssignmentsLoadRequestAction {
  return {
    type: PatternCategoryAssignmentActionType.LOAD_REQUEST,
    patternChangeParameters
  };
}

export function loadPatternCategoryAssignmentsFailedAction(patternChangeParameters: PatternCategoryChangeParameters): PatternCategoryAssignmentsLoadFailedAction {
  return {
    type: PatternCategoryAssignmentActionType.LOAD_FAILED,
    patternChangeParameters
  };
}

export function loadPatternCategoryAssignmentsSuccessAction(
  patternChangeParameters: PatternCategoryChangeParameters,
  patternCategoryAssignments: PatternCategoryAssignments): PatternCategoryAssignmentsLoadSuccessAction {
  return {
    type: PatternCategoryAssignmentActionType.LOAD_SUCCESS,
    patternChangeParameters,
    patternCategoryAssignments
  };
}

export function loadPatternCategoryAssignmentsProgressAction(patternChangeParameters: PatternCategoryChangeParameters): PatternCategoryAssignmentsLoadProgressAction {
  return {
    type: PatternCategoryAssignmentActionType.LOAD_PROGRESS,
    patternChangeParameters
  };
}

export interface PatternCategoryAssignmentsAction {
  patternChangeParameters: PatternCategoryChangeParameters
}

export interface PatternCategoryAssignmentsLoadRequestAction extends PatternCategoryAssignmentsAction {
  type: typeof PatternCategoryAssignmentActionType.LOAD_REQUEST
}

interface PatternCategoryAssignmentsLoadProgressAction extends PatternCategoryAssignmentsAction {
  type: typeof PatternCategoryAssignmentActionType.LOAD_PROGRESS
}

interface PatternCategoryAssignmentsLoadFailedAction extends PatternCategoryAssignmentsAction {
  type: typeof PatternCategoryAssignmentActionType.LOAD_FAILED
}

interface PatternCategoryAssignmentsLoadSuccessAction extends PatternCategoryAssignmentsAction {
  type: typeof PatternCategoryAssignmentActionType.LOAD_SUCCESS,
  patternCategoryAssignments: PatternCategoryAssignments
}