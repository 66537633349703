import { all, call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  invalidateActivePatternAction,
  loadFragmentFailedAction,
  loadFragmentProgressAction,
  loadFragmentSuccessAction,
  loadPatternsFailedAction,
  loadPatternsProgressAction,
  PatternsActionType,
  PatternsLoadRequestAction
} from '../store/pattern/patternsActions';
import patternService from '../service/patternService';
import Pattern from '../service/domain/Pattern';
import { SceneThemesActionType } from '../store/scene/sceneThemesActions';
import { SceneVariantsActionType } from '../store/scene/sceneVariantsActions';
import { GlassAdjustmentsActionType } from '../store/glass/glassAdjustmentsActions';
import { GlassTypesActionType } from '../store/glass/glassTypeActions';
import { GlassColorsActionType } from '../store/glass/glassColorsActions';
import { ScenesActionType } from '../store/scene/scenesActions';
import { activePatternSelector, patternsSelector, patternsStatusSelector, patternUrlAliasSelector } from '../store/pattern/patternSelectors';
import EntityStatus from '../store/entityStatus';
import PatternSearchParameters from '../service/dto/PatternSearchParameters';
import { StateEntity } from '../store/StateEntity';
import { activeSceneThemeEntitySelector } from '../store/scene/sceneThemesSelectors';
import { activeGlassTypeEntitySelector } from '../store/glass/glassTypesSelectors';
import { activeGlassColorEntitySelector } from '../store/glass/glassColorsSelectors';
import { activeGlassAdjustmentEntitySelector } from '../store/glass/glassAdjustmentsSelectors';

const QUERY_SIZE = 10;
const STOP_PATTERN_LOADING_ACTION = { type: 'STOP_PATTERN_LOADING_ACTION' };
/**
 * Finds all parallel Sagas connected with patterns
 * @return all found sagas
 */
export default function * patternsSagas() {
  yield all([takeLoadPatterns(), takeChangeParentEntities(), takeFragmentLoadRequest()]);
}

function * takeLoadPatterns() {
  yield takeEvery(PatternsActionType.LOAD_REQUEST, loadPatterns);
}

function * takeChangeParentEntities() {
  yield all([
    yield takeEvery(ScenesActionType.SET_ACTIVE, invalidatePattern),
    yield takeEvery(SceneThemesActionType.SET_ACTIVE, invalidatePattern),
    yield takeEvery(SceneVariantsActionType.SET_ACTIVE, invalidatePattern),
    yield takeEvery(GlassAdjustmentsActionType.SET_ACTIVE, invalidatePattern),
    yield takeEvery(GlassTypesActionType.SET_ACTIVE, invalidatePattern),
    yield takeEvery(GlassColorsActionType.SET_ACTIVE, invalidatePattern)
  ]);
}

function * takeFragmentLoadRequest() {
  yield takeEvery(PatternsActionType.LOAD_FRAGMENT_REQUEST, loadFragmentRequest);
}

function * invalidatePattern() {
  const activePattern = yield select(activePatternSelector);
  const loadedPatternsStatus = yield select(patternsStatusSelector);
  const desiredStatus = EntityStatus.NOT_LOADED;
  if (activePattern.status !== desiredStatus || loadedPatternsStatus !== desiredStatus) {
    yield put(invalidateActivePatternAction());
  }
}

function * loadPatterns(action: PatternsLoadRequestAction) {
  yield put(STOP_PATTERN_LOADING_ACTION);

  const searchParameters = {
    ...action.patternSearchParameters,
    offset: 0,
    size: QUERY_SIZE
  };
  yield put(loadPatternsProgressAction(searchParameters));
  try {
    const activePattern: StateEntity<Pattern> | undefined = yield select(activePatternSelector);
    if (activePattern?.entity) {
      const patterns: Pattern[] = yield call(patternService.findPatterns, {
        ...searchParameters,
        patternThumbnailId: activePattern.entity.thumbnailId
      });
      if (patterns.length > 0) {
        yield put(loadFragmentSuccessAction(patterns, true, searchParameters, undefined));
      }
    }
    const alias = yield select(patternUrlAliasSelector);
    if (!activePattern?.entity && alias) {
      const patterns: Pattern[] = yield call(patternService.findPatterns, {
        ...searchParameters,
        patternAlias: alias
      });
      if (patterns.length > 0) {
        yield put(loadFragmentSuccessAction(patterns, true, searchParameters, undefined));
      }
    }
    const task = yield fork(loadFragment, action.patternSearchParameters);

    yield take(STOP_PATTERN_LOADING_ACTION.type);
    yield cancel(task);
  } catch (e) {
    console.error(e);
    yield put(loadPatternsFailedAction(searchParameters));
  }
}

function * loadFragmentRequest() {
  const { id: sceneThemeId } = yield select(activeSceneThemeEntitySelector);
  const { id: glassTypeId } = yield select(activeGlassTypeEntitySelector);
  const { id: glassColorId } = yield select(activeGlassColorEntitySelector);
  const { id: glassAdjustmentId } = yield select(activeGlassAdjustmentEntitySelector);
  const searchParameters: PatternSearchParameters = {
    sceneThemeId,
    glassTypeId,
    glassColorId,
    glassAdjustmentId
  };
  const task = yield fork(loadFragment, searchParameters);

  yield take(STOP_PATTERN_LOADING_ACTION.type);
  yield cancel(task);
}

function * loadFragment(searchParameters: PatternSearchParameters) {
  const { hasMore, loadedOffset } = yield select(patternsSelector);
  if (hasMore || hasMore === undefined) {
    searchParameters.offset = (searchParameters.offset ?? loadedOffset ?? -1) + 1;
    searchParameters.size = searchParameters.size ?? QUERY_SIZE;
    let result = yield loadMoreFragments(searchParameters);
    while (result.shouldLoadMore) {
      const newSearchParameters = {
        ...searchParameters,
        offset: result.offset + 1
      };
      result = yield loadMoreFragments(newSearchParameters);
    }
  }
}

function * loadMoreFragments(searchParameters: PatternSearchParameters) {
  yield put(loadFragmentProgressAction(searchParameters));
  try {
    const foundPatterns: Pattern[] = yield call(patternService.findPatterns, searchParameters);
    const offset = (searchParameters.offset ?? 0) - 1 + foundPatterns.length;
    const hasMore = foundPatterns.length >= QUERY_SIZE;
    yield put(loadFragmentSuccessAction(foundPatterns, hasMore, searchParameters, offset));
    if (hasMore) {
      return {
        offset: offset,
        shouldLoadMore: true
      };
    }
    return {
      offset: offset,
      shouldLoadMore: false
    };
  } catch (e) {
    console.error(e);
    yield put(loadFragmentFailedAction(searchParameters));
  }
}