import { all } from 'redux-saga/effects';
import { scenesSagas } from './scenesSagas';
import glassAdjustmentsSagas from './glassAdjustmentsSagas';
import patternCategoriesSagas from './patternCategoriesSagas';
import patternsSagas from './patternSagas';
import glassTypesSagas from './glassTypesSagas';
import glassColorsSagas from './glassColorsSagas';
import glassSagas from './glassSagas';

/**
 * Creates the root Saga wrapping all saga business in the entire application
 */
export default function * rootSaga() {
  yield all([
    scenesSagas(),
    glassSagas(),
    glassAdjustmentsSagas(),
    patternCategoriesSagas(),
    patternsSagas(),
    glassTypesSagas(),
    glassColorsSagas()
  ]);
};