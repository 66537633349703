import React from 'react';

const IconCross: React.FC = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <polygon fill="currentColor"
      points="20 1.212 18.788 0 10 8.788 1.212 0 0 1.212 8.788 10 0 18.788 1.212 20 10 11.212 18.788 20 20 18.788 11.212 10 20 1.212" />
  </svg>;
};

export default IconCross;
