/*
* Configuration of application locale for localization
*/

export const SUPPORTED_LOCALE_VALUES = ['cs', 'sk'];

export enum SupportedLocale {CS = 'cs', SK = 'sk'}

const translationDomainMap = new Map();
translationDomainMap.set('cz', 'cs');
translationDomainMap.set('sk', 'sk');
export const DOMAIN_LOCALE_TRANSLATION = translationDomainMap;

export const DEFAULT_LOCALE = SupportedLocale.CS;

export function fromValue(value: string): SupportedLocale {
  switch (value.toLowerCase()) {
    case SupportedLocale.CS:
      return SupportedLocale.CS;
    case SupportedLocale.SK:
      return SupportedLocale.SK;
    default:
      throw new Error(`Locale of value '${value}' are not supported.`);
  }
}