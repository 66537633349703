import { useLayoutEffect, useState } from 'react';
import { ViewportSize } from '../../../service/dto/ViewportSize';

export default function useWindowSize(): ViewportSize {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return {
    width: size[0],
    height: size[1]
  };
}