import React, { useEffect, useMemo, useRef } from 'react';

const createClickHandler = (ref: React.RefObject<HTMLDivElement>, onClickOutside: () => void) => (event: any) => {
  if (ref.current && !ref.current.contains(event.target)) {
    onClickOutside();
  }
};
const useOutsideHandler = (ref: React.RefObject<HTMLDivElement>, onClickOutside: () => void) => {
  const clickHandler = useMemo(() => createClickHandler(ref, onClickOutside), [ref, onClickOutside]);
  useEffect(() => {
    document.addEventListener('mousedown', clickHandler);
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, [ref, clickHandler]);
};

interface Props {
  onClickOutside: () => void
}

const OutsideClickHandler: React.FC<Props> = (props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideHandler(wrapperRef, props.onClickOutside);

  return <div ref={wrapperRef}>{props.children}</div>;
};

export default OutsideClickHandler;