import StoreState from '../storeState';
import { createSelector } from 'reselect';
import { allScenesSelector } from './sceneSelectors';
import { activeSceneThemeGroupEntitySelector } from './sceneThemesSelectors';

/*
 * Shared selectors for scene variants
 */

export const activeSceneVariantSelector = (state: StoreState) => state.scene.sceneVariants.activeVariant;
export const activeSceneVariantEntitySelector = (state: StoreState) => state.scene.sceneVariants.activeVariant.entity;
export const activeSceneVariantsSelector = (state: StoreState) => state.scene.scenes.activeScene?.entity?.variants ?? [];
export const variantsForSceneThemeGroupSelector = createSelector([activeSceneThemeGroupEntitySelector, allScenesSelector],
  (activeThemeGroup, allScenes) => {
    const groupId = activeThemeGroup?.id;
    if (!groupId) {
      return [];
    }
    return allScenes
      .flatMap(scene => scene.variants)
      .filter(variant => variant.themes.find(theme => theme.sceneThemeGroupId === groupId));
  });