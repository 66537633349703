import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadScenesRequestAction } from '../../../store/scene/scenesActions';

/**
 * Component takes care of scenes loading
 */
const ScenesProvider: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadScenesRequestAction());
  }, [dispatch]);

  return null;
};

const SceneProviders: React.FC = () => {
  return <>
    <ScenesProvider />
  </>;
};

export { SceneProviders as default };