import Pattern from './domain/Pattern';
import configuration from './configuration';
import EntitySaveResponse from './domain/admin/EntitySaveResponse';
import { LoadingStatus } from './domain/admin/loadingStatus';
import PatternSaveRequest from './domain/admin/PatternSaveRequest';
import PatternSearchParameters from './dto/PatternSearchParameters';
import { PatternUsage } from './domain/admin/PatternUsage';
import { FormDataBuilder, UrlBuilder } from './fetchHelper';

class PatternService {
  findPatterns(searchParameters: PatternSearchParameters): Promise<Pattern[]> {
    const url = UrlBuilder.fromBaseUrl(configuration.publicApiUrl, '/pattern')
      .withParameter('patternCategoryId', searchParameters.patternCategoryId)
      .withParameter('sceneVariantId', searchParameters.sceneVariantId)
      .withParameter('sceneThemeId', searchParameters.sceneThemeId)
      .withParameter('glassColorId', searchParameters.glassColorId)
      .withParameter('glassAdjustmentId', searchParameters.glassAdjustmentId)
      .withParameter('glassTypeId', searchParameters.glassTypeId)
      .withParameter('patternAlias', searchParameters.patternAlias)
      .withParameter('offset', searchParameters.offset)
      .withParameter('size', searchParameters.size)
      .withParameter('patternThumbnailId', searchParameters.patternThumbnailId)
      .build();
    return fetch(url).then(value => {
      return value.json().then(items => {
        return items.map((item: any) => ({
          id: item.id,
          alias: item.patternThumbnail.alias,
          order: item.order,
          title: item.patternThumbnail.title,
          thumbnail: item.patternThumbnail.imagePath,
          thumbnailId: item.patternThumbnail.id,
          thumbnailPatternPostfix: item.patternThumbnail.patternPostfix,
          sceneImage: item.imagePath,
          patternCategoryId: item.patternThumbnail.patternCategoryId
        }));
      });
    });
  }

  findById(patternId: number): Promise<Pattern> {
    console.log('FINDS');
    const url = UrlBuilder.fromBaseUrl(configuration.adminApiUrl, '/pattern/id')
      .withParameter('patternId', patternId)
      .build();

    return fetch(url)
      .then(response => response.json());
  }

  findPatternByThumbnailUsage(searchParameters: PatternSearchParameters): Promise<PatternUsage> {
    const url = UrlBuilder.fromBaseUrl(configuration.adminApiUrl, '/pattern/usages')
      .withParameter('patternThumbnailId', searchParameters.patternThumbnailId)
      .withParameter('patternCategoryId', searchParameters.patternCategoryId)
      .build();

    return fetch(url)
      .then(response => response.json())
      .then(items => ({
          items,
          status: LoadingStatus.SUCCESSFUL
        })
      )
      .catch(reason => {
        console.error(reason);
        return {
          items: [],
          status: LoadingStatus.ERROR
        };
      });
  }

  savePattern(saveRequest: PatternSaveRequest): Promise<EntitySaveResponse> {
    const formData = FormDataBuilder.newInstance()
      .withData('sceneVariantId', saveRequest.sceneVariantId)
      .withData('sceneThemeId', saveRequest.sceneThemeId)
      .withData('glassAdjustmentId', saveRequest.glassAdjustmentId)
      .withData('glassTypeId', saveRequest.glassTypeId)
      .withData('glassColorId', saveRequest.glassColorId)
      .withData('patternThumbnailId', saveRequest.patternThumbnailId)
      .withData('file', saveRequest.file)
      .withData('alias', saveRequest.alias)
      .withData('title', saveRequest.title)
      .withData('id', saveRequest.id)
      .withData('order', saveRequest.order)
      .build();
    const url = UrlBuilder.fromBaseUrl(configuration.adminApiUrl, '/pattern/save').build();

    return fetch(url, {
      method: 'POST',
      body: formData
    }).then(response => {
      if (response.ok) {
        return { status: LoadingStatus.SUCCESSFUL };
      } else {
        console.error(response.text());
        return { status: LoadingStatus.ERROR };
      }
    }).catch(reason => {
      console.error(reason);
      return { status: LoadingStatus.ERROR };
    });
  }

  updatePattern(pattern: Pattern): Promise<Pattern> {
    const formData = FormDataBuilder.newInstance()
      .withData('alias', pattern.alias)
      .withData('title', pattern.title)
      .withData('id', pattern.id)
      .withData('order', pattern.order)
      .build();
    const url = UrlBuilder.fromBaseUrl(configuration.adminApiUrl, '/pattern/update').build();

    return fetch(url, {
      method: 'POST',
      body: formData
    }).then(response => {
      return response.json();
    });
  }

  deletePattern(patternId: number): Promise<void> {
    return fetch(`${configuration.adminApiUrl}/pattern/delete/${patternId}`, { method: 'DELETE' }).then(response => response.json());
  }
}

export default new PatternService();