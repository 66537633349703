import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  GlassTypeAssignmentsActionType,
  GlassTypeAssignmentsLoadRequestAction,
  loadGlassTypeAssignmentsFailedAction,
  loadGlassTypeAssignmentsProgressAction,
  loadGlassTypeAssignmentsSuccessAction
} from '../store/glass/glassTypesAssignmentsActions';
import glassTypesService from '../service/glassTypesService';
import GlassTypeAssignment from '../service/domain/GlassTypeAssignment';
import { SceneThemesActionType } from '../store/scene/sceneThemesActions';
import { SceneVariantsActionType } from '../store/scene/sceneVariantsActions';
import { GlassAdjustmentsActionType } from '../store/glass/glassAdjustmentsActions';
import { GlassColorsActionType } from '../store/glass/glassColorsActions';
import EntityStatus from '../store/entityStatus';
import { setActiveGlassTypeAction } from '../store/glass/glassTypeActions';
import { activeGlassTypeSelector } from '../store/glass/glassTypesSelectors';

/**
 * Finds all parallel Sagas connected with glass types
 * @return all found sagas
 */
export default function * glassTypesSagas() {
  yield all([takeLoadGlassTypeAssignments(), takeActionsForTypeReset()]);
}

function * takeLoadGlassTypeAssignments() {
  yield takeEvery(GlassTypeAssignmentsActionType.LOAD_REQUEST, loadGlassTypeAssignments);
}

function * loadGlassTypeAssignments(action: GlassTypeAssignmentsLoadRequestAction) {
  const sceneVariant = action.sceneVariant;
  const glassAdjustment = action.glassAdjustment;

  yield put(loadGlassTypeAssignmentsProgressAction(sceneVariant, glassAdjustment));
  try {
    const assignments: GlassTypeAssignment[] = yield call(glassTypesService.findAssignmentsForScene, sceneVariant, glassAdjustment);
    yield put(loadGlassTypeAssignmentsSuccessAction(assignments, sceneVariant, glassAdjustment));
  } catch (e) {
    console.error(e);
    yield put(loadGlassTypeAssignmentsFailedAction(sceneVariant, glassAdjustment));
  }
}

function * takeActionsForTypeReset() {
  yield all([
    yield takeEvery(SceneThemesActionType.SET_ACTIVE, resetType),
    yield takeEvery(SceneVariantsActionType.SET_ACTIVE, resetType),
    yield takeEvery(GlassAdjustmentsActionType.SET_ACTIVE, resetType),
    yield takeEvery(GlassColorsActionType.SET_ACTIVE, resetType)
  ]);
}

function * resetType() {
  const activeType = yield select(activeGlassTypeSelector);
  const resultStatus = EntityStatus.NOT_LOADED;
  if (activeType.status !== resultStatus) {
    yield put(setActiveGlassTypeAction({
      status: resultStatus,
      entity: activeType.entity
    }));
  }
}