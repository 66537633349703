import { PatternCategoriesState } from './patternState';
import { PatternCategoriesActions, PatternCategoriesActionType } from './patternCategoriesActions';
import EntityStatus from '../entityStatus';
import { PatternsActionType, PatternsSetActiveAction } from './patternsActions';
import { GlassColorsActionType, SetActiveGlassColorAction } from '../glass/glassColorsActions';
import { GlassAdjustmentsActionType, SetActiveAdjustmentAction } from '../glass/glassAdjustmentsActions';
import { GlassTypesActionType, SetActiveGlassTypeAction } from '../glass/glassTypeActions';
import { SceneThemesActionType, SetActiveSceneThemeAction } from '../scene/sceneThemesActions';
import { SceneVariantsActionType, SetActiveSceneVariantAction } from '../scene/sceneVariantsActions';

const initialState: PatternCategoriesState = {
  patternCategories: [],
  activePatternCategory: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  patternCategoryUrlAlias: undefined,
  status: EntityStatus.NOT_LOADED
};

type actions = PatternCategoriesActions |
  PatternsSetActiveAction |
  SetActiveGlassTypeAction |
  SetActiveGlassColorAction |
  SetActiveAdjustmentAction |
  SetActiveSceneVariantAction |
  SetActiveSceneThemeAction;

export default (state: PatternCategoriesState = initialState, action: actions): PatternCategoriesState => {
  switch (action.type) {
    case PatternsActionType.SET_ACTIVE:
    case GlassColorsActionType.SET_ACTIVE:
    case GlassTypesActionType.SET_ACTIVE:
    case GlassAdjustmentsActionType.SET_ACTIVE:
    case SceneVariantsActionType.SET_ACTIVE:
    case SceneThemesActionType.SET_ACTIVE:
      return {
        ...state,
        activePatternCategory: {
          entity: state.activePatternCategory.entity,
          status: EntityStatus.NOT_LOADED
        }
      };
    case PatternCategoriesActionType.LOAD_FAILED:
      return {
        ...state,
        patternCategories: [],
        status: EntityStatus.LOAD_FAILED
      };
    case PatternCategoriesActionType.LOAD_PROGRESS:
      return {
        ...state,
        patternCategories: [],
        status: EntityStatus.LOAD_IN_PROGRESS
      };
    case PatternCategoriesActionType.LOAD_REQUEST:
      return {
        ...state,
        patternCategories: [],
        status: EntityStatus.NOT_LOADED
      };
    case PatternCategoriesActionType.LOAD_SUCCESS:
      return {
        ...state,
        patternCategories: action.patternCategories,
        status: EntityStatus.LOAD_SUCCESS
      };
    case PatternCategoriesActionType.SET_ACTIVE:
      return {
        ...state,
        activePatternCategory: action.patternCategory
      };
    case PatternCategoriesActionType.SET_URL_ALIAS:
      return {
        ...state,
        patternCategoryUrlAlias: action.alias
      };
    default:
      return state;
  }
};