import configuration from './configuration';
import { ImageSize, SupportedImageSize } from './dto/imageSize';
import { ViewportSize } from './dto/ViewportSize';

const imageSizeSuffix = '?size=';

class PublicApiImageService {
  findThumbnailImagePath(imagePath: string): string {
    return `${configuration.publicImageApiUrl}/${imagePath}`;
  }

  findImagePath(imagePath: string, imageSize?: SupportedImageSize): string {
    return `${configuration.publicImageApiUrl}/${imagePath}${imageSize ? PublicApiImageService._buildImageSizeParameter(imageSize) : ''}`;
  }

  static findImageSize(viewportSize: ViewportSize): ImageSize {
    const width = viewportSize.width;
    const height = viewportSize.height;
    return configuration.imageSizes
        .sort((a, b) => b.priority - a.priority)
        .find(imageSize => width <= imageSize.width && height <= imageSize.height)
      ?? configuration.defaultImageSize();
  }

  static _buildImageSizeParameter(imageSize?: SupportedImageSize) {
    return imageSize ? imageSizeSuffix + imageSize : '';
  }
}

const instance = new PublicApiImageService();
export { PublicApiImageService, instance as default };