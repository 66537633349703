import SceneTheme from '../../service/domain/SceneTheme';
import { StateEntity } from '../StateEntity';

export enum SceneThemesActionType {
  SET_ACTIVE = 'SCENE_THEMES_SET_ACTIVE',
  SET_URL_ALIAS = 'SCENE_THEMES_SET_SCENE_URL_ALIAS'
}

export type SceneThemesActions =
  SetActiveSceneThemeAction
  | SetSceneThemeUrlAliasAction;

export function setActiveSceneThemeAction(sceneTheme: StateEntity<SceneTheme>): SetActiveSceneThemeAction {
  return {
    type: SceneThemesActionType.SET_ACTIVE,
    sceneTheme
  };
}

export function setSceneThemesThemeUrlAction(alias?: string): SetSceneThemeUrlAliasAction {
  return {
    type: SceneThemesActionType.SET_URL_ALIAS,
    alias
  };
}

export interface SetActiveSceneThemeAction {
  type: typeof SceneThemesActionType.SET_ACTIVE,
  sceneTheme: StateEntity<SceneTheme>
}

interface SetSceneThemeUrlAliasAction {
  type: typeof SceneThemesActionType.SET_URL_ALIAS,
  alias?: string
}