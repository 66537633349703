import React from 'react';

const IconInfo: React.FC = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{
    width: 25,
    marginTop: -5
  }}>
    <path fill="currentColor"
      d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" />
  </svg>;

};

export default IconInfo;
