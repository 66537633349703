import PatternCategory from './domain/PatternCategory';
import PatternCategoryChangeParameters from './dto/PatternCategoryChangeParameters';
import PatternCategoryAssignments from './domain/PatternCategoryAssignments';
import configuration from './configuration';
import { ImageSaveRequestPartial } from './domain/admin/ImageSaveRequestPartial';
import { FormDataBuilder, UrlBuilder } from './fetchHelper';

class PatternCategoryService {

  findAllActive(): Promise<PatternCategory[]> {
    return fetch(
      `${configuration.publicApiUrl}/pattern-category`).then(value => {
      return value.json();
    });
  }

  findAll(): Promise<Array<PatternCategory>> {
    return fetch(`${configuration.adminApiUrl}/pattern-categories`).then(value => {
      return value.json();
    });
  }

  findById(id: number): Promise<PatternCategory> {
    return fetch(`${configuration.adminApiUrl}/pattern-categories/${id}`).then(value => {
      return value.json();
    });
  }

  prepareNew(): Partial<PatternCategory> {
    return { active: true };
  }

  deletePatternCategory(patternCategoryId: number): Promise<void> {
    return fetch(`${configuration.adminApiUrl}/pattern-categories/delete/${patternCategoryId}`, { method: 'DELETE' }).then(response => response.json());
  }

  save(patternCategory: PatternCategory & { thumbnailImageUpload?: ImageSaveRequestPartial }): Promise<PatternCategory> {
    const formDataBuilder = FormDataBuilder.newInstance()
      .withData('alias', patternCategory.alias)
      .withData('active', patternCategory.active + '')
      .withData('titleCs', patternCategory.titleCs)
      .withData('titleSk', patternCategory.titleSk)
      .withData('thumbnailImage', patternCategory.thumbnailImage)
      .withData('id', patternCategory.id)
      .withData('order', patternCategory.order);

    if (patternCategory.thumbnailImageUpload) {
      formDataBuilder.withData('thumbnailImageUpload.File', patternCategory.thumbnailImageUpload.file);
      formDataBuilder.withData('thumbnailImageUpload.Filename', patternCategory.thumbnailImageUpload.fileName);
    }
    const formData = formDataBuilder.build();
    return fetch(`${configuration.adminApiUrl}/pattern-categories/save/${patternCategory.id ?? ''}`, {
      method: 'POST',
      body: formData
    }).then(value => {
      return value.json();
    });
  };

  findAssignment(patternChangeParameters: PatternCategoryChangeParameters): Promise<PatternCategoryAssignments> {
    const url = UrlBuilder.fromBaseUrl(configuration.publicApiUrl, '/pattern-category-assignment')
      .withParameter('glassColorId', patternChangeParameters.glassColorId)
      .withParameter('glassAdjustmentId', patternChangeParameters.glassAdjustmentId)
      .withParameter('sceneThemeId', patternChangeParameters.sceneThemeId)
      .withParameter('glassTypeId', patternChangeParameters.glassTypeId)
      .build();

    return fetch(url)
      .then(value => {
        return value.json().then(items => {
          const categoryIds = items.map((item: any) => item.patternCategoryId);
          return {
            categoryIds: categoryIds,
            sceneThemeId: patternChangeParameters.sceneThemeId,
            glassAdjustmentId: patternChangeParameters.glassAdjustmentId,
            glassTypeId: patternChangeParameters.glassTypeId,
            glassColorId: patternChangeParameters.glassColorId
          };
        });
      });
  }
}

export default new PatternCategoryService();