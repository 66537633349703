import { ViewportSize } from './ViewportSize';

export enum SupportedImageSize {
  HD1080 = '',
  CGA = 'cga',
  FWVGA = 'fwvga',
  HD720 = 'hd720',
  HD768 = 'hd768'
}

export interface ImageSize extends ViewportSize {
  sizeType: SupportedImageSize,
  priority: number
}