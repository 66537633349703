import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  loadPatternCategoriesFailedAction,
  loadPatternCategoriesProgressAction,
  loadPatternCategoriesRequestAction,
  loadPatternCategoriesSuccessAction
} from '../store/pattern/patternCategoriesActions';
import patternCategoryService from '../service/patternCategoryService';
import PatternCategory from '../service/domain/PatternCategory';
import {
  loadPatternCategoryAssignmentsFailedAction,
  loadPatternCategoryAssignmentsProgressAction,
  loadPatternCategoryAssignmentsSuccessAction,
  PatternCategoryAssignmentActionType,
  PatternCategoryAssignmentsLoadRequestAction
} from '../store/pattern/patternCategoryAssignmentsActions';
import PatternCategoryAssignments from '../service/domain/PatternCategoryAssignments';

/**
 * Finds all parallel Sagas connected with pattern categories
 * @return all found sagas
 */
export default function * patternCategoriesSagas() {
  yield all([takeLoadPatternCategories(), takeLoadPatternCategoriesAssignments()]);
}

function * takeLoadPatternCategories() {
  yield takeEvery(loadPatternCategoriesRequestAction().type, loadPatternCategories);
}

function * loadPatternCategories() {
  yield put(loadPatternCategoriesProgressAction());
  try {
    const patternCategories: PatternCategory[] = yield call(patternCategoryService.findAllActive);
    yield put(loadPatternCategoriesSuccessAction(patternCategories));
  } catch (e) {
    console.error(e);
    yield put(loadPatternCategoriesFailedAction());
  }
}

function * takeLoadPatternCategoriesAssignments() {
  yield takeEvery(PatternCategoryAssignmentActionType.LOAD_REQUEST, loadPatternCategoriesAssignments);
}

function * loadPatternCategoriesAssignments(action: PatternCategoryAssignmentsLoadRequestAction) {
  const patternChangeParameters = action.patternChangeParameters;
  yield put(loadPatternCategoryAssignmentsProgressAction(patternChangeParameters));
  try {
    const assignments: PatternCategoryAssignments = yield call(patternCategoryService.findAssignment, patternChangeParameters);
    yield put(loadPatternCategoryAssignmentsSuccessAction(patternChangeParameters, assignments));
  } catch (e) {
    console.error(e);
    yield put(loadPatternCategoryAssignmentsFailedAction(patternChangeParameters));
  }
}