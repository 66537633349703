import React from 'react';
import useLocalizeMessage from '../../localization/localizeMessage';
import { useDispatch, useSelector } from 'react-redux';
import { activeSceneVariantEntitySelector } from '../../store/scene/sceneVariantsSelectors';
import { activeGlassAdjustmentEntitySelector } from '../../store/glass/glassAdjustmentsSelectors';
import GlassType from '../../service/domain/GlassType';
import { setActiveGlassTypeAction } from '../../store/glass/glassTypeActions';
import DetailMenu, { DetailMenuProps } from '../../component/configurer/sidebar/DetailMenu';
import SubmenuItems, { SidebarItemsMenuSize } from '../../component/configurer/sidebar/SubmenuItems';
import GlassColorDetailMenu from './GlassColorDetailMenu';
import StoreState from '../../store/storeState';
import SceneVariant from '../../service/domain/SceneVariant';
import GlassAdjustment from '../../service/domain/GlassAdjustment';
import EntityStatus from '../../store/entityStatus';
import { StateEntity } from '../../store/StateEntity';
import { glassColorAssignmentsSelector, glassColorsSelector } from '../../store/glass/glassColorsSelectors';

type Props = { activeGlassType: StateEntity<GlassType>, onClick: () => void } & Pick<DetailMenuProps, 'visible' | 'onCloseClick'>

const glassTypesSelector = (state: StoreState) => state.glass.types.types;
const glassTypeAssignmentsSelector = (sceneVariant?: SceneVariant, glassAdjustment?: GlassAdjustment) => (state: StoreState) => {
  if (!sceneVariant || !glassAdjustment) {
    return;
  }
  return state.glass.typeAssignments.typeAssignments.find(
    assignment => assignment.glassAdjustmentId === glassAdjustment.id && assignment.sceneVariantId === sceneVariant.id);
};

const GlassTypeDetailMenu: React.FC<Props> = (props) => {

  const localizeMessage = useLocalizeMessage();
  const dispatch = useDispatch();
  const sceneVariant = useSelector(activeSceneVariantEntitySelector);
  const adjustment = useSelector(activeGlassAdjustmentEntitySelector);
  const assignments = useSelector(glassTypeAssignmentsSelector(sceneVariant, adjustment));
  const glassTypes = useSelector(glassTypesSelector);
  const currentGlassTypes = glassTypes.filter(type => assignments?.typeAssignments.find(assignment => assignment.typeId === type.id));
  const setActiveGlassType = (glassType: GlassType) => () => {
    if (glassType !== props.activeGlassType.entity) {
      dispatch(setActiveGlassTypeAction({
        entity: glassType,
        status: EntityStatus.LOAD_SUCCESS
      }));
    }
  };
  const showGlassTypes = currentGlassTypes.length > 1;
  const colorAssignments = useSelector(glassColorAssignmentsSelector);
  const glassColors = useSelector(glassColorsSelector);
  const currentGlassColors = glassColors.filter(color => colorAssignments?.colorAssignments.find(assignment => assignment.colorId === color.id));
  const showGlassColors = currentGlassColors.length > 1;
  const glassTypeMenuTitle = showGlassTypes ? 'sidebar.memuItem.detailmenu.label.GLASS_TYPE' : 'sidebar.memuItem.detailmenu.label.GLASS_COLOR';
  const glassColorMenuTitle = showGlassColors ? localizeMessage('sidebar.memuItem.detailmenu.label.GLASS_COLOR') : undefined;

  return showGlassTypes || showGlassColors
    ? <DetailMenu title={localizeMessage(glassTypeMenuTitle)}
      visible={props.visible && (showGlassTypes || showGlassColors)}
      onCloseClick={props.onCloseClick}>
      <SubmenuItems items={currentGlassTypes}
        activeItem={props.activeGlassType.entity}
        showItemTitle={true}
        onItemClick={setActiveGlassType}
        visible={showGlassTypes}
        onClick={props.onClick}
        itemsStatus={props.activeGlassType.status}
        itemsSize={SidebarItemsMenuSize.SMALL}
        level={2} />
      {showGlassColors &&
      <GlassColorDetailMenu title={glassColorMenuTitle} />
      }
    </DetailMenu>
    : null;
};

export default GlassTypeDetailMenu;
