import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import adjustmentService from '../service/glassAdjustmentService';
import {
  AdjustmentAssignmentsActionType,
  AdjustmentAssignmentsLoadRequestAction,
  AdjustmentAssignmentsLoadSuccessAction,
  loadAdjustmentAssignmentsFailedAction,
  loadAdjustmentAssignmentsProgressAction,
  loadAdjustmentAssignmentsSuccessAction
} from '../store/glass/glassAdjustmentsAssignmentsActions';
import GlassAdjustmentAssignment from '../service/domain/GlassAdjustmentAssignment';
import { SceneThemesActionType } from '../store/scene/sceneThemesActions';
import { SceneVariantsActionType } from '../store/scene/sceneVariantsActions';
import { setActiveAdjustmentAction } from '../store/glass/glassAdjustmentsActions';
import EntityStatus from '../store/entityStatus';
import { activeGlassAdjustmentSelector } from '../store/glass/glassAdjustmentsSelectors';

/**
 * Finds all parallel Sagas connected with adjustments
 * @return all found sagas
 */
export default function * glassAdjustmentsSagas() {
  yield all([takeLoadAdjustmentAssignments(), takeActionsForAdjustmentReset()]);
}

function * takeLoadAdjustmentAssignments() {
  yield takeEvery(AdjustmentAssignmentsActionType.LOAD_REQUEST, loadAdjustmentAssignments);
}

function * takeActionsForAdjustmentReset() {
  yield all([
    yield takeEvery(SceneThemesActionType.SET_ACTIVE, resetAdjustment),
    yield takeEvery(SceneVariantsActionType.SET_ACTIVE, resetAdjustment)
  ]);
}

function * resetAdjustment() {
  const activeAdjustment = yield select(activeGlassAdjustmentSelector);
  const resultStatus = EntityStatus.NOT_LOADED;
  if (activeAdjustment.status !== resultStatus) {
    yield put(setActiveAdjustmentAction({
      status: resultStatus,
      entity: activeAdjustment.entity
    }));
  }
}

function * loadAdjustmentAssignments(action: AdjustmentAssignmentsLoadRequestAction) {
  const sceneThemeGroup = action.sceneThemeGroup;
  yield put(loadAdjustmentAssignmentsProgressAction(sceneThemeGroup));
  try {
    const adjustmentAssignments: GlassAdjustmentAssignment[] = yield call(adjustmentService.findAssignmentsForThemeGroup, sceneThemeGroup);
    const groupedBySceneVariant = groupBy(adjustmentAssignments, item => item.sceneVariantId);
    const actions: AdjustmentAssignmentsLoadSuccessAction[] = [];
    groupedBySceneVariant.forEach((items, sceneVariantId) => {
      actions.push(loadAdjustmentAssignmentsSuccessAction(items, sceneVariantId));
    });
    yield all(actions.map(item => put(item)));
  } catch (e) {
    console.error(e);
    yield put(loadAdjustmentAssignmentsFailedAction(sceneThemeGroup));
  }
}

function groupBy<T>(list: Array<T>, keyGetter: (item: T) => any) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
