import EntityStatus from './../entityStatus';
import SceneVariant from '../../service/domain/SceneVariant';
import { GlassColorAssignmentsItemState, GlassColorAssignmentsState } from './glassState';
import GlassColorAssignment from '../../service/domain/GlassColorAssignment';
import { GlassColorAssignmentsActionType, GlassColorsAssignmentsActions } from './glassColorsAssignmentsActions';

const initialState: GlassColorAssignmentsState = {
  colorAssignments: []
};

export default (state: GlassColorAssignmentsState = initialState, action: GlassColorsAssignmentsActions): GlassColorAssignmentsState => {
  switch (action.type) {
    case GlassColorAssignmentsActionType.LOAD_SUCCESS:
      return {
        ...state,
        colorAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, action.glassColorAssignments, EntityStatus.LOAD_SUCCESS)
      };
    case GlassColorAssignmentsActionType.LOAD_PROGRESS:
      return {
        ...state,
        colorAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, [], EntityStatus.LOAD_IN_PROGRESS)
      };
    case  GlassColorAssignmentsActionType.LOAD_FAILED  :
      return {
        ...state,
        colorAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, [], EntityStatus.LOAD_FAILED)
      };
    case GlassColorAssignmentsActionType.LOAD_REQUEST   :
      return {
        ...state,
        colorAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, [], EntityStatus.NOT_LOADED)
      };
    default:
      return state;
  }
}

function buildAdjustmentAssignmentState(
  currentState: GlassColorAssignmentsState,
  sceneVariant: SceneVariant,
  typeAssignments: GlassColorAssignment[],
  status: EntityStatus)
  : GlassColorAssignmentsItemState[] {
  const result = [...currentState.colorAssignments];
  const foundIndex = result.findIndex(assignment => assignment.sceneVariantId === sceneVariant.id);
  const newAssignment = {
    sceneVariantId: sceneVariant.id,
    status: status,
    colorAssignments: typeAssignments
  };
  if (foundIndex > -1) {
    result[foundIndex] = newAssignment;
  } else {
    result.push(newAssignment);
  }
  return result;
}