import React, { useEffect, useRef, useState } from 'react';
import useLocalizeMessage from '../../localization/localizeMessage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  activePatternSelector,
  loadedPatternsFragmentStatusSelector,
  loadedPatternsHasMoreSelector,
  loadedPatternsSelector,
  patternsStatusSelector,
  patternUrlAliasSelector
} from '../../store/pattern/patternSelectors';
import EntityStatus from '../../store/entityStatus';
import { setActivePatternAction } from '../../store/pattern/patternsActions';
import SubmenuItems, { SidebarItemsMenuSize } from '../../component/configurer/sidebar/SubmenuItems';
import Pattern from '../../service/domain/Pattern';
import EntityWithTitle from '../../service/domain/EntityWithTitle';
import EntityWithThumbnail from '../../service/domain/EntityWithThumbnail';
import MainMenuScrollableSelection, { ACTIVE_ITEM_CLASS } from '../../component/configurer/sidebar/MainMenuScrollableSelection';
import Progress from '../../component/configurer/loader/Progress';

type Props = { onClick: () => void }

interface ExtendedPattern extends Pattern, EntityWithTitle, EntityWithThumbnail {
}

const convertPattern = (pattern?: Pattern): ExtendedPattern | undefined => {
  if (!pattern) {
    return undefined;
  }
  return {
    ...pattern,
    thumbnailImage: pattern.thumbnail,
    title: pattern.title,
    titleCs: pattern.title,
    titleSk: pattern.title
  };
};

const MenuItemPatterns: React.FC<Props> = (props) => {
  const localizeMessage = useLocalizeMessage();
  const patternsStatus = useSelector(patternsStatusSelector);
  const patterns = useSelector(loadedPatternsSelector, shallowEqual);
  const dispatch = useDispatch();
  const activePattern = useSelector(activePatternSelector);
  const patternUrlAlias = useSelector(patternUrlAliasSelector);
  const activePatternThumbnailIdRef = useRef<number>();
  const hasMoreItems = useSelector(loadedPatternsHasMoreSelector);
  const fragmentStatus = useSelector(loadedPatternsFragmentStatusSelector);
  const [scrollTopPosition, setScrollTopPosition] = useState<number>(0);

  useEffect(() => {
    if (activePatternThumbnailIdRef.current !== activePattern.entity?.thumbnailId) {
      activePatternThumbnailIdRef.current = activePattern.entity?.thumbnailId;
    }
    if (patterns.length > 0) {
      const foundInItems = patterns.find(pattern => pattern.thumbnailId === activePatternThumbnailIdRef.current);
      if (!foundInItems) {
        setScrollTopPosition(0);
      }
    }
  }, [patterns, activePattern]);

  const setPattern = (pattern: ExtendedPattern) => () => {
    if (activePattern.entity?.id !== pattern.id) {
      dispatch(setActivePatternAction({
        entity: pattern,
        status: EntityStatus.LOAD_SUCCESS
      }));
    }
  };
  const setScrollPosition = (value: number) => {
    setScrollTopPosition(value);
  };
  const loadPatterns = () => {
    if (fragmentStatus !== EntityStatus.LOAD_IN_PROGRESS && hasMoreItems) {
      // dispatch(loadFragmentRequestAction());
    }
  };
  const convertedPatterns: ExtendedPattern[] = patterns.map(pattern => convertPattern(pattern) as ExtendedPattern);

  if (patterns.length < 1 || activePattern.status !== EntityStatus.LOAD_SUCCESS) {
    return null;
  }
  const activePatternOrder = patterns.findIndex(pattern => pattern.id === activePattern.entity?.id);

  return <MainMenuScrollableSelection
    renderedItemIsNew={activePattern.entity?.alias === patternUrlAlias}
    onClick={props.onClick}
    onScrollDown={loadPatterns}
    title={localizeMessage('sidebar.memuItem.detailmenu.label.PATTERN')}
    rerenderItem={activePattern.entity}
    renderedItemOrder={activePatternOrder}
    setScrollPosition={setScrollPosition}
    scrollTopPosition={scrollTopPosition}
    renderedItemsLength={patterns.length}
  >
    <SubmenuItems items={convertedPatterns}
      activeItem={convertPattern(activePattern.entity)}
      showItemTitle={false}
      onItemClick={setPattern}
      visible={true}
      onClick={() => {}}
      itemsStatus={patternsStatus}
      itemsSize={SidebarItemsMenuSize.SMALL}
      level={2}
      standalone={true}
      activeItemClass={ACTIVE_ITEM_CLASS} />
    {fragmentStatus === EntityStatus.LOAD_IN_PROGRESS && <Progress />}
  </MainMenuScrollableSelection>;
};

export default MenuItemPatterns;
