import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeSceneThemeEntitySelector } from '../../store/scene/sceneThemesSelectors';
import { setActiveSceneThemeAction } from '../../store/scene/sceneThemesActions';
import EntityStatus from '../../store/entityStatus';
import SceneThemeSwitch from '../../component/configurer/switcher/SceneThemeSwitch';
import useLocalizeMessage from '../../localization/localizeMessage';
import { themesForSceneThemeGroupAndActiveGlassAdjustmentSelector } from '../../store/glass/glassAdjustmentThemesSelectors';

const SceneThemeSwitcher: React.FC = React.memo(() => {
  const themes = useSelector(themesForSceneThemeGroupAndActiveGlassAdjustmentSelector);
  const activeTheme = useSelector(activeSceneThemeEntitySelector);
  const dispatch = useDispatch();
  const _ = useLocalizeMessage();
  const onSwitcherClick = () => {
    const currentIndex = themes.findIndex(theme => theme.id === activeTheme?.id);
    if (themes.length > 1) {
      const isLast = currentIndex + 1 === themes.length;
      const newThemeIndex = isLast ? 0 : currentIndex + 1;
      dispatch(setActiveSceneThemeAction({
        status: EntityStatus.LOAD_SUCCESS,
        entity: themes[newThemeIndex]
      }));
    }
  };
  return themes.length <= 1
    ? null
    : <SceneThemeSwitch onClick={onSwitcherClick}
      label={_('themeSwitch.label')}
      currentIndex={themes.findIndex(theme => theme.id === activeTheme?.id)}
      size={themes.length} />;
});

export default SceneThemeSwitcher;
