import { useEffect, useMemo, useState } from 'react';
import { LoadingStatus } from '../../../service/domain/admin/loadingStatus';

interface ImageWrapper {
  image?: HTMLImageElement
}

function clearImage(imageWrapper: ImageWrapper) {
  if (imageWrapper?.image) {
    imageWrapper.image.src = '';
    // @ts-ignore
    imageWrapper.image = null;
  }
}

export const useImageLoader = (imagePath?: string) => {
  const [imageLoadingStatus, setimageLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const imageWrapper = useMemo<ImageWrapper>(() => imagePath ? { image: new Image() } : { image: undefined }, [imagePath]);
  useEffect(() => {
    setimageLoadingStatus(LoadingStatus.NOT_LOADING);
    return () => {
      clearImage(imageWrapper);
    };
  }, [imageWrapper, setimageLoadingStatus]);

  if (imageWrapper?.image) {
    if (!imageWrapper.image.onload) {
      imageWrapper.image.onload = function () {
        if (imageWrapper.image) {
          setimageLoadingStatus(LoadingStatus.SUCCESSFUL);
        }
      };
    }
    if (!imageWrapper.image.onload) {
      imageWrapper.image.onerror = (e: any) => {
        console.error('Image loading error', imagePath, e);
        setimageLoadingStatus(LoadingStatus.ERROR);
      };
    }
    if (!imageWrapper.image.onloadstart) {
      imageWrapper.image.onloadstart = () => {
        setimageLoadingStatus(LoadingStatus.IN_PROGRESS);
      };
    }
    if (!imageWrapper.image.src && imagePath) {
      imageWrapper.image.src = imagePath;
    }
  }

  return () => {
    return {
      image: imageWrapper?.image,
      status: imageLoadingStatus
    };
  };
};