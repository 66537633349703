import EntityStatus from './../entityStatus';
import GlassAdjustmentAssignment from '../../service/domain/GlassAdjustmentAssignment';
import { AdjustmentAssignmentsActionType, GlassAdjustmentsAssignmentsActions } from './glassAdjustmentsAssignmentsActions';
import { GlassAdjustmentAssignmentsItemState, GlassAdjustmentAssignmentsState } from './glassState';

const initialState: GlassAdjustmentAssignmentsState = {
  adjustmentAssignments: [],
  status: EntityStatus.NOT_LOADED
};

export default (state: GlassAdjustmentAssignmentsState = initialState, action: GlassAdjustmentsAssignmentsActions): GlassAdjustmentAssignmentsState => {
  switch (action.type) {
    case AdjustmentAssignmentsActionType.LOAD_SUCCESS:
      return {
        ...state,
        adjustmentAssignments: buildAdjustmentAssignmentState(state, action.sceneVariantId, action.adjustmentAssignments, EntityStatus.LOAD_SUCCESS)
      };
    case AdjustmentAssignmentsActionType.LOAD_PROGRESS:
      return {
        ...state,
        status: EntityStatus.LOAD_IN_PROGRESS
      };
    case  AdjustmentAssignmentsActionType.LOAD_FAILED  :
      return {
        ...state,
        status: EntityStatus.LOAD_FAILED
      };
    case AdjustmentAssignmentsActionType.LOAD_REQUEST   :
      return {
        ...state,
        status: EntityStatus.NOT_LOADED
      };
    default:
      return state;
  }
}

function buildAdjustmentAssignmentState(
  currentState: GlassAdjustmentAssignmentsState,
  sceneVariantId: number,
  adjustmentAssignments: GlassAdjustmentAssignment[],
  status: EntityStatus)
  : GlassAdjustmentAssignmentsItemState[] {
  const result = [...currentState.adjustmentAssignments];
  const foundIndex = result.findIndex(assignment => assignment.sceneVariantId === sceneVariantId);
  const newAssignment = {
    sceneVariantId: sceneVariantId,
    status: status,
    adjustmentAssignments: adjustmentAssignments
  };
  if (foundIndex > -1) {
    result[foundIndex] = newAssignment;
  } else {
    result.push(newAssignment);
  }
  return result;
}