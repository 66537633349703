import StoreState from '../storeState';
import { createSelector } from 'reselect';
import { allScenesSelector } from './sceneSelectors';

/*
 * Shared selectors for scene variants
 */

export const activeSceneThemeSelector = (state: StoreState) => state.scene.sceneThemes.activeTheme;
export const activeSceneThemeStatusSelector = (state: StoreState) => state.scene.sceneThemes.activeTheme.status;
export const activeSceneThemeEntitySelector = (state: StoreState) => state.scene.sceneThemes.activeTheme.entity;
export const sceneThemesSelector = (state: StoreState) => {
  return state.scene.sceneVariants.activeVariant.entity?.themes ?? [];
};
export const allSceneThemesSelector = createSelector([allScenesSelector], (allScenes) => {
  return allScenes
    .flatMap(scene => scene.variants)
    .flatMap(variant => variant.themes);
});
export const activeSceneThemeGroupSelector = (state: StoreState) => state.scene.sceneThemes.activeThemeGroup;
export const activeSceneThemeGroupEntitySelector = (state: StoreState) => state.scene.sceneThemes.activeThemeGroup.entity;
export const sceneThemeGroupsSelector = (state: StoreState) => state.scene.sceneThemes.themeGroups;