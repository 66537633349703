import PatternCategoryAssignments from '../../service/domain/PatternCategoryAssignments';
import EntityStatus from '../entityStatus';
import { PatternCategoryAssignmentActionType, PatternCategoryAssignmentsActions } from './patternCategoryAssignmentsActions';
import { PatternCategoryAssignmentsItemState, PatternCategoryAssignmentsState } from './patternState';
import PatternCategoryChangeParameters from '../../service/dto/PatternCategoryChangeParameters';

const initialState: PatternCategoryAssignmentsState = {
  patternCategoryAssignments: []
};

function createBlankCategoryAssignments(patternChangeParameters: PatternCategoryChangeParameters): PatternCategoryAssignments {
  return {
    categoryIds: [],
    ...patternChangeParameters
  };
}

export default (state: PatternCategoryAssignmentsState = initialState, action: PatternCategoryAssignmentsActions): PatternCategoryAssignmentsState => {
  switch (action.type) {
    case PatternCategoryAssignmentActionType.LOAD_FAILED:
      return {
        ...state,
        patternCategoryAssignments: buildAssignments(state, createBlankCategoryAssignments(action.patternChangeParameters),
          action.patternChangeParameters, EntityStatus.LOAD_FAILED)
      };
    case PatternCategoryAssignmentActionType.LOAD_PROGRESS:
      return {
        ...state,
        patternCategoryAssignments: buildAssignments(state, createBlankCategoryAssignments(action.patternChangeParameters),
          action.patternChangeParameters, EntityStatus.LOAD_IN_PROGRESS)
      };
    case PatternCategoryAssignmentActionType.LOAD_REQUEST:
      return {
        ...state,
        patternCategoryAssignments: buildAssignments(state, createBlankCategoryAssignments(action.patternChangeParameters),
          action.patternChangeParameters, EntityStatus.NOT_LOADED)
      };
    case PatternCategoryAssignmentActionType.LOAD_SUCCESS:
      return {
        ...state,
        patternCategoryAssignments: buildAssignments(state, action.patternCategoryAssignments, action.patternChangeParameters, EntityStatus.LOAD_SUCCESS)
      };
    default:
      return state;
  }
}

function buildAssignments(
  currentState: PatternCategoryAssignmentsState,
  patternCategoryAssignments: PatternCategoryAssignments,
  patternChangeParameters: PatternCategoryChangeParameters,
  status: EntityStatus): PatternCategoryAssignmentsItemState[] {
  const result = [...currentState.patternCategoryAssignments];
  const foundIndex = result.findIndex(assignment => patternCategoryAssignmentEquals(assignment.patternCategoryAssignments, patternChangeParameters));
  const newAssignment = {
    status: status,
    patternCategoryAssignments: patternCategoryAssignments
  };
  if (foundIndex > -1) {
    result[foundIndex] = newAssignment;
  } else {
    result.push(newAssignment);
  }
  return result;
}

export function patternCategoryAssignmentEquals(
  patternCategoryAssignments: PatternCategoryAssignments,
  patternChangeParameters: PatternCategoryChangeParameters) {
  return patternCategoryAssignments.glassAdjustmentId === patternChangeParameters.glassAdjustmentId &&
    patternCategoryAssignments.glassColorId === patternChangeParameters.glassColorId &&
    patternCategoryAssignments.glassTypeId === patternChangeParameters.glassTypeId &&
    patternCategoryAssignments.sceneThemeId === patternChangeParameters.sceneThemeId;
}