import SceneVariant from '../../service/domain/SceneVariant';
import GlassAdjustment from '../../service/domain/GlassAdjustment';
import GlassTypeAssignment from '../../service/domain/GlassTypeAssignment';

export enum GlassTypeAssignmentsActionType {
  LOAD_SUCCESS = 'GLASS_TYPES_ASSIGNMENTS_LOAD_SUCCESS',
  LOAD_FAILED = 'GLASS_TYPES_ASSIGNMENTS_LOAD_FAILED',
  LOAD_REQUEST = 'GLASS_TYPES_ASSIGNMENTS_LOAD_REQUEST',
  LOAD_PROGRESS = 'GLASS_TYPES_ASSIGNMENTS_LOAD_PROGRESS'
}

export type GlassTypesAssignmentsActions =
  GlassTypeAssignmentsLoadRequestAction
  | GlassTypeAssignmentsLoadFailedAction
  | GlassTypeAssignmentsLoadSuccessAction
  | GlassTypeAssignmentsLoadProgressAction;

export function loadGlassTypeAssignmentsRequestAction(
  sceneVariant: SceneVariant,
  glassAdjustment: GlassAdjustment): GlassTypeAssignmentsLoadRequestAction {
  return {
    type: GlassTypeAssignmentsActionType.LOAD_REQUEST,
    sceneVariant,
    glassAdjustment
  };
}

export function loadGlassTypeAssignmentsFailedAction(sceneVariant: SceneVariant, glassAdjustment: GlassAdjustment): GlassTypeAssignmentsLoadFailedAction {
  return {
    type: GlassTypeAssignmentsActionType.LOAD_FAILED,
    sceneVariant,
    glassAdjustment
  };
}

export function loadGlassTypeAssignmentsSuccessAction(glassTypeAssignments: GlassTypeAssignment[],
  sceneVariant: SceneVariant, glassAdjustment: GlassAdjustment): GlassTypeAssignmentsLoadSuccessAction {
  return {
    type: GlassTypeAssignmentsActionType.LOAD_SUCCESS,
    sceneVariant,
    glassAdjustment,
    glassTypeAssignments
  };
}

export function loadGlassTypeAssignmentsProgressAction(
  sceneVariant: SceneVariant,
  glassAdjustment: GlassAdjustment): GlassTypeAssignmentsLoadProgressAction {
  return {
    type: GlassTypeAssignmentsActionType.LOAD_PROGRESS,
    sceneVariant,
    glassAdjustment
  };
}

export interface GlassTypeAssignmentsAction {
  sceneVariant: SceneVariant,
  glassAdjustment: GlassAdjustment
}

export interface GlassTypeAssignmentsLoadRequestAction extends GlassTypeAssignmentsAction {
  type: typeof GlassTypeAssignmentsActionType.LOAD_REQUEST
}

interface GlassTypeAssignmentsLoadProgressAction extends GlassTypeAssignmentsAction {
  type: typeof GlassTypeAssignmentsActionType.LOAD_PROGRESS
}

interface GlassTypeAssignmentsLoadFailedAction extends GlassTypeAssignmentsAction {
  type: typeof GlassTypeAssignmentsActionType.LOAD_FAILED
}

interface GlassTypeAssignmentsLoadSuccessAction extends GlassTypeAssignmentsAction {
  type: typeof GlassTypeAssignmentsActionType.LOAD_SUCCESS,
  glassTypeAssignments: GlassTypeAssignment[]
}