import React from 'react';
import styles from '../../../component/configurer/canvas/Canvas.module.scss';

interface CanvasProps {
  canvasHtmlElementRef: any,
  canvasWidth: number,
  canvasHeight: number,
}

const HtmlCanvas: React.FC<CanvasProps> = React.memo((props: CanvasProps) => {
    return <canvas ref={props.canvasHtmlElementRef} className={styles.canvasImage} style={{
      width: props.canvasWidth,
      height: props.canvasHeight
    }} />;
  }
);

export default HtmlCanvas;