import EntityStatus from '../../../store/entityStatus';
import Pattern from '../../../service/domain/Pattern';
import { StateEntity } from '../../../store/StateEntity';

/**
 * Resolves active pattern according to given parameters if possible
 * @param patternsStatus status of loaded patterns
 * @param patterns loaded patterns
 * @param activePattern active pattern, can be undefined
 * @param activePatternUrlAlias active pattern from URL, can be undefined
 * @return resolved active pattern or undefined if not found
 */
export function resolveActivePattern(patternsStatus: EntityStatus, patterns: Pattern[], activePattern: StateEntity<Pattern>,
  activePatternUrlAlias?: string): StateEntity<Pattern> {
  if (patternsStatus === EntityStatus.LOAD_SUCCESS && patterns.length < 1) {
    return {
      entity: undefined,
      status: EntityStatus.LOAD_SUCCESS
    };
  }
  if (patternsStatus !== EntityStatus.LOAD_SUCCESS) {
    return {
      entity: activePattern.entity,
      status: patternsStatus
    };
  }
  return {
    entity: findDefault(patterns, activePatternUrlAlias, activePattern.entity),
    status: EntityStatus.LOAD_SUCCESS
  };
}

function findDefault(entities: Pattern[], activeUrlAlias?: string, activeState?: Pattern) {
  if (!entities) {
    return;
  }
  let foundEntity;
  if (activeState) {
    foundEntity = entities.find(entity => entity.id === activeState.id);
  }
  if (!foundEntity) {
    foundEntity = entities.find(
      entity => entity.thumbnailId === activeState?.thumbnailId || entity.thumbnailPatternPostfix === activeState?.thumbnailPatternPostfix);
  }
  if (!foundEntity && activeUrlAlias) {
    foundEntity = entities.find(entity => entity.alias === activeUrlAlias);
  }
  if (!foundEntity && entities.length > 0) {
    foundEntity = entities[0];
  }
  return foundEntity;
}