import EntityStatus from '../../../store/entityStatus';
import { GlassColorAssignmentsItemState } from '../../../store/glass/glassState';
import GlassColor from '../../../service/domain/GlassColor';
import GlassColorAssignment from '../../../service/domain/GlassColorAssignment';
import { StateEntity } from '../../../store/StateEntity';

/**
 * resolves active glass color if possible
 * @param glassColorStatus status of the colors, must not be undefined
 * @param glassColors all colors, can be undefined
 * @param glassColorsAssignments, assignments of the glass color to current scene variant and glass adjustment, can be undefined
 * @param activeGlassColorUrl alias of the glass color from url, can be undefined
 * @param activeColor current active glass color, can be undefined
 */
export default (
  glassColorStatus: EntityStatus,
  activeColor: StateEntity<GlassColor>,
  glassColors?: GlassColor[],
  glassColorsAssignments?: GlassColorAssignmentsItemState,
  activeGlassColorUrl?: string): StateEntity<GlassColor> => {
  if (!glassColors || glassColorStatus !== EntityStatus.LOAD_SUCCESS || !glassColorsAssignments || glassColorsAssignments.status
    !== EntityStatus.LOAD_SUCCESS) {
    return {
      entity: activeColor.entity,
      status: glassColorsAssignments?.status ?? EntityStatus.NOT_LOADED
    };
  }
  return {
    entity: findDefaultAdjustment(glassColors, glassColorsAssignments.colorAssignments, activeGlassColorUrl, activeColor.entity),
    status: EntityStatus.LOAD_SUCCESS
  };
}

function findDefaultAdjustment(glassColors: GlassColor[], colorAssignments: GlassColorAssignment[], activeGlassColorUrl?: string,
  activeColor?: GlassColor) {
  colorAssignments.sort((left, right) => left.order - right.order);

  const assignedTypes = colorAssignments
    .map(assignment => glassColors.find(color => color.id === assignment.colorId))
    .filter(color => color !== undefined);

  let foundColor;
  if (activeColor) {
    foundColor = (assignedTypes as GlassColor[]).find(color => color.id === activeColor.id);
  }
  if (!foundColor && activeGlassColorUrl) {
    foundColor = (assignedTypes as GlassColor[]).find(color => color.alias === activeGlassColorUrl);
  }
  if (!foundColor && assignedTypes.length > 0) {
    foundColor = assignedTypes[0];
  }
  return foundColor;
}
