import { BaseEntity } from '../../../service/domain/BaseEntity';
import { DefaultEntity } from '../../../service/domain/DefaultEntity';

/**
 * Resolves active entoty according to given parameters
 * @param entities entities for current scene, can be undefined
 * @param activeUrlAlias active URL alias, can be undefined
 * @param activeState current active entity from app state
 * @return active entity or undefined if is not available
 */
export default <T extends BaseEntity & DefaultEntity>(entities?: T[], activeUrlAlias?: string, activeState?: T) => {
  if (!entities) {
    return;
  }
  return findDefault(entities, activeUrlAlias, activeState);
}

export const findEntity = <T extends BaseEntity>(entities?: T[], activeUrlAlias?: string, activeState?: T) => {
  if (!entities) {
    return;
  }
  return findDefaultBase(entities, activeUrlAlias, activeState);
};

function findDefault<T extends BaseEntity & DefaultEntity>(entities: T[], activeUrlAlias?: string, activeState?: T) {
  let foundEntity;
  if (activeState) {
    foundEntity = entities.find(entity => entity.id === activeState.id);
  }
  if (!foundEntity && activeUrlAlias) {
    foundEntity = entities.find(entity => entity.alias === activeUrlAlias);
  }
  if (!foundEntity) {
    foundEntity = entities.find(entity => entity.default);
  }
  if (!foundEntity && entities.length > 0) {
    foundEntity = entities[0];
  }
  return foundEntity;
}

function findDefaultBase<T extends BaseEntity>(entities: T[], activeUrlAlias?: string, activeState?: T) {
  let foundEntity;
  if (activeState) {
    foundEntity = entities.find(entity => entity.id === activeState.id);
  }
  if (!foundEntity && activeUrlAlias) {
    foundEntity = entities.find(entity => entity.alias === activeUrlAlias);
  }
  if (!foundEntity && entities.length > 0) {
    foundEntity = entities[0];
  }
  return foundEntity;
}