import GlassAdjustment from './domain/GlassAdjustment';
import SceneVariant from './domain/SceneVariant';
import GlassTypeAssignment from './domain/GlassTypeAssignment';
import configuration from './configuration';
import { SearchParameters } from './dto/SearchParameters';
import GlassType from './domain/GlassType';
import { UrlBuilder } from './fetchHelper';

interface GlassTypeSearchParameters extends SearchParameters {
  sceneVariantId?: number,
  glassAdjustmentId?: number
}

class GlassTypesService {
  findAssignmentsForScene(sceneVariant: SceneVariant, glassAdjustment: GlassAdjustment): Promise<GlassTypeAssignment[]> {
    return fetch(
      `${configuration.publicApiUrl}/glass-type-assignment?sceneVariantId=${sceneVariant.id}&adjustmentId=${glassAdjustment.id}`)
      .then(value => {
        return value.json();
      });
  }

  findAssignments(glassTypeId?: number, sceneVariantId?: number, glassAdjustmentId?: number, onlyActive?: boolean): Promise<GlassTypeAssignment[]> {
    const url = UrlBuilder.fromBaseUrl(configuration.adminApiUrl, '/glass-type-assignments')
      .withParameter('glassTypeId', glassTypeId)
      .withParameter('sceneVariantId', sceneVariantId)
      .withParameter('glassAdjustmentId', glassAdjustmentId)
      .withParameter('onlyActive', onlyActive)
      .build();

    return fetch(url).then(value => value.json());
  }

  saveAssignments(assignments: GlassTypeAssignment[], sceneVariantId: number, glassAdjustmentId: number): Promise<GlassTypeAssignment[]> {
    return fetch(`${configuration.adminApiUrl}/glass-type-assignments/save?sceneVariantId=${sceneVariantId}&glassAdjustmentId=${glassAdjustmentId}`, {
      method: 'POST',
      body: JSON.stringify(assignments)
    }).then(value => {
      return value.json();
    });
  };

  search(searchParameters: GlassTypeSearchParameters) {
    const search = `${configuration.publicApiUrl}/glass-types?sceneVariantId=${searchParameters.sceneVariantId}&adjustmentId=${searchParameters.glassAdjustmentId}`;
    return fetch(search).then(value => {
      return value.json();
    });
  }

  findById(id: number): Promise<GlassType> {
    return fetch(`${configuration.adminApiUrl}/glass-types/${id}`).then(value => {
      return value.json();
    });
  }

  save(glassType: GlassType): Promise<GlassType> {
    return fetch(`${configuration.adminApiUrl}/glass-types/save/${glassType.id}`, {
      method: 'POST',
      body: JSON.stringify(glassType)
    }).then(value => {
      return value.json();
    });
  };

  findAll(onlyActive: boolean): Promise<Array<GlassType>> {
    return fetch(`${configuration.adminApiUrl}/glass-types?onlyActive=${onlyActive}`).then(value => {
      return value.json();
    });
  }
}

export default new GlassTypesService();