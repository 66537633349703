import PatternCategory from '../../service/domain/PatternCategory';
import { StateEntity } from '../StateEntity';

export enum PatternCategoriesActionType {
  LOAD_SUCCESS = 'PATTERN_CATEGORIES_LOAD_SUCCESS',
  LOAD_FAILED = 'PATTERN_CATEGORIES_LOAD_FAILED',
  LOAD_REQUEST = 'PATTERN_CATEGORIES_LOAD_REQUEST',
  LOAD_PROGRESS = 'PATTERN_CATEGORIES_LOAD_PROGRESS',
  SET_ACTIVE = 'PATTERN_CATEGORIES_SET_ACTIVE',
  SET_URL_ALIAS = 'PATTERN_CATEGORIES_SET_URL_ALIAS'
}

export type PatternCategoriesActions =
  PatternCategoriesLoadRequestAction
  | PatternCategoriesLoadFailedAction
  | PatternCategoriesLoadSuccessAction
  | PatternCategoriesLoadProgressAction
  | PatternCategoriesAddActiveAction
  | SetPatternCategoryUrlAliasAction;

export function loadPatternCategoriesRequestAction(): PatternCategoriesLoadRequestAction {
  return {
    type: PatternCategoriesActionType.LOAD_REQUEST
  };
}

export function loadPatternCategoriesFailedAction(): PatternCategoriesLoadFailedAction {
  return {
    type: PatternCategoriesActionType.LOAD_FAILED
  };
}

export function loadPatternCategoriesSuccessAction(patternCategories: PatternCategory[]): PatternCategoriesLoadSuccessAction {
  return {
    type: PatternCategoriesActionType.LOAD_SUCCESS,
    patternCategories
  };
}

export function loadPatternCategoriesProgressAction(): PatternCategoriesLoadProgressAction {
  return {
    type: PatternCategoriesActionType.LOAD_PROGRESS
  };
}

export function setActivePatternCategoryAction(patternCategory: StateEntity<PatternCategory>): PatternCategoriesAddActiveAction {
  return {
    type: PatternCategoriesActionType.SET_ACTIVE,
    patternCategory
  };
}

export function setPatternCategoryUrlAliasAction(alias?: string): SetPatternCategoryUrlAliasAction {
  return {
    type: PatternCategoriesActionType.SET_URL_ALIAS,
    alias
  };
}

interface PatternCategoriesLoadRequestAction {
  type: typeof PatternCategoriesActionType.LOAD_REQUEST,
}

interface PatternCategoriesLoadProgressAction {
  type: typeof PatternCategoriesActionType.LOAD_PROGRESS,
}

interface PatternCategoriesLoadFailedAction {
  type: typeof PatternCategoriesActionType.LOAD_FAILED,
}

interface PatternCategoriesLoadSuccessAction {
  type: typeof PatternCategoriesActionType.LOAD_SUCCESS,
  patternCategories: PatternCategory[]
}

interface PatternCategoriesAddActiveAction {
  type: typeof PatternCategoriesActionType.SET_ACTIVE,
  patternCategory: StateEntity<PatternCategory>
}

interface SetPatternCategoryUrlAliasAction {
  type: typeof PatternCategoriesActionType.SET_URL_ALIAS,
  alias?: string
}