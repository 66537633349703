import { GlassColorsState } from './glassState';
import EntityStatus from '../entityStatus';
import { GlassColorsActionType, GlassColorsActionTypes } from './glassColorsActions';

const initialState: GlassColorsState = {
  colors: [],
  status: EntityStatus.NOT_LOADED,
  activeColor: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  colorUrlAlias: undefined
};

export default (state: GlassColorsState = initialState, action: GlassColorsActionTypes): GlassColorsState => {
  switch (action.type) {
    case GlassColorsActionType.LOAD_SUCCESS:
      return {
        ...state,
        colors: action.glassColors,
        status: EntityStatus.LOAD_SUCCESS
      };
    case GlassColorsActionType.LOAD_FAILED:
      return {
        ...state,
        colors: [],
        status: EntityStatus.LOAD_FAILED
      };
    case GlassColorsActionType.LOAD_REQUEST:
      return {
        ...state,
        colors: [],
        status: EntityStatus.NOT_LOADED
      };
    case GlassColorsActionType.LOAD_PROGRESS:
      return {
        ...state,
        colors: [],
        status: EntityStatus.LOAD_IN_PROGRESS
      };
    case GlassColorsActionType.SET_ACTIVE:
      return {
        ...state,
        activeColor: action.glassColor
      };
    case GlassColorsActionType.SET_URL_ALIAS:
      return {
        ...state,
        colorUrlAlias: action.alias
      };
    default:
      return state;
  }
}