import EntityStatus from '../entityStatus';
import { PatternsActions, PatternsActionType } from './patternsActions';
import { PatternsState } from './patternState';
import Pattern from '../../service/domain/Pattern';

const initialState: PatternsState = {
  relatedPatterns: {
    patterns: [],
    status: EntityStatus.NOT_LOADED,
    fragmentStatus: EntityStatus.NOT_LOADED,
    hasMore: undefined,
    loadedOffset: undefined
  },
  activePattern: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  paging: {
    previousPatterns: [],
    nextPatterns: []
  }
};

export default (state: PatternsState = initialState, action: PatternsActions) => {
  switch (action.type) {
    case PatternsActionType.LOAD_FAILED:
      return {
        ...state,
        relatedPatterns: {
          ...state.relatedPatterns,
          patterns: [],
          status: EntityStatus.LOAD_FAILED,
          hasMore: undefined,
          loadedOffset: undefined,
          fragmentStatus: EntityStatus.LOAD_FAILED
        }
      };
    case PatternsActionType.LOAD_PROGRESS:
      return {
        ...state,
        relatedPatterns: {
          ...state.relatedPatterns,
          patterns: [],
          status: EntityStatus.LOAD_IN_PROGRESS
        }
      };
    case PatternsActionType.LOAD_REQUEST:
      return {
        ...state,
        relatedPatterns: {
          ...state.relatedPatterns,
          status: EntityStatus.NOT_LOADED,
          fragmentStatus: EntityStatus.NOT_LOADED,
          hasMore: true,
          loadedOffset: undefined,
          patterns: []
        }
      };
    case PatternsActionType.LOAD_FRAGMENT_SUCCESS:
      return {
        ...state,
        relatedPatterns: {
          ...state.relatedPatterns,
          status: EntityStatus.LOAD_SUCCESS,
          fragmentStatus: EntityStatus.LOAD_SUCCESS,
          hasMore: action.hasMore,
          loadedOffset: action.loadedOffset,
          patterns: mergePatterns(state.relatedPatterns.patterns, action.patterns)
        }
      };
    case PatternsActionType.LOAD_FRAGMENT_FAILED: {
      return {
        ...state,
        relatedPatterns: {
          ...state.relatedPatterns,
          fragmentStatus: EntityStatus.LOAD_FAILED
        }
      };
    }
    case PatternsActionType.LOAD_FRAGMENT_PROGRESS: {
      return {
        ...state,
        relatedPatterns: {
          ...state.relatedPatterns,
          fragmentStatus: EntityStatus.LOAD_IN_PROGRESS
        }
      };
    }
    case PatternsActionType.SET_ACTIVE:
      return {
        ...state,
        activePattern: action.pattern
      };
    case PatternsActionType.INVALIDATE_ACTIVE:
      return {
        ...state,
        relatedPatterns: {
          ...state.relatedPatterns,
          status: EntityStatus.NOT_LOADED,
          patterns: []
        },
        activePattern: {
          ...state.activePattern,
          status: EntityStatus.NOT_LOADED
        }
      };
    case PatternsActionType.SET_PREVNEXT:
      return {
        ...state,
        paging: {
          previousPatterns: action.previous,
          nextPatterns: action.next
        }
      };
    case PatternsActionType.SET_ACTIVE_URL:
      return {
        ...state,
        patternUrlAlias: action.activePatternUrl
      };
    default:
      return state;
  }
};

function mergePatterns(originalPatterns: Pattern[], newPatterns: Pattern[]) {
  let duplicatePatternFound = false;
  if (originalPatterns.length > 0 && newPatterns.length > 0) {
    const patternToSearch = originalPatterns[0];
    duplicatePatternFound = newPatterns.findIndex(pattern => pattern.id === patternToSearch.id) > -1;
  }
  const adjustedOriginalPatterns = duplicatePatternFound ? originalPatterns.slice(1) : originalPatterns;
  return [...adjustedOriginalPatterns, ...newPatterns];
}