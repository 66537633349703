import React, { useEffect } from 'react';
import { StateEntity } from '../../../store/StateEntity';
import { BaseEntity } from '../../../service/domain/BaseEntity';
import EntityStatus from '../../../store/entityStatus';
import applicationUrlService from '../../../service/applicationUrlService';
import { shallowEqual, useSelector } from 'react-redux';
import { activePatternSelector } from '../../../store/pattern/patternSelectors';
import { activePatternCategorySelector } from '../../../store/pattern/patternCategoriesSelectors';
import { activeGlassTypeEntitySelector } from '../../../store/glass/glassTypesSelectors';
import { activeGlassColorEntitySelector } from '../../../store/glass/glassColorsSelectors';
import { activeGlassAdjustmentEntitySelector } from '../../../store/glass/glassAdjustmentsSelectors';
import { activeSceneThemeEntitySelector } from '../../../store/scene/sceneThemesSelectors';
import { activeSceneVariantEntitySelector } from '../../../store/scene/sceneVariantsSelectors';
import { activeSceneEntitySelector } from '../../../store/scene/sceneSelectors';
import reportingService from '../../../service/reportingService';

interface Props {
}

const findLoadedEntityAlias = <T extends BaseEntity>(entity: StateEntity<T>) => {
  let result = undefined;
  if (entity.status === EntityStatus.LOAD_SUCCESS) {
    result = entity.entity?.alias;
  }
  return result;
};

const SceneReportingProvider: React.FC<Props> = () => {
  const scene = useSelector(activeSceneEntitySelector);
  const sceneVariant = useSelector(activeSceneVariantEntitySelector);
  const sceneTheme = useSelector(activeSceneThemeEntitySelector);
  const glassAdjustment = useSelector(activeGlassAdjustmentEntitySelector);
  const glassColor = useSelector(activeGlassColorEntitySelector);
  const glassType = useSelector(activeGlassTypeEntitySelector);
  const patternCategory = useSelector(activePatternCategorySelector, shallowEqual);
  const pattern = useSelector(activePatternSelector);

  const sceneAlias = scene?.alias;
  const sceneVariantAlias = sceneVariant?.alias;
  const sceneThemeAlias = sceneTheme?.alias;
  const glassAdjustmentAlias = glassAdjustment?.alias;
  const glassTypeAlias = glassType?.alias;
  const glassColorAlias = glassColor?.alias;
  const patternCategoryAlias = findLoadedEntityAlias(patternCategory);
  const patternAlias = findLoadedEntityAlias(pattern);

  useEffect(() => {
    if (sceneAlias && sceneVariantAlias && sceneThemeAlias && glassAdjustmentAlias && glassTypeAlias && glassColorAlias && patternCategoryAlias &&
      patternAlias) {
      const reportingUrl = applicationUrlService.composeShareableUrl(sceneAlias, sceneVariantAlias, sceneThemeAlias, glassAdjustmentAlias, glassTypeAlias,
        glassColorAlias, patternCategoryAlias, patternAlias);
      reportingService.reportCustomPage(reportingUrl);
    }
  }, [
    sceneAlias,
    sceneVariantAlias,
    sceneThemeAlias,
    glassAdjustmentAlias,
    glassTypeAlias,
    glassColorAlias,
    patternCategoryAlias,
    patternAlias]);

  return null;
};

export default SceneReportingProvider;
