import React from 'react';
import { DetailMenuProps } from '../../component/configurer/sidebar/DetailMenu';
import { useDispatch, useSelector } from 'react-redux';
import { activeGlassColorSelector, glassColorAssignmentsSelector, glassColorsSelector } from '../../store/glass/glassColorsSelectors';
import GlassColor from '../../service/domain/GlassColor';
import { setActiveGlassColorAction } from '../../store/glass/glassColorsActions';
import EntityStatus from '../../store/entityStatus';
import SubmenuItems, { SidebarItemsMenuSize } from '../../component/configurer/sidebar/SubmenuItems';
import style from '../../component/configurer/sidebar/DetailMenu.module.scss';

type Props = Pick<DetailMenuProps, 'title'>

const GlassColorDetailMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const activeGlassColor = useSelector(activeGlassColorSelector);
  const assignments = useSelector(glassColorAssignmentsSelector);
  const glassColors = useSelector(glassColorsSelector);
  const currentGlassColors = glassColors.filter(color => assignments?.colorAssignments.find(assignment => assignment.colorId === color.id));

  const setActiveGlassColor = (glassColor: GlassColor) => () => {
    if (glassColor !== activeGlassColor.entity) {
      dispatch(setActiveGlassColorAction({
        entity: glassColor,
        status: EntityStatus.LOAD_SUCCESS
      }));
    }
  };
  return currentGlassColors.length > 1
    ? <>
      {props.title && <div className={style.detailMenuHeader}>
        <div className={style.detailMenuHeaderTitle}>{props.title}</div>
      </div>}
      <SubmenuItems items={currentGlassColors}
        activeItem={activeGlassColor.entity}
        showItemTitle={true}
        onItemClick={setActiveGlassColor}
        visible={true}
        onClick={() => {}}
        itemsStatus={activeGlassColor.status}
        itemsSize={SidebarItemsMenuSize.SMALL}
        level={2} />
    </>
    : null;
};

export default GlassColorDetailMenu;
