import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import scenesReducer from './scene/scenesReducer';
import rootSaga from '../saga/rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';
import patternReducer from './pattern/patternReducer';
import glassReducer from './glass/glassReducer';
import layoutReducer from './layout/layoutReducer';

/**
 * Initializes the Redux store and all necessary middleware and starts them
 */
export default () => {
  const sagaMiddleware = createSagaMiddleware();
  const reducers = {
    scene: scenesReducer,
    glass: glassReducer,
    pattern: patternReducer,
    layout: layoutReducer
  };
  const store = createStore(combineReducers(reducers), composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return store;
}