import { DEFAULT_LOCALE, DOMAIN_LOCALE_TRANSLATION, fromValue, SUPPORTED_LOCALE_VALUES, SupportedLocale } from './localeConfiguration';

/**
 * Resolves locale for configurer
 * @param urlLocale locale given in URL, can be undefined
 */
export default (urlLocale?: string): SupportedLocale => {
  if (urlLocale && localeSupported(urlLocale)) {
    return fromValue(urlLocale);
  }
  return findDefaultLocale();
}

export function localeSupported(locale: string): boolean {
  return SUPPORTED_LOCALE_VALUES.includes(locale.toLowerCase());
}

export function findDefaultLocale() {
  const allLocale: string[] = [];
  addValidUrlLocale(allLocale, DOMAIN_LOCALE_TRANSLATION.get(findHostnamePostfix(window.location.hostname)));
  allLocale.push(...navigator.languages?.slice() ?? []);
  const foundLocale = allLocale
    .filter(locale => locale && locale.length >= 2)
    .map(locale => locale.toLocaleLowerCase().substring(0, 2))
    .find(locale => SUPPORTED_LOCALE_VALUES.includes(locale));
  return foundLocale ? fromValue(foundLocale) : DEFAULT_LOCALE;
}

export function findHostnamePostfix(hostname: string) {
  const dotIndex = hostname.lastIndexOf('.');
  return hostname.substring(dotIndex + 1).toLowerCase();
}

function addValidUrlLocale(allLocales: string[], urlLocale?: string) {
  if (urlLocale && urlLocale.length === 2) {
    allLocales.unshift(urlLocale);
  }
}