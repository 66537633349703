import React, { useMemo, useState } from 'react';
import GlassAdjustment from '../../service/domain/GlassAdjustment';
import EntityStatus from '../../store/entityStatus';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveAdjustmentAction } from '../../store/glass/glassAdjustmentsActions';
import SubmenuItems, { ItemType, SidebarItemsMenuSize } from '../../component/configurer/sidebar/SubmenuItems';
import GlassTypeDetailMenu from './GlassTypeDetailMenu';
import { adjustmentAssignmentsForThemeGroupSelector, adjustmentsSelector } from '../../store/glass/glassAdjustmentsSelectors';
import GlassType from '../../service/domain/GlassType';
import { StateEntity } from '../../store/StateEntity';

interface Props {
  activeGlassAdjustment: StateEntity<GlassAdjustment>,
  activeGlassType: StateEntity<GlassType>,
  status: EntityStatus,
  onClick: () => void,
  visible: boolean
}

const GlassAdjustmentsSubMenu: React.FC<Props> = (props) => {
  const adjustmentAssignments = useSelector(adjustmentAssignmentsForThemeGroupSelector);
  const adjustments = useSelector(adjustmentsSelector);
  const dispatch = useDispatch();
  const [glassTypesVisible, setGlassTypesVisible] = useState<boolean>(true);
  const assignedAdjustments = adjustments.filter(
    adjustment => adjustmentAssignments.find(assignment => assignment.adjustmentId === adjustment.id));
  const setActiveAdjustment = (item: ItemType) => () => {
    const adjustment = item as GlassAdjustment;
    if (adjustment !== props.activeGlassAdjustment.entity) {
      dispatch(setActiveAdjustmentAction({
        entity: adjustment,
        status: EntityStatus.LOAD_SUCCESS
      }));
    }
    setGlassTypesVisible(true);
    //TODO PCM vyresit pripad, ze ma sklo dalsi mozne vybery
    props.onClick();
  };
  const showAdditionalContent = (item: ItemType) => {
    return item === props.activeGlassAdjustment.entity;
  };
  const { activeGlassType } = props;

  const additionalContent = useMemo(
    () => {
      const onGlassTypeMenuClose = () => {
        setGlassTypesVisible(false);
      };
      return <GlassTypeDetailMenu
        visible={glassTypesVisible}
        onCloseClick={onGlassTypeMenuClose}
        activeGlassType={activeGlassType}
        onClick={props.onClick} />;
    }, [glassTypesVisible, setGlassTypesVisible, activeGlassType, props.onClick]);

  return <SubmenuItems items={assignedAdjustments}
    activeItem={props.activeGlassAdjustment.entity}
    showItemTitle={true}
    onItemClick={setActiveAdjustment}
    visible={props.visible}
    onClick={props.onClick}
    itemsStatus={props.activeGlassAdjustment.status}
    showAdditionalContent={showAdditionalContent}
    additionalContent={additionalContent}
    itemsSize={SidebarItemsMenuSize.LARGE}
    level={1} />;
};

export default GlassAdjustmentsSubMenu;
