import SceneTheme from '../../service/domain/SceneTheme';
import { SceneThemesActions, SceneThemesActionType } from './sceneThemesActions';
import { StateEntity } from '../StateEntity';
import EntityStatus from '../entityStatus';
import SceneThemeGroup from '../../service/domain/SceneThemeGroup';
import { ScenesActions, ScenesActionType } from './scenesActions';
import { SceneThemeGroupsActions, SceneThemeGroupsActionType } from './sceneThemeGroupsActions';

export interface SceneThemesState {
  activeTheme: StateEntity<SceneTheme>,
  themeGroups: SceneThemeGroup[],
  themeGroupsState: EntityStatus,
  activeThemeGroup: StateEntity<SceneThemeGroup>
  sceneThemeUrlAlias?: string
}

const initialState: SceneThemesState = {
  activeTheme: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  themeGroups: [],
  themeGroupsState: EntityStatus.NOT_LOADED,
  activeThemeGroup: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  }
};

export default (state: SceneThemesState = initialState, action: SceneThemesActions | ScenesActions | SceneThemeGroupsActions): SceneThemesState => {
  switch (action.type) {
    case ScenesActionType.LOAD_SUCCESS:
      return {
        ...state,
        themeGroups: action.themeGroups,
        themeGroupsState: EntityStatus.LOAD_SUCCESS
      };
    case ScenesActionType.LOAD_REQUEST:
      return {
        ...state,
        themeGroups: [],
        themeGroupsState: EntityStatus.NOT_LOADED
      };
    case ScenesActionType.LOAD_PROGRESS:
      return {
        ...state,
        themeGroups: [],
        themeGroupsState: EntityStatus.LOAD_IN_PROGRESS
      };
    case ScenesActionType.LOAD_FAILED:
      return {
        ...state,
        themeGroups: [],
        themeGroupsState: EntityStatus.LOAD_FAILED
      };
    case SceneThemesActionType.SET_ACTIVE:
      return {
        ...state,
        activeTheme: action.sceneTheme
      };
    case SceneThemesActionType.SET_URL_ALIAS:
      return {
        ...state,
        sceneThemeUrlAlias: action.alias
      };
    case SceneThemeGroupsActionType.SET_ACTIVE:
      return {
        ...state,
        activeThemeGroup: action.sceneThemeGroup
      };
    default:
      return state;
  }
}