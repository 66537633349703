import Scene from '../../../service/domain/Scene';
import EntityStatus from '../../../store/entityStatus';
import activeEntityResolver from './activeEntityResolver';
import { StateEntity } from '../../../store/StateEntity';

/**
 * Resolves default active scene if possible
 * @param scenesStatus status of the all loaded scenes, must not be undefined
 * @param scenes all loaded scenes, can be undefined
 * @param activeSceneAlias alias of the active scene, can be undefined
 * @param currentActiveScene current active scene, can be undefined
 * @return default scene or undefined if default active scene cannot be resolved
 */
export default (scenesStatus: EntityStatus, scenes?: Scene[], activeSceneAlias?: string, currentActiveScene?: Scene): StateEntity<Scene> => {
  if (!scenes || scenesStatus !== EntityStatus.LOAD_SUCCESS) {
    return {
      entity: currentActiveScene,
      status: scenesStatus
    };
  }
  return {
    entity: activeEntityResolver(scenes, activeSceneAlias, currentActiveScene),
    status: EntityStatus.LOAD_SUCCESS
  };
}