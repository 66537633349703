import React from 'react';
import style from './MainMenu.module.scss';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../../store/layout/layoutSelector';

const MainMenu: React.FC = (props) => {
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);

  return (
    <div className={sidebarMinimized ? style.mainMenuMinimized : style.mainMenu}>
      {props.children}
    </div>
  );
};

export default MainMenu;
