import SceneVariant from './domain/SceneVariant';
import GlassColorAssignment from './domain/GlassColorAssignment';
import configuration from './configuration';
import { SearchParameters } from './dto/SearchParameters';
import GlassColor from './domain/GlassColor';
import { UrlBuilder } from './fetchHelper';

interface GlassColorSearchParameters extends SearchParameters {
  sceneVariantId?: number
}

class GlassColorService {
  findAssignmentsForScene(sceneVariant: SceneVariant): Promise<GlassColorAssignment[]> {
    return fetch(`${configuration.publicApiUrl}/glass-color-assignment?sceneVariantId=${sceneVariant.id}`).then(value => {
      return value.json();
    });
  }

  findAssignments(glassColorId?: number, sceneVariantId?: number, onlyActive?: boolean): Promise<GlassColorAssignment[]> {
    const url = UrlBuilder.fromBaseUrl(configuration.adminApiUrl, '/glass-color-assignments')
      .withParameter('sceneVariantId', sceneVariantId)
      .withParameter('glassColorId', glassColorId)
      .withParameter('onlyActive', onlyActive)
      .build();

    return fetch(url).then(value => value.json());
  }

  saveAssignments(assignments: GlassColorAssignment[], sceneVariantId: number): Promise<GlassColorAssignment[]> {
    return fetch(`${configuration.adminApiUrl}/glass-color-assignments/save?sceneVariantId=${sceneVariantId}`, {
      method: 'POST',
      body: JSON.stringify(assignments)
    }).then(value => {
      return value.json();
    });
  };

  search(searchParameters: GlassColorSearchParameters) {
    return fetch(
      `${configuration.publicApiUrl}/glass-colors?sceneVariantId=${searchParameters.sceneVariantId}`).then(value => {
      return value.json();
    });
  }

  findById(id: number): Promise<GlassColor> {
    return fetch(`${configuration.adminApiUrl}/glass-colors/${id}`).then(value => {
      return value.json();
    });
  }

  save(glassColor: GlassColor): Promise<GlassColor> {
    return fetch(`${configuration.adminApiUrl}/glass-colors/save/${glassColor.id}`, {
      method: 'POST',
      body: JSON.stringify(glassColor)
    }).then(value => {
      return value.json();
    });
  };

  findAll(onlyActive: boolean): Promise<Array<GlassColor>> {
    return fetch(`${configuration.adminApiUrl}/glass-colors?onlyActive=${onlyActive}`).then(value => {
      return value.json();
    });
  }
}

export default new GlassColorService();