import GlassAdjustment from '../../../service/domain/GlassAdjustment';
import EntityStatus from '../../../store/entityStatus';
import GlassAdjustmentAssignment from '../../../service/domain/GlassAdjustmentAssignment';
import { GlassAdjustmentAssignmentsItemState } from '../../../store/glass/glassState';
import { StateEntity } from '../../../store/StateEntity';

/**
 * resolves active adjustment if possible
 * @param adjustmentStatus status of the adjustment, must not be undefined
 * @param adjustments all adjustments, can be undefined
 * @param adjustmentAssignments, assignments of the adjustment to current scene variant, can be undefined
 * @param activeAdjustment current active adjustment, can be undefined
 * @param activeAdjustmentAlias, alias of the active adjustment from url, can be undefined
 */
export default (
  adjustmentStatus: EntityStatus,
  activeAdjustment: StateEntity<GlassAdjustment>,
  adjustments?: GlassAdjustment[],
  adjustmentAssignments?: GlassAdjustmentAssignmentsItemState,
  activeAdjustmentAlias?: string): StateEntity<GlassAdjustment> => {
  if (!adjustments || adjustmentStatus !== EntityStatus.LOAD_SUCCESS || !adjustmentAssignments || adjustmentAssignments.status
    !== EntityStatus.LOAD_SUCCESS) {
    return {
      entity: activeAdjustment.entity,
      status: adjustmentAssignments?.status ?? EntityStatus.NOT_LOADED
    };
  }
  return {
    entity: findDefaultAdjustment(adjustments, adjustmentAssignments.adjustmentAssignments, activeAdjustmentAlias, activeAdjustment.entity),
    status: EntityStatus.LOAD_SUCCESS
  };
}

function findDefaultAdjustment(
  adjustments: GlassAdjustment[],
  adjustmentAssignments: GlassAdjustmentAssignment[],
  activeAdjustmentAlias?: string,
  activeAdjustment?: GlassAdjustment) {
  adjustmentAssignments.sort((left, right) => left.order - right.order);
  const assignedAdjustments = adjustmentAssignments
    .map(assignment => adjustments.find(adjustment => adjustment.id === assignment.adjustmentId))
    .filter(adjustment => adjustment !== undefined) as GlassAdjustment[];

  let foundAdjustment;
  if (activeAdjustment) {
    foundAdjustment = assignedAdjustments.find(adjustment => adjustment.id === activeAdjustment.id);
  }
  if (!foundAdjustment && activeAdjustmentAlias) {
    foundAdjustment = assignedAdjustments.find(adjustment => adjustment.alias === activeAdjustmentAlias);
  }
  if (!foundAdjustment) {
    const defaultAssignment = adjustmentAssignments.find(assignment => assignment.default);
    if (defaultAssignment) {
      foundAdjustment = assignedAdjustments.find(adjustment => adjustment.id === defaultAssignment.adjustmentId);
    }
  }
  if (!foundAdjustment && assignedAdjustments.length > 0) {
    foundAdjustment = assignedAdjustments[0];
  }
  return foundAdjustment;
}
