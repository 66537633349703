import React from 'react';
import { MenuItemProperties } from './Sidebar';
import MainMenuItem, { IconDirection } from '../../component/configurer/sidebar/MainMenuItem';
import useLocalizeMessage from '../../localization/localizeMessage';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../store/layout/layoutSelector';
import useLocalizeEntityTitle from '../../localization/localizeEntityTitle';
import { activeSceneSelector, allScenesSelector } from '../../store/scene/sceneSelectors';
import SidebarSubmenu, { Width } from '../../component/configurer/sidebar/SidebarSubmenu';
import SceneSubMenu from './SceneSubMenu';

const MenuItemScene: React.FC<MenuItemProperties> = (props) => {
  const localizeMessage = useLocalizeMessage();
  const localizedEntityTitle = useLocalizeEntityTitle();
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);
  const activeScene = useSelector(activeSceneSelector);
  const scenes = useSelector(allScenesSelector);

  return <>
    <MainMenuItem
      visible={scenes.length > 1}
      active={props.subMenuVisible}
      label={localizeMessage('sidebar.memuItem.label.SCENE')}
      subLabel={localizedEntityTitle(activeScene.entity)}
      imagePath={activeScene?.entity?.thumbnailImage}
      showLabel={!sidebarMinimized}
      showIcon={true}
      itemStatus={activeScene.status}
      iconDirection={IconDirection.RIGHT}
      hasAction={true}
      onclick={props.onClick} />
    <SidebarSubmenu
      onClick={props.onClick}
      visible={props.subMenuVisible}
      title={localizeMessage('sidebar.memuItem.submenu.label.SCENE')}
      width={Width.THIN}>
      <SceneSubMenu
        onClick={props.onClick}
        visible={props.subMenuVisible}
        activeScene={activeScene} />
    </SidebarSubmenu>
  </>;
};

export default MenuItemScene;
