import React from 'react';
import style from './MainMenu.module.scss';
import EntityStatus from '../../../store/entityStatus';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../../store/layout/layoutSelector';
import publicApiImageService from '../../../service/publicApiImageService';
import IconArrowRight from '../icons/IconArrowRight';
import IconArrowDown from '../icons/IconArrowDown';
import clsx from 'clsx';

export enum IconDirection {
  RIGHT,
  DOWN
}

interface Props {
  label: string,
  subLabel?: string,
  visible: boolean,
  imagePath?: string,
  itemStatus: EntityStatus,
  onclick: () => void,
  showLabel: boolean,
  showIcon: boolean,
  active: boolean,
  iconDirection: IconDirection,
  hasAction: boolean
}

const MainMenuItem: React.FC<Props> = (props) => {
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);
  const wrapperClass = clsx(style.mainMenuItem,
    props.hasAction ? style.mainMenuItemClickable : undefined,
    props.visible ? style.mainMenuItemVisible : undefined,
    props.active ? style.mainMenuItemActive : undefined,
    props.iconDirection === IconDirection.DOWN ? style.mainMenuItemNoBorderBottom : undefined);
  const iconClass = sidebarMinimized ? style.mainMenuItemHeaderMinimized : style.mainMenuItemHeader;
  const labelClass = props.showIcon ? style.mainMenuItemLabel : style.mainMenuItemLabelWithoutIcon;
  const icon = <div className={iconClass}>
    {props.imagePath &&
    <div className={`${style.mainMenuItemHeaderIcon}`}
      style={{ backgroundImage: `url(${publicApiImageService.findThumbnailImagePath(props.imagePath)})` }}
      title={props.label} />}
    {!props.imagePath &&
    <div className={style.mainMenuItemHeaderIcon} title={props.label} />}</div>;
  return <div className={wrapperClass} onClick={props.onclick}>
    {props.visible && (<>
      {props.showIcon && icon}
      {props.showLabel && <div className={labelClass}>
        <div className={style.mainMenuItemLabelText}>{props.label}</div>
        {props.subLabel !== undefined && <div className={style.mainMenuItemSubLabelText}>{props.subLabel}</div>}
      </div>}
      {props.showLabel &&
      <div className={style.mainMenuItemLabelIcon}>{props.iconDirection === IconDirection.DOWN ? <IconArrowDown /> : <IconArrowRight />}</div>}
    </>)
    }
  </div>;
};

export default MainMenuItem;
