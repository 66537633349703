import StoreState from '../storeState';
import { createSelector } from 'reselect';
import { activeSceneVariantEntitySelector } from '../scene/sceneVariantsSelectors';

/*
 * Shared selectors for scene
 */
const colorAssignments = (state: StoreState) => state.glass.colorAssignments.colorAssignments;
export const activeGlassColorSelector = (state: StoreState) => state.glass.colors.activeColor;
export const activeGlassColorEntitySelector = (state: StoreState) => state.glass.colors.activeColor.entity;
export const activeGlassColorStatusSelector = (state: StoreState) => state.glass.colors.activeColor.status;
export const glassColorsSelector = (state: StoreState) => state.glass.colors.colors;
export const glassColorsStatusSelector = (state: StoreState) => state.glass.colors.status;
export const glassColorAssignmentsSelector = createSelector([activeSceneVariantEntitySelector, colorAssignments], (sceneVariant, colorAssignments) => {
  if (!sceneVariant) {
    return;
  }
  return colorAssignments.find(assignment => assignment.sceneVariantId === sceneVariant?.id);
});