import React, { useEffect } from 'react';
import StoreState from '../../../store/storeState';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { activeSceneVariantEntitySelector } from '../../../store/scene/sceneVariantsSelectors';
import activeGlassColorResolver from './activeGlassColorResolver';
import {
  activeGlassColorSelector,
  glassColorAssignmentsSelector,
  glassColorsSelector,
  glassColorsStatusSelector
} from '../../../store/glass/glassColorsSelectors';
import { setActiveGlassColorAction } from '../../../store/glass/glassColorsActions';
import { loadGLassColorAssignmentsRequestAction } from '../../../store/glass/glassColorsAssignmentsActions';
import { arraysEqual } from '../../../store/utils';
import { activeGlassAdjustmentStatusSelector } from '../../../store/glass/glassAdjustmentsSelectors';
import EntityStatus from '../../../store/entityStatus';

const glassColorUrlAliasSelector = (state: StoreState) => state.glass.colors.colorUrlAlias;

const ActiveGlassColorsProvider: React.FC = () => {
  const glassAdjustmentStatus = useSelector(activeGlassAdjustmentStatusSelector);
  const glassColors = useSelector(glassColorsSelector, arraysEqual);
  const glassColorsStatus = useSelector(glassColorsStatusSelector);
  const assignments = useSelector(glassColorAssignmentsSelector);
  const activeGlassColor = useSelector(activeGlassColorSelector, shallowEqual);
  const glassColorAlias = useSelector(glassColorUrlAliasSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (glassAdjustmentStatus === EntityStatus.LOAD_SUCCESS) {
      const newActiveGlassColor = activeGlassColorResolver(glassColorsStatus, activeGlassColor, glassColors, assignments, glassColorAlias);
      if (activeGlassColor.entity?.id !== newActiveGlassColor.entity?.id || activeGlassColor.status !== newActiveGlassColor.status) {
        dispatch(setActiveGlassColorAction(newActiveGlassColor));
      }
    }
  }, [glassColorsStatus, activeGlassColor, glassColors, assignments, glassColorAlias, glassAdjustmentStatus, dispatch]);

  return null;
};

const GlassColorsAssignmentsProvider: React.FC = () => {
  const activeSceneVariant = useSelector(activeSceneVariantEntitySelector);
  const assignments = useSelector(glassColorAssignmentsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeSceneVariant && !assignments) {
      dispatch(loadGLassColorAssignmentsRequestAction(activeSceneVariant));
    }
  }, [activeSceneVariant, assignments, dispatch]);

  return null;
};

const GlassColorProviders: React.FC = () => {
  return <>
    <GlassColorsAssignmentsProvider />
    <ActiveGlassColorsProvider />
  </>;
};

export default GlassColorProviders;
