import React from 'react';
import { useParams } from 'react-router';
import Configurer from './configurer/Configurer';
import reportingService, { ReportingPage } from '../service/reportingService';

export enum ConfigurerRouteParameters {
  Locale = 'LOCALE',
  Scene = 'SCENE',
  SceneVariant = 'SCENE_VARIANT',
  SceneTheme = 'SCENE_THEME',
  GlassAdjustment = 'GLASS_ADJUSTMENT',
  GlassType = 'GLASS_TYPE',
  GlassColor = 'GLASS_COLOR',
  PatternCategory = 'PATTERN_CATEGORY',
  Pattern = 'PATTERN'
}

export interface ConfigurerUrlParameters {
  locale?: string,
  scene?: string,
  sceneVariant?: string,
  sceneTheme?: string,
  glassAdjustment?: string,
  glassType?: string,
  glassColor?: string,
  patternCategory?: string,
  pattern?: string
}

export default () => {
  const {
    [ConfigurerRouteParameters.Locale]: locale, [ConfigurerRouteParameters.Scene]: scene, [ConfigurerRouteParameters.SceneVariant]: sceneVariant,
    [ConfigurerRouteParameters.SceneTheme]: sceneTheme, [ConfigurerRouteParameters.GlassAdjustment]: glassAdjustment,
    [ConfigurerRouteParameters.GlassType]: glassType, [ConfigurerRouteParameters.GlassColor]: glassColor,
    [ConfigurerRouteParameters.PatternCategory]: patternCategory, [ConfigurerRouteParameters.Pattern]: pattern
  } = useParams();

  const urlParameters: ConfigurerUrlParameters = {
    locale: locale,
    scene: scene,
    sceneVariant: sceneVariant,
    sceneTheme: sceneTheme,
    glassAdjustment: glassAdjustment,
    glassType: glassType,
    glassColor: glassColor,
    patternCategory: patternCategory,
    pattern: pattern
  };
  reportingService.initializeReporting();
  reportingService.reportPage(ReportingPage.HOME);
  return <Configurer urlParameters={urlParameters} />;
}