export enum GlassActionType {
  LOAD_REQUEST = 'GLASS_LOAD_REQUEST'
}

export type GlassActions = GlassLoadRequestAction;

export function loadGlassRequestAction(): GlassLoadRequestAction {
  return {
    type: GlassActionType.LOAD_REQUEST
  };
}

interface GlassLoadRequestAction {
  type: typeof GlassActionType.LOAD_REQUEST
}