import { ViewportSize } from '../../../service/dto/ViewportSize';

export default (
  canvasSize: ViewportSize,
  imageSize: ViewportSize,
  dx: number,
  dw: number,
  dy: number,
  dh: number) => {
  const imageAspectRatio = imageSize.width / imageSize.height;
  const canvasAspectRatio = canvasSize.width / canvasSize.height;

  if (imageAspectRatio <= canvasAspectRatio) {
    const widthRatio = imageSize.width / canvasSize.width;
    dx = dx / widthRatio;
    dw = dw / widthRatio;
    dy = ((canvasSize.height - (imageSize.height / widthRatio)) / 2) + (dy / widthRatio);
    dh = dh / widthRatio;
  } else {
    const heightRatio = imageSize.height / canvasSize.height;
    dy = dy / heightRatio;
    dh = dh / heightRatio;
    dx = ((canvasSize.width - (imageSize.width / heightRatio)) / 2) + (dx / heightRatio);
    dw = dw / heightRatio;
  }
  return {
    dx: Math.round(dx),
    dw: Math.round(dw),
    dy: Math.round(dy),
    dh: Math.round(dh)
  };
}