export enum AdminRoutes {
  ROOT = '/admin',
  OVERVIEW = '/admin/overview',
  PATTERN = '/admin/pattern',
  PATTERN_NEW = '/admin/pattern/edit',
  PATTERN_EDIT = PATTERN_NEW,
  PATTERN_EDIT_ID = '/admin/pattern/edit/:patternId',
  PATTERN_THUMBNAIL = '/admin/pattern-thumbnail',
  PATTERN_THUMBNAIL_EDIT_ID = '/admin/pattern-thumbnail/edit/:patternThumbnailId',
  PATTERN_THUMBNAIL_EDIT = '/admin/pattern-thumbnail/edit',
  PATTERN_THUMBNAIL_NEW = PATTERN_THUMBNAIL_EDIT,
  PATTERN_CATEGORY = '/admin/pattern-category',
  PATTERN_CATEGORY_EDIT = '/admin/pattern-category/edit',
  PATTERN_CATEGORY_NEW = PATTERN_CATEGORY_EDIT,
  PATTERN_CATEGORY_EDIT_ID = '/admin/pattern-category/edit/:categoryId',
  SCENE = '/admin/scene',
  SCENE_EDIT = '/admin/scene/edit',
  SCENE_EDIT_ID = '/admin/scene/edit/:sceneId',
  SCENE_VARIANT = '/admin/scene-variant',
  SCENE_VARIANT_EDIT = '/admin/scene-variant/edit',
  SCENE_VARIANT_EDIT_ID = '/admin/scene-variant/edit/:variantId',
  SCENE_THEME = '/admin/scene-theme',
  SCENE_THEME_EDIT = '/admin/scene-theme/edit',
  SCENE_THEME_EDIT_ID = '/admin/scene-theme/edit/:themeId',
  SCENE_THEME_GROUP = '/admin/scene-theme-group',
  SCENE_THEME_GROUP_EDIT = '/admin/scene-theme-group/edit',
  SCENE_THEME_GROUP_EDIT_ID = '/admin/scene-theme-group/edit/:themeGroupId',
  GLASS_ADJUSTMENT = '/admin/glass-adjustment',
  GLASS_ADJUSTMENT_EDIT = '/admin/glass-adjustment/edit',
  GLASS_ADJUSTMENT_EDIT_ID = '/admin/glass-adjustment/edit/:adjustmentId',
  GLASS_ADJUSTMENT_ASSIGNMENT = '/admin/scene-variant/:sceneVariantId/glass-adjustment',
  GLASS_TYPE = '/admin/glass-type',
  GLASS_TYPE_EDIT = '/admin/glass-type/edit',
  GLASS_TYPE_EDIT_ID = '/admin/glass-type/edit/:typeId',
  GLASS_TYPE_ASSIGNMENT = '/admin/scene-variant/:sceneVariantId/glass-type',
  GLASS_COLOR = '/admin/glass-color',
  GLASS_COLOR_EDIT = '/admin/glass-color/edit',
  GLASS_COLOR_EDIT_ID = '/admin/glass-color/edit/:colorId',
  GLASS_COLOR_ASSIGNMENT = '/admin/scene-variant/:sceneVariantId/glass-color',
  LOGIN = '/admin/login',
  MANUAL = '/admin/manual',
  ABOUT = '/admin/about',
}