import StoreState from '../storeState';
import { PatternsPagingState, RelatedPatternsState } from './patternState';
import { arraysEqual } from '../utils';

/*
 * Shared selectors for scene
 */
export const activePatternSelector = (state: StoreState) => state.pattern.patterns.activePattern;
export const patternsPagingSelector = (state: StoreState) => state.pattern.patterns.paging;
export const patternsSelector = (state: StoreState): RelatedPatternsState => state.pattern.patterns.relatedPatterns;
export const patternsStatusSelector = (state: StoreState) => state.pattern.patterns.relatedPatterns.status;
export const loadedPatternsSelector = (state: StoreState) => state.pattern.patterns.relatedPatterns.patterns;
export const loadedPatternsFragmentStatusSelector = (state: StoreState) => state.pattern.patterns.relatedPatterns.fragmentStatus;
export const loadedPatternsHasMoreSelector = (state: StoreState) => state.pattern.patterns.relatedPatterns.hasMore;
export const patternUrlAliasSelector = (state: StoreState) => state.pattern.patterns.patternUrlAlias;

export const patternsPagingStateEquals = (a: PatternsPagingState, b: PatternsPagingState) =>
  arraysEqual(a.nextPatterns, b.nextPatterns) && arraysEqual(a.previousPatterns, b.previousPatterns);
