import React from 'react';
import EntityStatus from '../../store/entityStatus';
import { useDispatch, useSelector } from 'react-redux';
import SubmenuItems, { ItemType, SidebarItemsMenuSize } from '../../component/configurer/sidebar/SubmenuItems';
import { StateEntity } from '../../store/StateEntity';
import Scene from '../../service/domain/Scene';
import { setActiveSceneThemeAction } from '../../store/scene/sceneThemesActions';
import { allSceneThemesSelector, sceneThemeGroupsSelector } from '../../store/scene/sceneThemesSelectors';
import SceneThemeGroup from '../../service/domain/SceneThemeGroup';

interface Props {
  activeScene: StateEntity<Scene>,
  activeThemeGroup: StateEntity<SceneThemeGroup>,
  sceneStatus: EntityStatus,
  onClick: () => void,
  visible: boolean
}

const SceneColorsSubMenu: React.FC<Props> = (props) => {
  const allThemes = useSelector(allSceneThemesSelector);
  const themeGroups = useSelector(sceneThemeGroupsSelector);
  const dispatch = useDispatch();
  const groupIds: number[] = [];
  allThemes.forEach(theme => {
    if (!groupIds.includes(theme.sceneThemeGroupId)) {
      groupIds.push(theme.sceneThemeGroupId);
    }
  });
  const result = groupIds
    .map(id => themeGroups.find(group => group.id === id))
    .filter(group => !!group) as SceneThemeGroup[];
  const onGroupItemClick = (item: ItemType) => () => {
    const sceneThemeGroup = item as SceneThemeGroup;
    const groupThemes = allThemes.filter(theme => theme.sceneThemeGroupId === sceneThemeGroup.id);
    const defaultTheme = groupThemes.find(theme => theme.default)
      ?? groupThemes.sort((t1, t2) => t1.order - t2.order)[0];
    dispatch(setActiveSceneThemeAction({
      entity: defaultTheme,
      status: EntityStatus.LOAD_SUCCESS
    }));
    props.onClick();
  };
  return <SubmenuItems items={result}
    activeItem={props.activeThemeGroup.entity}
    showItemTitle={true}
    onItemClick={onGroupItemClick}
    visible={props.visible}
    onClick={props.onClick}
    itemsStatus={props.activeScene.status}
    itemsSize={SidebarItemsMenuSize.LARGE}
    level={1} />;
};

export default SceneColorsSubMenu;
