import React, { useState } from 'react';
import style from './Header.module.scss';
import Logo from './Logo';
import useLocalizeMessage from '../../../localization/localizeMessage';
import ShareLink from '../sharelink/ShareLink';
import { useDispatch } from 'react-redux';
import { setOpenedMenuItem } from '../../../store/layout/layoutActions';
import IconShare from '../icons/IconShare';
import clsx from 'clsx';
import IconInfo from '../icons/IconInfo';
import reportingService, { ReportingPage } from '../../../service/reportingService';
import IconArrowRight from '../icons/IconArrowRight';
import IconFrame from '../icons/IconFrame';

const Header: React.FC = () => {
  const [showShareDialog, setShowShareDialog] = useState<boolean>(false);
  const dispatch = useDispatch();
  const localizedMessage = useLocalizeMessage();
  const onShareLinkButtonClick = () => {
    if (showShareDialog) {
      reportingService.reportPage(ReportingPage.SHARE);
    }
    setShowShareDialog(!showShareDialog);
  };
  const closeMenuIfOpened = () => {
    dispatch(setOpenedMenuItem(undefined));
  };

  return <header className={style.header} onClick={closeMenuIfOpened}>
    <div className={style.logoWrapper}>
      <Logo />
    </div>
    <div className={style.logoSeparator} />
    <div className={style.appNameWrapper}>
      <span className={style.appNameLine}>Konfigurátor Grafosklo</span>
    </div>
    <div className={style.linksWrapper}>
      <ShareLink visible={showShareDialog} onClose={onShareLinkButtonClick} />
      <a href={localizedMessage('header.link.moreAbout.link')}
        target="_blank"
        rel="noopener noreferrer"
        title={localizedMessage('header.link.moreAbout.title')}
        className={clsx(style.aboutLink, style.aboutLinkNoRightPad)}>
        <span className={style.aboutTextPersistent}>{localizedMessage('header.link.moreAbout.title')}</span>
        <div className={style.aboutIcon}><IconInfo /></div>
      </a>
      <a href={localizedMessage('header.link.oldVersion.link')}
        target="_blank"
        rel="noopener noreferrer"
        title={localizedMessage('header.link.oldVersion.title')}
        className={clsx(style.aboutLink, style.aboutLinkNoRightPad)}>
        <span className={style.aboutTextPersistent}>{localizedMessage('header.link.oldVersion.title')}</span>
        <div className={style.aboutIcon}><IconArrowRight /></div>
      </a>
      <a href={localizedMessage('header.link.shutterstock.link')}
        target="_blank"
        rel="noopener noreferrer"
        title={localizedMessage('header.link.shutterstock.title')}
        className={clsx(style.aboutLink, style.aboutLinkMiddle)}>
        <span className={style.aboutTextPersistent}>{localizedMessage('header.link.shutterstock.title')}</span>
        <div className={style.aboutIcon}><IconFrame /></div>
      </a>
      <button
        onClick={onShareLinkButtonClick}
        title={localizedMessage('header.link.share.title')}
        className={clsx(style.aboutLink, style.aboutLinkBorder)}>
        <span className={style.aboutTextBold}>{localizedMessage('header.link.share.title')}</span>
        <div className={clsx(style.aboutIcon, style.aboutIconPersistent)}><IconShare /></div>
      </button>
    </div>
  </header>;
};

export default Header;
