import React from 'react';
import styles from './Logo.module.scss';

interface Props {
}

const Logo: React.FC<Props> = () => {
  return (<div className={styles.logo} />);
};

export default Logo;
