import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  loadScenesFailedAction,
  loadScenesProgressAction,
  loadScenesRequestAction,
  loadScenesSuccessAction,
  ScenesActionType,
  ScenesSetActiveAction,
  setActiveSceneAction
} from '../store/scene/scenesActions';
import sceneService, { ScenesWithThemeGroups } from '../service/sceneService';
import { SceneVariantsActionType, SetActiveSceneVariantAction, setActiveSceneVariantAction } from '../store/scene/sceneVariantsActions';
import activeSceneResolver from '../container/configurer/provider/activeSceneResolver';
import EntityStatus from '../store/entityStatus';
import { SceneThemesActionType, SetActiveSceneThemeAction, setActiveSceneThemeAction } from '../store/scene/sceneThemesActions';
import activeSceneVariantResolver from '../container/configurer/provider/activeSceneVariantResolver';
import activeSceneThemeResolver from '../container/configurer/provider/activeSceneThemeResolver';
import StoreState from '../store/storeState';
import { activeSceneThemeGroupSelector, activeSceneThemeSelector, sceneThemeGroupsSelector } from '../store/scene/sceneThemesSelectors';
import { activeSceneVariantSelector, activeSceneVariantsSelector } from '../store/scene/sceneVariantsSelectors';
import SceneVariant from '../service/domain/SceneVariant';
import SceneThemeGroup from '../service/domain/SceneThemeGroup';
import { setActiveSceneThemeGroupAction } from '../store/scene/sceneThemeGroupsActions';
import { StateEntity } from '../store/StateEntity';
import { GlassAdjustmentsActionType, SetActiveAdjustmentAction } from '../store/glass/glassAdjustmentsActions';
import { GlassAdjustmentAssignmentsItemState } from '../store/glass/glassState';
import Scene from '../service/domain/Scene';
import activeEntityResolver from '../container/configurer/provider/activeEntityResolver';
import GlassAdjustment from '../service/domain/GlassAdjustment';

/**
 * Finds all parallel Sagas connected with scene
 * @return all found sagas
 */
export function * scenesSagas() {
  yield all([takeLoadScenes(), takeSetActiveScene(), takeSetActiveSceneVariant(), takeSetActiveSceneTheme(), takeSetActiveGlassAdjustment()]);
}

function * takeLoadScenes() {
  yield takeEvery(loadScenesRequestAction().type, loadScenes);
}

function * takeSetActiveScene() {
  yield takeEvery(ScenesActionType.SET_ACTIVE, resetSceneVariant);
}

function * takeSetActiveSceneVariant() {
  yield takeEvery(SceneVariantsActionType.SET_ACTIVE, resetTheme);
}

function * takeSetActiveSceneTheme() {
  yield takeEvery(SceneThemesActionType.SET_ACTIVE, onSetActiveSceneTheme);
}

function * takeSetActiveGlassAdjustment() {
  yield takeEvery(GlassAdjustmentsActionType.SET_ACTIVE, onSetActiveGlassAdjustment);
}

function * resetTheme(action: SetActiveSceneVariantAction) {
  const sceneThemeUrl = yield select((state: StoreState) => state.scene.sceneThemes.sceneThemeUrlAlias);
  const sceneTheme = yield select(activeSceneThemeSelector);
  const newSceneTheme = activeSceneThemeResolver(action.sceneVariant, sceneTheme, sceneThemeUrl);
  yield put(setActiveSceneThemeAction(newSceneTheme));
}

function * onSetActiveSceneTheme(action: SetActiveSceneThemeAction) {
  yield all([
    setSceneVariantAccordingToTheme(action),
    setSceneThemeGroup(action)
  ]);
}

function * setSceneThemeGroup(action: SetActiveSceneThemeAction) {
  const sceneThemeGroups: SceneThemeGroup[] = yield select(sceneThemeGroupsSelector);
  const activeSceneThemeGroup: StateEntity<SceneThemeGroup> = yield select(activeSceneThemeGroupSelector);
  const sceneThemeGroupId = action.sceneTheme.entity?.sceneThemeGroupId;
  if (sceneThemeGroupId) {
    if (!activeSceneThemeGroup || activeSceneThemeGroup.entity?.id !== sceneThemeGroupId || activeSceneThemeGroup.status !== EntityStatus.LOAD_SUCCESS) {
      const foundGroup = sceneThemeGroups.find(group => group.id === sceneThemeGroupId);
      yield put(setActiveSceneThemeGroupAction({
        entity: foundGroup,
        status: EntityStatus.LOAD_SUCCESS
      }));
    }
  }
}

function * setSceneVariantAccordingToTheme(action: SetActiveSceneThemeAction) {
  const variants: SceneVariant[] = yield select(activeSceneVariantsSelector);
  const activeVariant = yield select(activeSceneVariantSelector);
  if (action.sceneTheme.entity) {
    const sceneVariant = variants
      .flatMap(variant => variant.themes.map(theme => ({
        themeId: theme.id,
        variant: variant
      })))
      .find(item => item.themeId === action.sceneTheme.entity?.id)
      ?.variant;
    if (sceneVariant !== activeVariant.entity) {
      yield put(setActiveSceneVariantAction({
        entity: sceneVariant,
        status: EntityStatus.LOAD_SUCCESS
      }));
    }
  }
}

function * resetSceneVariant(action: ScenesSetActiveAction) {
  const sceneVariantsUrl = yield select((state: StoreState) => state.scene.sceneVariants.sceneVariantUrlAlias);
  const newActiveSceneVariant = activeSceneVariantResolver(action.scene, undefined, sceneVariantsUrl);
  yield put(setActiveSceneVariantAction(newActiveSceneVariant));
}

function * onSetActiveGlassAdjustment(action: SetActiveAdjustmentAction) {
  const activeAdjustment = action.adjustment;
  const activeThemeGroup: StateEntity<SceneThemeGroup> = yield select((state: StoreState) => state.scene.sceneThemes.activeThemeGroup);
  const activeScene: StateEntity<Scene> = yield select((state: StoreState) => state.scene.scenes.activeScene);
  const activeSceneVariant: StateEntity<SceneVariant> = yield select((state: StoreState) => state.scene.sceneVariants.activeVariant);
  const allAssignments: GlassAdjustmentAssignmentsItemState[] = yield select(
    (state: StoreState) => state.glass.adjustmentAssignments.adjustmentAssignments);
  const shouldResetActiveSceneVariant = activeScene.status === EntityStatus.LOAD_SUCCESS && activeScene.entity &&
    activeAdjustment.status === EntityStatus.LOAD_SUCCESS && activeAdjustment.entity &&
    activeThemeGroup.status === EntityStatus.LOAD_SUCCESS && activeThemeGroup.entity &&
    activeSceneVariant.status === EntityStatus.LOAD_SUCCESS && activeSceneVariant.entity &&
    allAssignments.length > 0;

  if (shouldResetActiveSceneVariant) {
    const availableSceneVariantsForThemeGroup = findAvailableSceneVariantsForThemeGroup(allAssignments, activeAdjustment, activeScene,
      activeThemeGroup);
    const currentActiveSceneVariantIsInAvailable = availableSceneVariantsForThemeGroup.find(variant => variant.id === activeSceneVariant.entity?.id);
    if (!currentActiveSceneVariantIsInAvailable) {
      const newActiveSceneVariant = {
        entity: activeEntityResolver(availableSceneVariantsForThemeGroup, undefined, activeSceneVariant?.entity),
        status: EntityStatus.LOAD_SUCCESS
      };
      yield put(setActiveSceneVariantAction(newActiveSceneVariant));
    }
  }
}

function * loadScenes() {
  yield all([
    yield put(loadScenesProgressAction())
  ]);
  try {
    const scenesWithThemeGroups: ScenesWithThemeGroups = yield call(sceneService.findScenesWithThemeGroups);
    const scenes = scenesWithThemeGroups.scenes;
    const themeGroups = scenesWithThemeGroups.themeGroups;
    yield put(loadScenesSuccessAction(scenes, themeGroups));

    const sceneUrl = yield select((state: StoreState) => state.scene.scenes.sceneUrlAlias);
    const newActiveScene = activeSceneResolver(EntityStatus.LOAD_SUCCESS, scenes, sceneUrl, undefined);
    yield put(setActiveSceneAction(newActiveScene));
  } catch (e) {
    console.error(e);
    yield all([
      put(loadScenesFailedAction()),
      put(setActiveSceneThemeAction({ status: EntityStatus.LOAD_FAILED }))
    ]);
  }
}

function findAvailableSceneVariantsForThemeGroup(
  allAssignments: GlassAdjustmentAssignmentsItemState[],
  activeAdjustment: StateEntity<GlassAdjustment>,
  activeScene: StateEntity<Scene>,
  activeThemeGroup: StateEntity<SceneThemeGroup>): SceneVariant[] {
  const availableSceneVariantIdsForAssignment = allAssignments
    .filter(assignment => assignment.adjustmentAssignments.find(adj => adj.adjustmentId === activeAdjustment.entity?.id))
    .filter(assignment => assignment.status === EntityStatus.LOAD_SUCCESS)
    .map(assignment => assignment.sceneVariantId);
  const availableSceneVariantsForThemeGroup = activeScene.entity?.variants
    .filter(variant => variant.themes.find(theme => theme.sceneThemeGroupId === activeThemeGroup?.entity?.id))
    .filter(variant => availableSceneVariantIdsForAssignment.includes(variant.id));
  return availableSceneVariantsForThemeGroup ?? [];
}