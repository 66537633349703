import React from 'react';
import styles from './Configurer.module.scss';
import { useSelector } from 'react-redux';
import { sidebarMinimizedSelector } from '../../store/layout/layoutSelector';
import './configurer.fonts.css';

const Configurer: React.FC = (props) => {
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);
  return (<div className={sidebarMinimized ? styles.configurerSidebarMinimized : styles.configurer}>
    {props.children}
  </div>);
};

export default Configurer;
