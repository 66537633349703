import React, { useLayoutEffect, useRef } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import styles from './MainMenuScrollableSelection.module.scss';

const thumbYProps = {
  style: {
    backgroundColor: 'rgb(150, 99, 78)',
    opacity: 0.4
  }
};

interface Props {
  title: string,
  onClick: () => void,
  onScrollDown?: () => void,
  rerenderItem: any,
  renderedItemOrder: number,
  renderedItemIsNew: boolean,
  setScrollPosition: (value: number) => void,
  scrollTopPosition: number,
  renderedItemsLength: number
}

export const ACTIVE_ITEM_CLASS = 'ACTIVE_ITEM';
export const ITEM_CONTAINER_CLASS = 'ScrollbarsCustom-Scroller';

function scrollToParent(wrapperElement: Element, client: Element) {
  const parentViewableArea = {
    height: wrapperElement.clientHeight,
    width: wrapperElement.clientWidth
  };
  const parentRect = wrapperElement.getBoundingClientRect();
  const childRect = client.getBoundingClientRect();
  const isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height - childRect.height);
  if (!isViewable) {
    let topValue = (childRect.top + wrapperElement.scrollTop) - parentRect.top;
    let correction = 0;
    if (topValue > wrapperElement.scrollTop) {
      topValue = topValue - parentRect.height + childRect.height;
    }
    wrapperElement.scrollTop = topValue + correction;
  }
  return wrapperElement.scrollTop;
}

const MainMenuScrollableSelection: React.FC<Props> = (props) => {
  const renderedItemRef = useRef<number>(props.rerenderItem.id);
  const renderedItemOrderRef = useRef<number>(props.renderedItemOrder);
  const renderedItemLengthRef = useRef<number>(props.renderedItemsLength);
  const { rerenderItem, renderedItemOrder, renderedItemsLength, scrollTopPosition, setScrollPosition, renderedItemIsNew } = props;
  useLayoutEffect(() => {
    const activeElements = document.getElementsByClassName(ACTIVE_ITEM_CLASS);
    const rootElements = document.getElementsByClassName(ITEM_CONTAINER_CLASS);
    if (activeElements?.length && rootElements?.length) {
      if (rerenderItem.id !== renderedItemRef.current
        || (renderedItemIsNew && scrollTopPosition === 0 && renderedItemOrderRef.current > 0)) {
        const scrollTopResult = scrollToParent(rootElements[0], activeElements[0]);
        renderedItemRef.current = rerenderItem.id;
        setScrollPosition(scrollTopResult);
      } else if (renderedItemOrderRef.current !== renderedItemOrder
        || renderedItemLengthRef.current !== renderedItemsLength) {
        renderedItemLengthRef.current = renderedItemsLength;
        renderedItemOrderRef.current = renderedItemOrder;
        rootElements[0].scrollTop = scrollTopPosition;
      }
    }
  }, [rerenderItem.id, renderedItemOrder, renderedItemsLength, scrollTopPosition, setScrollPosition, renderedItemIsNew]);

  return <div title={props.title}
    onClick={props.onClick}
    className={styles.scrollbarWrapper}>
    <Scrollbar disableTrackXWidthCompensation={true}
      disableTrackYWidthCompensation={true}
      noScrollX={true}
      noDefaultStyles={false}
      thumbYProps={thumbYProps}
      onScroll={(values: any) => {
        if (props.onScrollDown) {
          const { contentScrollHeight, scrollTop, clientHeight } = values;
          if (scrollTop + clientHeight > contentScrollHeight - 50) {
            props.onScrollDown();
          }
        }
      }}
      className={styles.scrollbar}>
      {props.children}
    </Scrollbar>
  </div>;
};

export default MainMenuScrollableSelection;
