import { MenuItem } from './layoutState';

export enum LayoutAction {
  TOGGLE_VISIBILITY = 'LAYOUT_TOGGLE_SIDEBAR_VISIBILITY',
  MENU_ITEM_OPENED = 'LAYOUT_MENU_ITEM_OPENED'
}

export type LayoutActions =
  ToggleSidebarVisibilityAction | SetOpenedMenuItemAction;

export function toggleSidebarVisibility(minimized: boolean): ToggleSidebarVisibilityAction {
  return {
    type: LayoutAction.TOGGLE_VISIBILITY,
    minimized
  };
}

export function setOpenedMenuItem(menuItem?: MenuItem): SetOpenedMenuItemAction {
  return {
    type: LayoutAction.MENU_ITEM_OPENED,
    menuItem
  };
}

interface ToggleSidebarVisibilityAction {
  type: LayoutAction.TOGGLE_VISIBILITY
  minimized: boolean
}

interface SetOpenedMenuItemAction {
  type: LayoutAction.MENU_ITEM_OPENED
  menuItem?: MenuItem
}