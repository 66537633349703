import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConfigurerRouteParameters } from './container/ConfigurerRoute';
import { Provider } from 'react-redux';
import store from './store/storeInitializer';
import { AdminRoutes } from './container/admin/adminRoutes';
import Progress from './component/configurer/loader/Progress';
import { findDefaultLocale } from './localization/localeResolver';

const Admin: React.FC = lazy(() => import('./container/AdminRoute'));
const Configurer: React.FC = lazy(() => import('./container/ConfigurerRoute'));

const configurerPathPattern = `/:${ConfigurerRouteParameters.Locale}?\
/:${ConfigurerRouteParameters.Scene}?/:${ConfigurerRouteParameters.SceneVariant}?/:${ConfigurerRouteParameters.SceneTheme}?\
/:${ConfigurerRouteParameters.GlassAdjustment}?/:${ConfigurerRouteParameters.GlassType}?/:${ConfigurerRouteParameters.GlassColor}?\
/:${ConfigurerRouteParameters.PatternCategory}?/:${ConfigurerRouteParameters.Pattern}?`;

const App: React.FC = () => (
  <Provider store={store()}>
    <BrowserRouter forceRefresh={false}>
      <Suspense fallback={<Progress />}>
        <Route path="/" exact={true}>
          <Redirect to={`${findDefaultLocale()}/`} />
        </Route>
        <Switch>
          <Route path={AdminRoutes.ROOT} component={Admin} />
          <Route path={configurerPathPattern} component={Configurer} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </Provider>
);

export default App;
