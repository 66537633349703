export enum MenuItem {
  SCENE,
  COLORS,
  GLASS,
  PATTERN
}

export default interface LayoutState {
  sidebarMinimized: boolean,
  menuItemOpened?: MenuItem
}