import cs from './cs.json';
import sk from './sk.json';
import { SupportedLocale } from './localeConfiguration';

/*
 * Provides object with all available message sources for localization
 */
type SupportedMessageSources = { [Key in SupportedLocale]: any };
const supportedMessageSources: SupportedMessageSources = {
  [SupportedLocale.CS]: cs,
  [SupportedLocale.SK]: sk
};

export default supportedMessageSources;
