import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loadPatternCategoriesRequestAction, setActivePatternCategoryAction } from '../../../store/pattern/patternCategoriesActions';
import { activePatternSelector } from '../../../store/pattern/patternSelectors';
import EntityStatus from '../../../store/entityStatus';
import {
  activePatternCategorySelector,
  patternCategoriesSelector,
  patternCategoriesStatusSelector
} from '../../../store/pattern/patternCategoriesSelectors';
import { arraysEqual } from '../../../store/utils';

const ActivePatternCategoryProvider: React.FC = () => {
  //const patternCategoryAliasUrlSelector = (state: StoreState) => state.pattern.patternCategories.patternCategoryUrlAlias;
  // const patternCategories = useSelector(patternCategoriesSelector, arraysEqual);
  // const patternCategoriesStatus = useSelector(patternCategoriesStatusSelector);
  // const activePatternCategoryUrl = useSelector(patternCategoryAliasUrlSelector);
  // const activePatternCategory = useSelector(activePatternCategoriesSelector, shallowEqual);
  // const patternCategoryAssignments = useSelector(patternCategoryAssignmentsSelector);
  //
  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  //   const newActivePatternCategory = activePatternCategoryResolver(patternCategoriesStatus,
  //     activePatternCategory,
  //     patternCategories,
  //     patternCategoryAssignments?.patternCategoryAssignments,
  //     activePatternCategoryUrl);
  //   if (activePatternCategory.entity?.id !== newActivePatternCategory.entity?.id || activePatternCategory.status !== newActivePatternCategory.status) {
  //     dispatch(setActivePatternCategoryAction(newActivePatternCategory));
  //   }
  // }, [patternCategoriesStatus, activePatternCategory, patternCategories, patternCategoryAssignments, activePatternCategoryUrl, dispatch]);

  return null;
};

const ActivePatternCategoryAccordingToPatternProvider: React.FC = () => {
  const patternCategories = useSelector(patternCategoriesSelector, arraysEqual);
  const patternCategoriesStatus = useSelector(patternCategoriesStatusSelector);
  const activePatternCategory = useSelector(activePatternCategorySelector, shallowEqual);
  const activePattern = useSelector(activePatternSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    const activePatternCategoryId = activePattern?.entity?.patternCategoryId;
    const activePatternLoaded = activePattern.status === EntityStatus.LOAD_SUCCESS;
    if (patternCategoriesStatus === EntityStatus.LOAD_SUCCESS && activePatternCategoryId && activePatternLoaded) {
      const newActivePatternCategory = patternCategories.find(category => category.id === activePatternCategoryId);
      if (activePatternCategory.entity?.id !== newActivePatternCategory?.id ||
        (newActivePatternCategory?.id && activePatternCategory.status !== EntityStatus.LOAD_SUCCESS)) {
        dispatch(setActivePatternCategoryAction({
          entity: newActivePatternCategory,
          status: EntityStatus.LOAD_SUCCESS
        }));
      }
    }
  }, [patternCategoriesStatus, activePatternCategory, patternCategories, activePattern, dispatch]);

  return null;
};

const PatternCategoryAssignmentsProvider: React.FC = () => {
  // const activeSceneTheme = useSelector(activeSceneThemeEntitySelector, shallowEqual);
  // const activeGlassAdjustment = useSelector(activeGlassAdjustmentEntitySelector, shallowEqual);
  // const activeGlassType = useSelector(activeGlassTypeEntitySelector, shallowEqual);
  // const activeGlassColor = useSelector(activeGlassColorEntitySelector, shallowEqual);
  // const patternCategoryAssignments = useSelector(patternCategoryAssignmentsSelector);
  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  //   if (activeGlassType &&
  //     activeGlassColor &&
  //     activeGlassAdjustment &&
  //     activeSceneTheme &&
  //     (!patternCategoryAssignments || patternCategoryAssignments.status === EntityStatus.LOAD_FAILED)) {
  //     dispatch(loadPatternCategoryAssignmentsRequestAction({
  //       glassTypeId: activeGlassType.id,
  //       glassColorId: activeGlassColor.id,
  //       glassAdjustmentId: activeGlassAdjustment.id,
  //       sceneThemeId: activeSceneTheme.id
  //     }));
  //   }
  // }, [activeGlassType, activeGlassColor, activeGlassAdjustment, activeSceneTheme, patternCategoryAssignments, dispatch]);

  return null;
};

const PatternCategoriesProvider: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPatternCategoriesRequestAction());
  }, [dispatch]);

  return null;
};

const PatternCategoryProvider: React.FC = () => {
  return <>
    <PatternCategoriesProvider />
    <PatternCategoryAssignmentsProvider />
    <ActivePatternCategoryProvider />
    <ActivePatternCategoryAccordingToPatternProvider />
  </>;
};

export default PatternCategoryProvider;