import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeSceneVariantEntitySelector } from '../../../store/scene/sceneVariantsSelectors';
import { activeSceneThemeSelector } from '../../../store/scene/sceneThemesSelectors';
import SceneTheme from '../../../service/domain/SceneTheme';
import { activePatternSelector } from '../../../store/pattern/patternSelectors';
import Canvas from '../../../component/configurer/canvas/Canvas';
import useWindowSize from './windowSizeEffect';
import publicApiImageService, { PublicApiImageService } from '../../../service/publicApiImageService';
import { StateEntity } from '../../../store/StateEntity';
import { LoadingStatus } from '../../../service/domain/admin/loadingStatus';
import renderImage, { isImageDrawable } from './imageRenderer';
import { useImageLoader } from './imageLoader';
import Pattern from '../../../service/domain/Pattern';
import configuration from '../../../service/configuration';
import { ViewportSize } from '../../../service/dto/ViewportSize';
import Swipe from './Swipe';
import { SceneProgressStatus } from './SceneProgressStatus';
import HtmlCanvas from './HtmlCanvas';
import EntityStatus from '../../../store/entityStatus';
import { setOpenedMenuItem } from '../../../store/layout/layoutActions';
import SceneThemeSwitcher from '../SceneThemeSwitcher';

const defaultImageSize: ViewportSize = configuration.defaultImageSize();

export interface SceneProgressProps {
  sceneTheme: StateEntity<SceneTheme>,
  sceneThemeImageRenderingStatus: LoadingStatus,
  sceneThemeImageLoadingStatus: LoadingStatus,
  patternImageLoadingStatus: LoadingStatus,
  patternImageRenderStatus: LoadingStatus,
  activePattern: StateEntity<Pattern>,
}

const menuHeight = 80;
const findMenuWidth = (windowSize: ViewportSize): number => {
  //see also _variables.scss
  if (windowSize.width <= 1024) {
    return 151;
  } else {
    return 296;
  }
};

const SceneCanvas: React.FC = () => {

  const originalWindowSize = useWindowSize();
  const activeSceneVariant = useSelector(activeSceneVariantEntitySelector);
  const activeSceneTheme = useSelector(activeSceneThemeSelector);
  const canvasHtmlElementRef = useRef<HTMLCanvasElement>(null);
  const activePattern = useSelector(activePatternSelector);
  const previousRenderedPattern = useRef<Pattern>();
  const dispatch = useDispatch();
  const [sceneThemeImageRenderStatus, setSceneThemeImageRenderStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [patternImageRenderStatus, setPatternImageRenderStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const sceneSizeImage = PublicApiImageService.findImageSize(originalWindowSize);
  const sceneImageLoader = useImageLoader(
    activeSceneTheme.status === EntityStatus.LOAD_SUCCESS && activeSceneTheme.entity?.imagePath
      ? publicApiImageService.findImagePath(activeSceneTheme.entity.imagePath, sceneSizeImage.sizeType)
      : undefined);
  const patternImageLoader = useImageLoader(
    activePattern.status === EntityStatus.LOAD_SUCCESS && activePattern.entity?.sceneImage
      ? publicApiImageService.findImagePath(activePattern.entity.sceneImage, sceneSizeImage.sizeType)
      : undefined);
  const windowSize = {
    width: originalWindowSize.width - findMenuWidth(originalWindowSize),
    height: originalWindowSize.height - menuHeight
  };
  const closeMenuIfOpened = () => {
    dispatch(setOpenedMenuItem(undefined));
  };
  const onThemeImageLoaded = () => setSceneThemeImageRenderStatus(LoadingStatus.SUCCESSFUL);
  const onPatternImageLoaded = () => {setPatternImageRenderStatus(LoadingStatus.SUCCESSFUL);};
  const sceneImage = sceneImageLoader();

  useEffect(() => {
    if (canvasHtmlElementRef.current) {
      const shouldNotReRender = activePattern.entity && !previousRenderedPattern.current;
      previousRenderedPattern.current = activePattern.entity;
      if (!shouldNotReRender) {
        setSceneThemeImageRenderStatus(LoadingStatus.NOT_LOADING);
        if (sceneImage.status === LoadingStatus.SUCCESSFUL &&
          sceneImage.image &&
          isImageDrawable(sceneImage.image)) {
          const imagePosition = {
            sx: 0,
            sy: 0,
            sw: sceneImage.image.width,
            sh: sceneImage.image.height,
            dx: 0,
            dy: 0,
            dw: defaultImageSize.width,
            dh: defaultImageSize.height
          };
          const tempWindowSize = {
            width: windowSize.width,
            height: windowSize.height
          };
          renderImage(canvasHtmlElementRef, tempWindowSize, defaultImageSize, imagePosition, onThemeImageLoaded, sceneImage.image, true);
        }
      }
    }
  }, [windowSize.width, windowSize.height, sceneImage.status, sceneImage.image, sceneSizeImage.width, sceneSizeImage.height, activePattern.entity]);

  const patternImage = patternImageLoader();
  useEffect(() => {
    setPatternImageRenderStatus(LoadingStatus.NOT_LOADING);
    if (activeSceneVariant &&
      sceneThemeImageRenderStatus === LoadingStatus.SUCCESSFUL &&
      patternImage.status === LoadingStatus.SUCCESSFUL &&
      patternImage.image &&
      isImageDrawable(patternImage.image)) {
      const patternFill = activeSceneVariant.filling;
      const imagePosition = {
        sx: 0,
        sy: 0,
        sw: patternImage.image.width,
        sh: patternImage.image.height,
        dx: patternFill.x,
        dy: patternFill.y,
        dw: patternFill.width,
        dh: patternFill.height
      };
      const tempWindowSize = {
        width: windowSize.width,
        height: windowSize.height
      };
      renderImage(canvasHtmlElementRef, tempWindowSize, defaultImageSize, imagePosition, onPatternImageLoaded, patternImage.image, false);
    }
  }, [sceneThemeImageRenderStatus, windowSize.width, windowSize.height, patternImage.status, patternImage.image, activeSceneVariant]);
  return <Canvas onClick={closeMenuIfOpened}>
    <SceneProgressStatus sceneTheme={activeSceneTheme} sceneThemeImageRenderingStatus={sceneThemeImageRenderStatus}
      patternImageLoadingStatus={patternImage.status} patternImageRenderStatus={patternImageRenderStatus}
      sceneThemeImageLoadingStatus={sceneImage.status} activePattern={activePattern} />
    <Swipe />
    <HtmlCanvas canvasHtmlElementRef={canvasHtmlElementRef} canvasWidth={windowSize.width} canvasHeight={windowSize.height} />
    <SceneThemeSwitcher />
  </Canvas>;
};

export default SceneCanvas;
