import React, { useEffect } from 'react';
import StoreState from '../../../store/storeState';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { activeSceneVariantEntitySelector } from '../../../store/scene/sceneVariantsSelectors';
import { activeGlassAdjustmentEntitySelector } from '../../../store/glass/glassAdjustmentsSelectors';
import { loadGlassTypeAssignmentsRequestAction } from '../../../store/glass/glassTypesAssignmentsActions';
import {
  activeGlassTypeSelector,
  glassTypeAssignmentsSelector,
  glassTypesSelector,
  glassTypesStatusSelector
} from '../../../store/glass/glassTypesSelectors';
import activeGlassTypeResolver from './activeGlassTypeResolver';
import { arraysEqual } from '../../../store/utils';
import EntityStatus from '../../../store/entityStatus';
import { setActiveGlassTypeAction } from '../../../store/glass/glassTypeActions';
import { activeGlassColorStatusSelector } from '../../../store/glass/glassColorsSelectors';

const glassTypeUrlAliasSelector = (state: StoreState) => state.glass.types.typeUrlAlias;

const ActiveGlassTypesProvider: React.FC = () => {
  const activeGlassColorStatus = useSelector(activeGlassColorStatusSelector);
  const glassTypes = useSelector(glassTypesSelector, arraysEqual);
  const glassTypesStatus = useSelector(glassTypesStatusSelector);
  const assignments = useSelector(glassTypeAssignmentsSelector);
  const activeGlassType = useSelector(activeGlassTypeSelector, shallowEqual);
  const glassTypeAlias = useSelector(glassTypeUrlAliasSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeGlassColorStatus === EntityStatus.LOAD_SUCCESS) {
      const newActiveGlassType = activeGlassTypeResolver(glassTypesStatus, activeGlassType, glassTypes, assignments, glassTypeAlias);
      if (activeGlassType.entity?.id !== newActiveGlassType.entity?.id || activeGlassType.status !== newActiveGlassType.status) {
        dispatch(setActiveGlassTypeAction(newActiveGlassType));
      }
    }
  }, [glassTypesStatus, activeGlassType, glassTypes, assignments, glassTypeAlias, activeGlassColorStatus, dispatch]);

  return null;
};

const GlassTypesAssignmentsProvider: React.FC = () => {
  const activeSceneVariant = useSelector(activeSceneVariantEntitySelector);
  const activeGlassAdjustment = useSelector(activeGlassAdjustmentEntitySelector);
  const assignments = useSelector(glassTypeAssignmentsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeSceneVariant && activeGlassAdjustment && !assignments) {
      dispatch(loadGlassTypeAssignmentsRequestAction(activeSceneVariant, activeGlassAdjustment));
    }
  }, [activeSceneVariant, activeGlassAdjustment, assignments, dispatch]);

  return null;
};

const GlassTypeProviders: React.FC = () => {
  return <>
    <GlassTypesAssignmentsProvider />
    <ActiveGlassTypesProvider />
  </>;
};

export default GlassTypeProviders;
