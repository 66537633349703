import React from 'react';
import { sidebarMinimizedSelector } from '../../../store/layout/layoutSelector';
import { useSelector } from 'react-redux';
import styles from './SidebarSubmenu.module.scss';
import IconArrowLeft from '../icons/IconArrowLeft';

export enum Width {
  WIDE = 'WIDE',
  THIN = 'THIN'
}

interface SubMenuProps {
  onClick: () => void,
  visible: boolean,
  title: string,
  width: Width
}

const SidebarSubmenu: React.FC<SubMenuProps> = (props) => {
  const sidebarMinimized = useSelector(sidebarMinimizedSelector);

  return <>
    {props.visible && <div
      className={`${sidebarMinimized ? styles.subMenuMinimized : styles.subMenu} ${styles.submenuContent} ${props.width === Width.WIDE
        ? styles.submenuContentWide : styles.submenuContent}`}
      onClick={() => {}}>
      <div>
        <div className={styles.subMenuTitle}>{props.title}
          <div className={styles.subMenuArrow} onClick={props.onClick}>
            <IconArrowLeft />
          </div>
        </div>
        <>{props.children}</>
      </div>
    </div>}
  </>;
};

export default SidebarSubmenu;
