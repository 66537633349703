import EntityStatus from './../entityStatus';
import SceneVariant from '../../service/domain/SceneVariant';
import { GlassTypeAssignmentsActionType, GlassTypesAssignmentsActions } from './glassTypesAssignmentsActions';
import { GlassTypeAssignmentsItemState, GlassTypeAssignmentsState } from './glassState';
import GlassTypeAssignment from '../../service/domain/GlassTypeAssignment';
import GlassAdjustment from '../../service/domain/GlassAdjustment';

const initialState: GlassTypeAssignmentsState = {
  typeAssignments: []
};

export default (state: GlassTypeAssignmentsState = initialState, action: GlassTypesAssignmentsActions): GlassTypeAssignmentsState => {
  switch (action.type) {
    case GlassTypeAssignmentsActionType.LOAD_SUCCESS:
      return {
        ...state,
        typeAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, action.glassAdjustment, action.glassTypeAssignments,
          EntityStatus.LOAD_SUCCESS)
      };
    case GlassTypeAssignmentsActionType.LOAD_PROGRESS:
      return {
        ...state,
        typeAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, action.glassAdjustment, [], EntityStatus.LOAD_IN_PROGRESS)
      };
    case  GlassTypeAssignmentsActionType.LOAD_FAILED  :
      return {
        ...state,
        typeAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, action.glassAdjustment, [], EntityStatus.LOAD_FAILED)
      };
    case GlassTypeAssignmentsActionType.LOAD_REQUEST   :
      return {
        ...state,
        typeAssignments: buildAdjustmentAssignmentState(state, action.sceneVariant, action.glassAdjustment, [], EntityStatus.NOT_LOADED)
      };
    default:
      return state;
  }
}

function buildAdjustmentAssignmentState(
  currentState: GlassTypeAssignmentsState,
  sceneVariant: SceneVariant,
  glassAdjustment: GlassAdjustment,
  typeAssignments: GlassTypeAssignment[],
  status: EntityStatus)
  : GlassTypeAssignmentsItemState[] {
  const result = [...currentState.typeAssignments];
  const foundIndex = result.findIndex(assignment => assignment.sceneVariantId === sceneVariant.id && assignment.glassAdjustmentId === glassAdjustment.id);
  const newAssignment = {
    sceneVariantId: sceneVariant.id,
    glassAdjustmentId: glassAdjustment.id,
    status: status,
    typeAssignments: typeAssignments
  };
  if (foundIndex > -1) {
    result[foundIndex] = newAssignment;
  } else {
    result.push(newAssignment);
  }
  return result;
}