import SceneVariant from '../../service/domain/SceneVariant';
import { StateEntity } from '../StateEntity';

export enum SceneVariantsActionType {
  SET_ACTIVE = 'SCENE_VARIANTS_SET_ACTIVE',
  SET_URL_ALIAS = 'SCENE_VARIANTS_SET_SCENE_URL_ALIAS'
}

export type SceneVariantsActions =
  SetActiveSceneVariantAction
  | SceneVariantsSetVariantUrlAliasAction;

export function setActiveSceneVariantAction(sceneVariant: StateEntity<SceneVariant>): SetActiveSceneVariantAction {
  return {
    type: SceneVariantsActionType.SET_ACTIVE,
    sceneVariant
  };
}

export function setSceneVariantsVariantUrlAction(alias?: string): SceneVariantsSetVariantUrlAliasAction {
  return {
    type: SceneVariantsActionType.SET_URL_ALIAS,
    alias
  };
}

export interface SetActiveSceneVariantAction {
  type: typeof SceneVariantsActionType.SET_ACTIVE,
  sceneVariant: StateEntity<SceneVariant>
}

interface SceneVariantsSetVariantUrlAliasAction {
  type: typeof SceneVariantsActionType.SET_URL_ALIAS,
  alias?: string
}