import { all, call, put, takeEvery } from 'redux-saga/effects';
import { loadAdjustmentsFailedAction, loadAdjustmentsProgressAction, loadAdjustmentsSuccessAction } from '../store/glass/glassAdjustmentsActions';
import { loadGlassTypesFailedAction, loadGlassTypesProgressAction, loadGlassTypesSuccessAction } from '../store/glass/glassTypeActions';
import glassService from '../service/glassService';
import Glass from '../service/domain/Glass';
import { loadGlassColorFailedAction, loadGlassColorProgressAction, loadGlassColorSuccessAction } from '../store/glass/glassColorsActions';
import { loadGlassRequestAction } from '../store/glass/glassActions';

/**
 * Finds all parallel Sagas connected with glass
 * @return all found sagas
 */
export default function * glassSagas() {
  yield all([takeLoadGlass()]);
}

function * takeLoadGlass() {
  yield takeEvery(loadGlassRequestAction().type, loadGlass);
}

function * loadGlass() {
  yield put(loadAdjustmentsProgressAction());
  yield put(loadGlassColorProgressAction());
  yield put(loadGlassTypesProgressAction());
  try {
    const glass: Glass = yield call(glassService.findAllActive);
    yield put(loadAdjustmentsSuccessAction(glass.adjustments));
    yield put(loadGlassTypesSuccessAction(glass.types));
    yield put(loadGlassColorSuccessAction(glass.colors));
  } catch (e) {
    console.error(e);
    yield put(loadAdjustmentsFailedAction());
    yield put(loadGlassColorFailedAction());
    yield put(loadGlassTypesFailedAction());
  }
}