import React from 'react';
import styles from './Canvas.module.scss';

interface Props {
  onClick: () => void
}

const Canvas: React.FC<Props> = (props) => {
  return (<div className={styles.canvas} onClick={props.onClick}>
    <div className={styles.canvasContent}>
      {props.children}
    </div>
  </div>);
};

export default Canvas;
