import React from 'react';
import Sidebar from '../../component/configurer/sidebar/Sidebar';
import MainMenu from '../../component/configurer/sidebar/MainMenu';
import MenuItemSceneColors from './MenuItemSceneColors';
import MenuItemScene from './MenuItemScene';
import MenuItemGlass from './MenuItemGlass';
import MenuItemPattern from './MenuItemPattern';
import SidebarFooter from '../../component/configurer/sidebar/SidebarFooter';
import { MenuItem } from '../../store/layout/layoutState';
import { useDispatch, useSelector } from 'react-redux';
import { menuItemOpenedSelector } from '../../store/layout/layoutSelector';
import { setOpenedMenuItem } from '../../store/layout/layoutActions';
import MenuItemPatterns from './MenuItemPatterns';

export interface MenuItemProperties {
  onClick: () => void,
  subMenuVisible: boolean
}

const SidebarMenuItems: React.FC = () => {
  const selectedMenuItem = useSelector(menuItemOpenedSelector);
  const dispatch = useDispatch();
  const switchMenuVisibility = (menuItem?: MenuItem) => {
    dispatch(setOpenedMenuItem(selectedMenuItem === menuItem ? undefined : menuItem));
  };

  return <>
    <MenuItemScene onClick={() => switchMenuVisibility(MenuItem.SCENE)} subMenuVisible={selectedMenuItem === MenuItem.SCENE} />
    <MenuItemSceneColors onClick={() => switchMenuVisibility(MenuItem.COLORS)} subMenuVisible={selectedMenuItem === MenuItem.COLORS} />
    <MenuItemGlass onClick={() => switchMenuVisibility(MenuItem.GLASS)} subMenuVisible={selectedMenuItem === MenuItem.GLASS} />
    <MenuItemPattern onClick={() => switchMenuVisibility()} subMenuVisible={false} />
    <MenuItemPatterns onClick={() => switchMenuVisibility()} />
  </>;
};

const SidebarContainer: React.FC = () => {
  return <Sidebar>
    <MainMenu>
      <SidebarMenuItems />
    </MainMenu>
    <SidebarFooter />
  </Sidebar>;
};

export default SidebarContainer;