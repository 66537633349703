import { useIntl } from 'react-intl';
import EntityWithTitle from '../service/domain/EntityWithTitle';
import { SupportedLocale } from './localeConfiguration';

/**
 * Hook to localize entity according to context language.
 * @throws error when locale are not supported by entity
 */
export default function useLocalizeEntityTitle() {
  const intl = useIntl();
  return (entity?: EntityWithTitle) => {
    if (!entity) {
      return '';
    }
    switch (intl.locale) {
      case SupportedLocale.CS:
        return entity.titleCs;
      case SupportedLocale.SK:
        return entity.titleSk;
      default:
        throw new Error(`Not supported locale ${intl.locale}`);
    }
  };
};