import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { activeSceneEntitySelector } from '../../../store/scene/sceneSelectors';
import useLocalizeEntityTitle from '../../../localization/localizeEntityTitle';
import { activeSceneVariantEntitySelector } from '../../../store/scene/sceneVariantsSelectors';
import { activeSceneThemeEntitySelector, activeSceneThemeGroupEntitySelector } from '../../../store/scene/sceneThemesSelectors';
import { activeGlassColorEntitySelector } from '../../../store/glass/glassColorsSelectors';
import { activeGlassTypeEntitySelector } from '../../../store/glass/glassTypesSelectors';
import { activeGlassAdjustmentEntitySelector } from '../../../store/glass/glassAdjustmentsSelectors';
import { activePatternSelector } from '../../../store/pattern/patternSelectors';
import style from './ShareLink.module.scss';
import detailMenuStyle from '../sidebar/DetailMenu.module.scss';
import useLocalizeMessage from '../../../localization/localizeMessage';
import IconCross from '../icons/IconCross';
import { activePatternCategorySelector } from '../../../store/pattern/patternCategoriesSelectors';
import EntityStatus from '../../../store/entityStatus';
import applicationUrlService from '../../../service/applicationUrlService';
import OutsideClickHandler from './OutsideClickHandler';

interface ShareLinkProps {
  visible: boolean,
  onClose: () => void
}

const CopyLink: React.FC<{ address: string }> = (props) => {
  const [copyButtonEnabled, setCopyButtonEnabled] = useState<boolean>(true);
  const _ = useLocalizeMessage();
  const ref = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (ref.current != null) {
      const input = ref.current;
      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand('copy');
      setCopyButtonEnabled(false);
    }
  };
  useEffect(() => {
    if (!copyButtonEnabled) {
      setTimeout(() => {setCopyButtonEnabled(true);}, 2000);
    }
  }, [copyButtonEnabled]);

  return <div className={style.shareLinkInputWrapper}>
    <input readOnly={true} className={style.shareLinkInput} value={props.address} ref={ref} title={_('shareLink.header.link')} />
    <button className={`${style.copyButton} ${copyButtonEnabled ? '' : style.copyButtonDisabled}`}
      onClick={onClick}
      title={_('shareLink.button.title')}>
      {copyButtonEnabled ? _('shareLink.button.value') : _('shareLink.button.done.value')}
    </button>
  </div>;
};

const LinkDialog: React.FC<{ onClose: () => void }> = (props) => {
  const localizeEntity = useLocalizeEntityTitle();
  const _ = useLocalizeMessage();
  const scene = useSelector(activeSceneEntitySelector);
  const sceneVariant = useSelector(activeSceneVariantEntitySelector);
  const sceneTheme = useSelector(activeSceneThemeEntitySelector);
  const sceneThemeGroup = useSelector(activeSceneThemeGroupEntitySelector);
  const glassColor = useSelector(activeGlassColorEntitySelector);
  const glassType = useSelector(activeGlassTypeEntitySelector);
  const glassAdjustment = useSelector(activeGlassAdjustmentEntitySelector);
  const patternCategoryState = useSelector(activePatternCategorySelector);
  const { entity: pattern } = useSelector(activePatternSelector);
  let patternCategory = undefined;
  if (patternCategoryState.status === EntityStatus.LOAD_SUCCESS) {
    patternCategory = patternCategoryState.entity;
  }

  const glassAdjustmentUrl = applicationUrlService.composeShareableUrl(scene?.alias, sceneVariant?.alias, sceneTheme?.alias,
    glassAdjustment?.alias, glassType?.alias, glassColor?.alias, patternCategory?.alias, pattern?.alias);
  const baseUrl = applicationUrlService.baseUrl();
  const linkValue = `${baseUrl}${glassAdjustmentUrl}`;

  return <OutsideClickHandler onClickOutside={props.onClose}>
    <div className={style.shareLinkContainer}>
      <div className={style.shareLinkContainerHeader}>
        <div className={detailMenuStyle.detailMenuHeader}>
          <h4 className={`${style.header} ${style.inlineHeader}`}>{_('shareLink.header.share')}</h4>
          <div className={detailMenuStyle.detailMenuHeaderIcon} onClick={props.onClose}>
            <div className={style.closeButton}><IconCross /></div>
          </div>
        </div>
        <div>
          <CopyLink address={linkValue} />
        </div>
      </div>
      <div className={style.shareLinkContainerInfoTable}>
        <div className={detailMenuStyle.detailMenuHeader}>
          <h4 className={`${style.header} ${style.inlineHeader}`}>{_('shareLink.header.info')}</h4>
        </div>
        <table className={style.pathList}>
          <tbody>
          <tr>
            <th>{_('sidebar.memuItem.label.SCENE')}:</th>
            <td>{localizeEntity(scene)}</td>
          </tr>
          <tr>
            <th>{_('sceneInfo.header.SCENE_VARIANT')}:</th>
            <td>{localizeEntity(sceneVariant)}</td>
          </tr>
          <tr>
            <th>{_(`sidebar.memuItem.detailmenu.label.${scene?.alias.toUpperCase()}`)}:</th>
            <td>{localizeEntity(sceneThemeGroup)}</td>
          </tr>
          <tr>
            <th>{_('sceneInfo.header.SCENE_THEME')}:</th>
            <td>{localizeEntity(sceneTheme)}</td>
          </tr>
          <tr>
            <th>{_('sceneInfo.header.GLASS_ADJUSTMENT')}:</th>
            <td>{localizeEntity(glassAdjustment)}</td>
          </tr>
          <tr>
            <th>{_('sceneInfo.header.GLASS_TYPE')}:</th>
            <td>{localizeEntity(glassType)}</td>
          </tr>
          <tr>
            <th>{_('sceneInfo.header.GLASS_COLOR')}:</th>
            <td>{localizeEntity(glassColor)}</td>
          </tr>
          <tr>
            <th>{_('sceneInfo.header.PATTERN_CATEGORY')}:</th>
            <td>{localizeEntity(patternCategory)}</td>
          </tr>
          <tr>
            <th>{_(`sidebar.memuItem.label.PATTERN.${glassAdjustment?.alias.toUpperCase()}`)}:</th>
            <td>{pattern?.title}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </OutsideClickHandler>;
};
const ShareLink: React.FC<ShareLinkProps> = (props) => props.visible ? <LinkDialog {...props} /> : null;

export default ShareLink;
