import React from 'react';
import styles from './LinearProgress.module.scss';

interface Props {
}

const LinearProgress: React.FC<Props> = () => {

  return <div className={styles.progress}>
    <div className={styles.progressStatus} />
    <div className={styles.progressIntermediate} />
  </div>;
};

export default LinearProgress;
