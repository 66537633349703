import { StateEntity } from '../StateEntity';
import SceneThemeGroup from '../../service/domain/SceneThemeGroup';

export enum SceneThemeGroupsActionType {
  SET_ACTIVE = 'SCENE_THEME_GROUPS_SET_ACTIVE'
}

export type SceneThemeGroupsActions =
  SetActiveSceneThemeGroupsAction;

export function setActiveSceneThemeGroupAction(sceneThemeGroup: StateEntity<SceneThemeGroup>): SetActiveSceneThemeGroupsAction {
  return {
    type: SceneThemeGroupsActionType.SET_ACTIVE,
    sceneThemeGroup
  };
}

export interface SetActiveSceneThemeGroupsAction {
  type: typeof SceneThemeGroupsActionType.SET_ACTIVE,
  sceneThemeGroup: StateEntity<SceneThemeGroup>
}