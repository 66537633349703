import React from 'react';
import useLocalizeMessage from '../../../localization/localizeMessage';
import { useSelector } from 'react-redux';
import { activeGlassAdjustmentSelector } from '../../../store/glass/glassAdjustmentsSelectors';
import { activeGlassTypeSelector } from '../../../store/glass/glassTypesSelectors';
import { activeGlassColorSelector } from '../../../store/glass/glassColorsSelectors';
import { LoadingStatus } from '../../../service/domain/admin/loadingStatus';
import EntityStatus, { toLoadingStatus } from '../../../store/entityStatus';
import { StateEntity } from '../../../store/StateEntity';
import styles from '../../../component/configurer/canvas/Canvas.module.scss';
import { SceneProgressProps } from './Canvas';
import LinearProgress from '../../../component/configurer/loader/LinearProgress';

const Loader: React.FC<{ className?: string, text?: string }> = (props) => {
  return <div className={`${styles.statusOverflow} ${props.className ?? ''}`}>
    <div className={styles.statusProgressWrapper}>
      <LinearProgress />
    </div>
    <div className={styles.statusText}>{props.text ?? ''}</div>
  </div>;
};

export const SceneProgressStatus: React.FC<SceneProgressProps> = React.memo((props) => {
  const _ = useLocalizeMessage();
  const activeGlassAdjustment = useSelector(activeGlassAdjustmentSelector);
  const activeGlassType = useSelector(activeGlassTypeSelector);
  const activeGlassColor = useSelector(activeGlassColorSelector);

  const allStatuses: LoadingStatus[] = [
    toLoadingStatus(props.sceneTheme.status),
    props.sceneThemeImageRenderingStatus,
    props.sceneThemeImageLoadingStatus,
    props.patternImageLoadingStatus,
    props.patternImageRenderStatus,
    toLoadingStatus(props.activePattern.status),
    toLoadingStatus(activeGlassAdjustment.status),
    toLoadingStatus(activeGlassColor.status),
    toLoadingStatus(activeGlassType.status)
  ];
  const allStatusEntities: StateEntity<any>[] = [
    props.sceneTheme,
    props.activePattern,
    activeGlassAdjustment,
    activeGlassColor,
    activeGlassType
  ];
  if (allStatuses.find(item => item === LoadingStatus.ERROR)) {
    return <Loader className={styles.statusOverflowMax} text={_('app.entity.status.LOAD_FAILED')} />;
  } else if (allStatuses.find(item => item === LoadingStatus.IN_PROGRESS)) {
    return <Loader className={styles.statusOverflowShown} />;
  } else if (allStatusEntities.find(item => item.status === EntityStatus.LOAD_SUCCESS && !item.entity)) {
    return <Loader className={styles.statusOverflowMax} text={_('canvas.loading.error.missingPattern')} />;
  } else if (allStatuses.every(item => item === LoadingStatus.SUCCESSFUL)) {
    return <Loader className={styles.statusOverflowHidden} />;
  } else {
    return <Loader className={styles.statusOverflowShown} />;
  }
});