import StoreState from '../storeState';
import { createSelector } from 'reselect';
import { activeSceneVariantEntitySelector } from '../scene/sceneVariantsSelectors';
import { activeGlassAdjustmentEntitySelector } from './glassAdjustmentsSelectors';

/*
 * Shared selectors for scene
 */
const glassTypeAssignments = (state: StoreState) => state.glass.typeAssignments.typeAssignments;
export const activeGlassTypeSelector = (state: StoreState) => state.glass.types.activeType;
export const activeGlassTypeEntitySelector = (state: StoreState) => state.glass.types.activeType.entity;
export const glassTypesSelector = (state: StoreState) => state.glass.types.types;
export const glassTypesStatusSelector = (state: StoreState) => state.glass.types.status;
export const glassTypeAssignmentsSelector = createSelector(
  [activeSceneVariantEntitySelector, activeGlassAdjustmentEntitySelector, glassTypeAssignments],
  (sceneVariant, glassAdjustment, glassTypeAssignments) => {
    if (!sceneVariant || !glassAdjustment) {
      return;
    }
    return glassTypeAssignments.find(
      assignment => assignment.glassAdjustmentId === glassAdjustment?.id && assignment.sceneVariantId === sceneVariant?.id);
  });