import SceneVariant from '../../../service/domain/SceneVariant';
import activeEntityResolver from './activeEntityResolver';
import { StateEntity } from '../../../store/StateEntity';
import EntityStatus from '../../../store/entityStatus';
import Scene from '../../../service/domain/Scene';

/**
 * Resolves active scene according to given parameters
 * @param activeScene vcurrent scene with variants, can be undefined
 * @param activeSceneAlias active scene alias, can be undefined
 * @param activeSceneVariant current active scene variant
 * @return active scene variant or undefined if is not available
 */
export default (
  activeScene: StateEntity<Scene>, activeSceneVariant?: StateEntity<SceneVariant>, activeSceneAlias?: string): StateEntity<SceneVariant> => {
  if (activeScene.status !== EntityStatus.LOAD_SUCCESS) {
    return {
      entity: activeSceneVariant?.entity,
      status: activeScene.status
    };
  }
  if (!activeScene.entity?.variants) {
    return {
      entity: undefined,
      status: activeScene.status
    };
  }
  return {
    entity: activeEntityResolver(activeScene.entity.variants, activeSceneAlias, activeSceneVariant?.entity),
    status: EntityStatus.LOAD_SUCCESS
  };
}