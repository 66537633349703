import ReactGA from 'react-ga';
import applicationUrlService from './applicationUrlService';
import environmentService from './environmentService';
import configuration from './configuration';

export enum ReportingPage {
  HOME = '',
  SHARE = 'share'
}

class ReportingService {

  initializeReporting() {
    ReactGA.initialize(configuration.productionReportingTrackerId, {
      debug: false
    });
  }

  reportPage(page: ReportingPage) {
    if (environmentService.isProduction()) {
      ReactGA.pageview(applicationUrlService.baseUrl() + page);
    }
  }

  reportCustomPage(page: string) {
    if (environmentService.isProduction()) {
      ReactGA.pageview(applicationUrlService.baseUrl() + page);
    }
  }
}

export default new ReportingService();