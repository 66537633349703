import GlassAdjustmentAssignment from '../../service/domain/GlassAdjustmentAssignment';
import SceneThemeGroup from '../../service/domain/SceneThemeGroup';

export enum AdjustmentAssignmentsActionType {
  LOAD_SUCCESS = 'ADJUSTMENTS_ASSIGNMENTS_LOAD_SUCCESS',
  LOAD_FAILED = 'ADJUSTMENTS_ASSIGNMENTS_LOAD_FAILED',
  LOAD_REQUEST = 'ADJUSTMENTS_ASSIGNMENTS_LOAD_REQUEST',
  LOAD_PROGRESS = 'ADJUSTMENTS_ASSIGNMENTS_LOAD_PROGRESS'
}

export type GlassAdjustmentsAssignmentsActions =
  AdjustmentAssignmentsLoadRequestAction
  | AdjustmentAssignmentsLoadFailedAction
  | AdjustmentAssignmentsLoadSuccessAction
  | AdjustmentAssignmentsLoadProgressAction;

export function loadAdjustmentAssignmentsRequestAction(sceneThemeGroup: SceneThemeGroup): AdjustmentAssignmentsLoadRequestAction {
  return {
    type: AdjustmentAssignmentsActionType.LOAD_REQUEST,
    sceneThemeGroup
  };
}

export function loadAdjustmentAssignmentsFailedAction(sceneThemeGroup: SceneThemeGroup): AdjustmentAssignmentsLoadFailedAction {
  return {
    type: AdjustmentAssignmentsActionType.LOAD_FAILED,
    sceneThemeGroup
  };
}

export function loadAdjustmentAssignmentsSuccessAction(
  adjustmentAssignments: GlassAdjustmentAssignment[],
  sceneVariantId: number): AdjustmentAssignmentsLoadSuccessAction {
  return {
    type: AdjustmentAssignmentsActionType.LOAD_SUCCESS,
    sceneVariantId,
    adjustmentAssignments
  };
}

export function loadAdjustmentAssignmentsProgressAction(sceneThemeGroup: SceneThemeGroup): AdjustmentAssignmentsLoadProgressAction {
  return {
    type: AdjustmentAssignmentsActionType.LOAD_PROGRESS,
    sceneThemeGroup
  };
}

interface AdjustmentAssignmentsAction {
  sceneVariantId: number
}

export interface AdjustmentAssignmentsLoadRequestAction {
  type: typeof AdjustmentAssignmentsActionType.LOAD_REQUEST,
  sceneThemeGroup: SceneThemeGroup
}

interface AdjustmentAssignmentsLoadProgressAction {
  type: typeof AdjustmentAssignmentsActionType.LOAD_PROGRESS,
  sceneThemeGroup: SceneThemeGroup
}

interface AdjustmentAssignmentsLoadFailedAction {
  type: typeof AdjustmentAssignmentsActionType.LOAD_FAILED,
  sceneThemeGroup: SceneThemeGroup
}

export interface AdjustmentAssignmentsLoadSuccessAction extends AdjustmentAssignmentsAction {
  type: typeof AdjustmentAssignmentsActionType.LOAD_SUCCESS,
  adjustmentAssignments: GlassAdjustmentAssignment[]
}