import SceneVariant from '../../../service/domain/SceneVariant';
import activeEntityResolver from './activeEntityResolver';
import { StateEntity } from '../../../store/StateEntity';
import EntityStatus from '../../../store/entityStatus';
import SceneTheme from '../../../service/domain/SceneTheme';

export default (
  activeSceneVariant: StateEntity<SceneVariant>, activeTheme?: StateEntity<SceneTheme>, activeSceneThemeAlias?: string): StateEntity<SceneTheme> => {
  if (activeSceneVariant.status !== EntityStatus.LOAD_SUCCESS) {
    return {
      entity: activeTheme?.entity,
      status: activeSceneVariant.status
    };
  }
  if (!activeSceneVariant.entity?.themes) {
    return {
      entity: undefined,
      status: EntityStatus.LOAD_SUCCESS
    };
  }
  return {
    entity: activeEntityResolver(activeSceneVariant.entity.themes, activeSceneThemeAlias, activeTheme?.entity),
    status: EntityStatus.LOAD_SUCCESS
  };
}