import Pattern from '../../service/domain/Pattern';
import PatternSearchParameters from '../../service/dto/PatternSearchParameters';
import { StateEntity } from '../StateEntity';

export enum PatternsActionType {
  LOAD_FAILED = 'PATTERNS_LOAD_FAILED',
  LOAD_REQUEST = 'PATTERNS_LOAD_REQUEST',
  LOAD_PROGRESS = 'PATTERNS_LOAD_PROGRESS',
  LOAD_FRAGMENT_SUCCESS = 'LOAD_FRAGMENT_SUCCESS',
  LOAD_FRAGMENT_REQUEST = 'LOAD_FRAGMENT_REQUEST',
  LOAD_FRAGMENT_FAILED = 'LOAD_FRAGMENT_FAILED',
  LOAD_FRAGMENT_PROGRESS = 'LOAD_FRAGMENT_PROGRESS',
  SET_ACTIVE = 'PATTERNS_SET_ACTIVE',
  INVALIDATE_ACTIVE = 'PATTERNS_INVALIDATE_ACTIVE',
  SET_PREVNEXT = 'PATTERNS_SET_PREVNEXT',
  SET_ACTIVE_URL = 'PATTERNS_SET_ACTIVE_URL',
}

export type PatternsActions =
  PatternsLoadRequestAction
  | PatternsLoadFailedAction
  | PatternsLoadProgressAction
  | PatternsSetActiveAction
  | PatternsSetPrevNextAction
  | PatternsInvalidateActiveAction
  | PatternsSetActiveUrlAction
  | PatternsLoadFragmentSuccessAction
  | PatternsLoadFragmentRequestAction
  | PatternsLoadFragmentProgressAction
  | PatternsLoadFragmentFailedAction;

export function setActivePatternAction(pattern: StateEntity<Pattern>): PatternsSetActiveAction {
  return {
    type: PatternsActionType.SET_ACTIVE,
    pattern
  };
}

export function invalidateActivePatternAction(): PatternsInvalidateActiveAction {
  return {
    type: PatternsActionType.INVALIDATE_ACTIVE
  };
}

export function setPrevNextPatternAction(previous: Pattern[], next: Pattern[]): PatternsSetPrevNextAction {
  return {
    type: PatternsActionType.SET_PREVNEXT,
    previous,
    next
  };
}

export function setPatternUrlAliasAction(activePatternUrl?: string): PatternsSetActiveUrlAction {
  return {
    type: PatternsActionType.SET_ACTIVE_URL,
    activePatternUrl
  };
}

export function loadPatternsRequestAction(patternSearchParameters: PatternSearchParameters): PatternsLoadRequestAction {
  return {
    type: PatternsActionType.LOAD_REQUEST,
    patternSearchParameters
  };
}

export function loadPatternsFailedAction(patternSearchParameters: PatternSearchParameters): PatternsLoadFailedAction {
  return {
    type: PatternsActionType.LOAD_FAILED,
    patternSearchParameters
  };
}

export function loadPatternsProgressAction(patternSearchParameters: PatternSearchParameters): PatternsLoadProgressAction {
  return {
    type: PatternsActionType.LOAD_PROGRESS,
    patternSearchParameters
  };
}

export function loadFragmentProgressAction(patternSearchParameters: PatternSearchParameters): PatternsLoadFragmentProgressAction {
  return {
    type: PatternsActionType.LOAD_FRAGMENT_PROGRESS,
    patternSearchParameters
  };
}

export function loadFragmentRequestAction(): PatternsLoadFragmentRequestAction {
  return {
    type: PatternsActionType.LOAD_FRAGMENT_REQUEST
  };
}

export function loadFragmentFailedAction(patternSearchParameters: PatternSearchParameters): PatternsLoadFragmentFailedAction {
  return {
    type: PatternsActionType.LOAD_FRAGMENT_FAILED,
    patternSearchParameters
  };
}

export function loadFragmentSuccessAction(
  patterns: Pattern[], hasMore: boolean, patternSearchParameters: PatternSearchParameters, loadedOffset?: number): PatternsLoadFragmentSuccessAction {
  return {
    type: PatternsActionType.LOAD_FRAGMENT_SUCCESS,
    patterns,
    hasMore,
    loadedOffset,
    patternSearchParameters
  };
}

interface PatternActionAttributes {
  patternSearchParameters: PatternSearchParameters
}

export interface PatternsLoadRequestAction extends PatternActionAttributes {
  type: typeof PatternsActionType.LOAD_REQUEST
}

interface PatternsLoadProgressAction extends PatternActionAttributes {
  type: typeof PatternsActionType.LOAD_PROGRESS
}

interface PatternsLoadFailedAction extends PatternActionAttributes {
  type: typeof PatternsActionType.LOAD_FAILED
}

interface PatternsLoadFragmentSuccessAction extends PatternActionAttributes {
  type: typeof PatternsActionType.LOAD_FRAGMENT_SUCCESS
  hasMore: boolean,
  loadedOffset?: number,
  patterns: Pattern[]
}

interface PatternsLoadFragmentProgressAction extends PatternActionAttributes {
  type: typeof PatternsActionType.LOAD_FRAGMENT_PROGRESS
}

interface PatternsLoadFragmentFailedAction extends PatternActionAttributes {
  type: typeof PatternsActionType.LOAD_FRAGMENT_FAILED
}

export interface PatternsLoadFragmentRequestAction {
  type: typeof PatternsActionType.LOAD_FRAGMENT_REQUEST
}

export interface PatternsSetActiveAction {
  type: typeof PatternsActionType.SET_ACTIVE
  pattern: StateEntity<Pattern>
}

interface PatternsInvalidateActiveAction {
  type: typeof PatternsActionType.INVALIDATE_ACTIVE
}

interface PatternsSetActiveUrlAction {
  type: typeof PatternsActionType.SET_ACTIVE_URL
  activePatternUrl?: string
}

interface PatternsSetPrevNextAction {
  type: typeof PatternsActionType.SET_PREVNEXT
  previous: Pattern[],
  next: Pattern[]
}