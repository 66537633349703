import configuration from './configuration';
import Glass from './domain/Glass';

class GlassService {

  findAllActive(): Promise<Array<Glass>> {
    return fetch(`${configuration.publicApiUrl}/glass`).then(value => {
      return value.json();
    });
  }

}

export default new GlassService();