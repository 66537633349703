import React from 'react';
import { ViewportSize } from '../../../service/dto/ViewportSize';
import imageSizeComputer from './imageSizeComputer';

export interface ImagePosition {
  sx: number,
  sy: number,
  sw: number,
  sh: number
  dx: number,
  dy: number,
  dw: number,
  dh: number
}

export function getScale() {
  return window.devicePixelRatio;
}

export function isImageDrawable(image?: HTMLImageElement) {
  return image &&
    image.width > 0 &&
    image.height > 0 &&
    image.complete &&
    image.src;
}

export function computeScale(windowSize: ViewportSize): ViewportSize {
  const scale = getScale();
  return {
    width: Math.floor(windowSize.width * scale),
    height: Math.floor(windowSize.height * scale)
  };
}

export default function renderImage(
  canvasHtmlElementRef: React.RefObject<HTMLCanvasElement>,
  windowSize: ViewportSize,
  imageSize: ViewportSize,
  imagePosition: ImagePosition,
  onImageLoaded: (context: CanvasRenderingContext2D) => void,
  image: HTMLImageElement,
  clearViewport: boolean) {
  const canvas = canvasHtmlElementRef.current as HTMLCanvasElement;
  const scaled = computeScale({
    width: windowSize.width,
    height: windowSize.height
  });
  const context = canvas.getContext('2d');
  if (canvas.width !== scaled.width || canvas.height !== scaled.height) {
    canvas.width = scaled.width;
    canvas.height = scaled.height;
    const scale = getScale();
    if (context) {
      console.debug('resetting scale', canvas.width, canvas.height, scaled.width, scaled.height, scale);
      context.scale(scale, scale);
    }
  }
  if (context) {
    const { dx, dy, dw, dh, sx, sy, sw, sh } = imagePosition;
    const imageViewport = imageSizeComputer(windowSize, imageSize, dx, dw, dy, dh);
    if (clearViewport) {
      context.clearRect(imageViewport.dx, imageViewport.dy, imageViewport.dw, imageViewport.dh);
    }
    console.debug('imageRenderer: drawing image to canvas', image, dx, dy, dw, dh, sx, sy, sw, sh);
    context.drawImage(image, sx, sy, sw, sh, imageViewport.dx, imageViewport.dy, imageViewport.dw, imageViewport.dh);
    onImageLoaded(context);
  }
}