import React from 'react';

const IconArrowLeft: React.FC = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 20">
    <rect fill="currentColor" x="1.02423" y="8.88841" width="23.97577" height="1.85038" />
    <rect fill="currentColor" x="-1.37818" y="4.63586" width="13.8792" height="1.85044" transform="translate(-2.30337 5.5612) rotate(-44.99919)" />
    <rect fill="currentColor" x="4.63614" y="7.12679" width="1.85056" height="13.87908" transform="translate(-8.31763 8.05274) rotate(-45.00121)" />
  </svg>;
};

export default IconArrowLeft;
