import GlassType from '../../service/domain/GlassType';
import { StateEntity } from '../StateEntity';

export enum GlassTypesActionType {
  LOAD_SUCCESS = 'GLASS_TYPES_LOAD_SUCCESS',
  LOAD_FAILED = 'GLASS_TYPES_LOAD_FAILED',
  LOAD_REQUEST = 'GLASS_TYPES_LOAD_REQUEST',
  LOAD_PROGRESS = 'GLASS_TYPES_LOAD_PROGRESS',
  SET_ACTIVE = 'GLASS_TYPES_SET_ACTIVE',
  SET_URL_ALIAS = 'GLASS_TYPES_URL_ALIAS'
}

export type GlassTypesActions =
  GlassTypesLoadRequestAction
  | GlassTypesLoadFailedAction
  | GlassTypesLoadSuccessAction
  | GlassTypesLoadProgressAction
  | SetActiveGlassTypeAction
  | SetTypeUrlAliasAction;

export function loadGlassTypesRequestAction(): GlassTypesLoadRequestAction {
  return {
    type: GlassTypesActionType.LOAD_REQUEST
  };
}

export function loadGlassTypesFailedAction(): GlassTypesLoadFailedAction {
  return {
    type: GlassTypesActionType.LOAD_FAILED
  };
}

export function loadGlassTypesSuccessAction(glassTypes: GlassType[]): GlassTypesLoadSuccessAction {
  return {
    type: GlassTypesActionType.LOAD_SUCCESS,
    glassTypes
  };
}

export function loadGlassTypesProgressAction(): GlassTypesLoadProgressAction {
  return {
    type: GlassTypesActionType.LOAD_PROGRESS
  };
}

export function setActiveGlassTypeAction(glassType: StateEntity<GlassType>): SetActiveGlassTypeAction {
  return {
    type: GlassTypesActionType.SET_ACTIVE,
    glassType
  };
}

export function setGlassTypeUrlAliasAction(alias?: string): SetTypeUrlAliasAction {
  return {
    type: GlassTypesActionType.SET_URL_ALIAS,
    alias
  };
}

interface GlassTypesLoadRequestAction {
  type: typeof GlassTypesActionType.LOAD_REQUEST,
}

interface GlassTypesLoadProgressAction {
  type: typeof GlassTypesActionType.LOAD_PROGRESS,
}

interface GlassTypesLoadFailedAction {
  type: typeof GlassTypesActionType.LOAD_FAILED,
}

interface GlassTypesLoadSuccessAction {
  type: typeof GlassTypesActionType.LOAD_SUCCESS,
  glassTypes: GlassType[]
}

export interface SetActiveGlassTypeAction {
  type: typeof GlassTypesActionType.SET_ACTIVE,
  glassType: StateEntity<GlassType>
}

interface SetTypeUrlAliasAction {
  type: typeof GlassTypesActionType.SET_URL_ALIAS,
  alias?: string
}