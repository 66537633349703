const UNKNOWN_VALUE = '';

class ApplicationUrlService {

  composeShareableUrl(
    sceneAlias?: string,
    sceneVariantAlias?: string,
    sceneThemeAlias?: string,
    glassAdjustmentAlias?: string,
    glassTypeAlias?: string,
    glassColorAlias?: string,
    patternCategoryAlias?: string,
    patternAlias?: string) {
    return `${sceneAlias ?? UNKNOWN_VALUE}/${sceneVariantAlias ?? UNKNOWN_VALUE}/${sceneThemeAlias ?? UNKNOWN_VALUE}/${glassAdjustmentAlias ??
    UNKNOWN_VALUE}/${glassTypeAlias ?? UNKNOWN_VALUE}/${glassColorAlias ?? UNKNOWN_VALUE}/${patternCategoryAlias ?? UNKNOWN_VALUE}/${patternAlias ??
    UNKNOWN_VALUE}`;
  }

  baseUrl() {
    return window.location.href;
  }
}

export default new ApplicationUrlService();