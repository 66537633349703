import { BaseEntity } from '../../../service/domain/BaseEntity';
import EntityWithTitle from '../../../service/domain/EntityWithTitle';
import React, { ReactElement, useMemo } from 'react';
import EntityStatus from '../../../store/entityStatus';
import useLocalizeEntityTitle from '../../../localization/localizeEntityTitle';
import useLocalizeMessage from '../../../localization/localizeMessage';
import styles from './SidebarSubmenu.module.scss';
import EntityWithThumbnail from '../../../service/domain/EntityWithThumbnail';
import publicApiImageService from '../../../service/publicApiImageService';
import Progress from '../loader/Progress';
import clsx from 'clsx';

export type ItemType = BaseEntity & EntityWithTitle & ({ thumbnailImage?: string } | EntityWithThumbnail);

export enum SidebarItemsMenuSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export interface SidebarItemsMenuProps<T extends ItemType> {
  items: T[],
  itemsStatus: EntityStatus,
  activeItem?: T,
  onItemClick: (item: T) => () => void,
  onClick: () => void,
  visible: boolean,
  additionalContent?: ReactElement,
  showAdditionalContent?: (item: T) => boolean,
  showItemTitle: boolean,
  itemsSize: SidebarItemsMenuSize,
  level: number,
  activeItemClass?: string,
  standalone?: boolean
}

const isItemActive = (item: ItemType, activeItem?: ItemType) => {
  return item.id === activeItem?.id;
};

const itemsSuccessfullyLoaded = <T extends ItemType>(
  props: SidebarItemsMenuProps<T>,
  localizeMessage: (messageCode: string) => string,
  localizeEntityTitle: (entity: EntityWithTitle) => (string)) => {
  if (props.items.length === 0) {
    return <div>{localizeMessage('sidebar.items.empty')}</div>;
  } else {
    const wrapperClass = clsx(styles.submenuList,
      props.itemsSize ? styles['submenuListSize-' + props.itemsSize] : undefined,
      props.standalone ? styles.standaloneSubmenuList : undefined);

    return <ul className={wrapperClass}>
      {props.items.sort((item1, item2) => item1.order - item2.order).map(item => {
        const additionalStyle = item.thumbnailImage
          ? { backgroundImage: `url(${publicApiImageService.findThumbnailImagePath(item.thumbnailImage)})` }
          : undefined;
        const itemActive = isItemActive(item, props.activeItem);
        const itemImageCLass = clsx(itemActive ? styles.submenuItemImageActive : styles.submenuItemImage,
          styles['submenuItemImageSize-' + props.itemsSize]);
        const labelClass = clsx(itemActive ? styles.submenuItemLabelActive : styles.submenuItemLabel,
          styles['submenuItemLabelSize-' + props.itemsSize],
          props.level > 1 ? styles.submenuItemLabelSmall : undefined
        );
        const liClasses = clsx(itemActive ? styles.submenuItemActive : styles.submenuItem,
          itemActive ? props.activeItemClass : undefined,
          props.standalone ? styles.standaloneSubmenuItem : undefined);

        return <li key={item.id} className={liClasses}>
          <div
            title={localizeEntityTitle(item)}
            onClick={props.onItemClick(item)}
            className={itemImageCLass}
            style={additionalStyle}
          />
          <div
            title={localizeEntityTitle(item)}
            onClick={props.onItemClick(item)}
            className={labelClass}>
            {props.showItemTitle && localizeEntityTitle(item)}
          </div>
          {props.showAdditionalContent && props.showAdditionalContent(item) && props.additionalContent}
        </li>;
      })}
    </ul>;
  }
};

const SubmenuItems = <T extends ItemType>(props: SidebarItemsMenuProps<T>) => {
  const localizeMessage = useLocalizeMessage();
  const localizeEntityTitle = useLocalizeEntityTitle();
  const displayProperty = props.visible ? 'block' : 'none';

  const component = useMemo(() => {
    switch (props.itemsStatus) {
      case EntityStatus.LOAD_FAILED:
      case EntityStatus.LOAD_IN_PROGRESS:
        return <Progress title={localizeMessage(`app.entity.status.${props.itemsStatus}`)} />;
      case EntityStatus.NOT_LOADED:
        return <></>;
      case EntityStatus.LOAD_SUCCESS: {
        return <>{itemsSuccessfullyLoaded(props, localizeMessage, localizeEntityTitle)}</>;
      }
      default:
        throw new Error(`Unknown status '${props.itemsStatus}'`);
    }
  }, [props, localizeMessage, localizeEntityTitle]);

  return <div style={{ display: displayProperty }} onClick={props.onClick}>
    {component}
  </div>;
};

export default SubmenuItems;