import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import glassColorService from '../service/glassColorService';
import {
  GlassColorAssignmentsActionType,
  GLassColorAssignmentsLoadRequestAction,
  loadGLassColorAssignmentsFailedAction,
  loadGLassColorAssignmentsProgressAction,
  loadGLassColorAssignmentsSuccessAction
} from '../store/glass/glassColorsAssignmentsActions';
import GlassColorAssignment from '../service/domain/GlassColorAssignment';
import { SceneThemesActionType } from '../store/scene/sceneThemesActions';
import { SceneVariantsActionType } from '../store/scene/sceneVariantsActions';
import { GlassAdjustmentsActionType } from '../store/glass/glassAdjustmentsActions';
import EntityStatus from '../store/entityStatus';
import { setActiveGlassColorAction } from '../store/glass/glassColorsActions';
import { activeGlassColorSelector } from '../store/glass/glassColorsSelectors';

/**
 * Finds all parallel Sagas connected with glass types
 * @return all found sagas
 */
export default function * glassColorsSagas() {
  yield all([takeLoadGlassColorAssignments(), takeActionsForColorReset()]);
}

function * takeLoadGlassColorAssignments() {
  yield takeEvery(GlassColorAssignmentsActionType.LOAD_REQUEST, loadGlassColorAssignments);
}

function * loadGlassColorAssignments(action: GLassColorAssignmentsLoadRequestAction) {
  const sceneVariant = action.sceneVariant;

  yield put(loadGLassColorAssignmentsProgressAction(sceneVariant));
  try {
    const assignments: GlassColorAssignment[] = yield call(glassColorService.findAssignmentsForScene, sceneVariant);
    yield put(loadGLassColorAssignmentsSuccessAction(assignments, sceneVariant));
  } catch (e) {
    console.error(e);
    yield put(loadGLassColorAssignmentsFailedAction(sceneVariant));
  }
}

function * takeActionsForColorReset() {
  yield all([
    yield takeEvery(SceneThemesActionType.SET_ACTIVE, resetColor),
    yield takeEvery(SceneVariantsActionType.SET_ACTIVE, resetColor),
    yield takeEvery(GlassAdjustmentsActionType.SET_ACTIVE, resetColor)
  ]);
}

function * resetColor() {
  const activeColor = yield select(activeGlassColorSelector);
  const resultStatus = EntityStatus.NOT_LOADED;
  if (activeColor.status !== resultStatus) {
    yield put(setActiveGlassColorAction({
      status: resultStatus,
      entity: activeColor.entity
    }));
  }
}