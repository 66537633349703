import Scene from '../../service/domain/Scene';
import { StateEntity } from '../StateEntity';
import SceneThemeGroup from '../../service/domain/SceneThemeGroup';

export enum ScenesActionType {
  LOAD_SUCCESS = 'SCENES_LOAD_SUCCESS',
  LOAD_FAILED = 'SCENES_LOAD_FAILED',
  LOAD_REQUEST = 'SCENES_LOAD_REQUEST',
  LOAD_PROGRESS = 'SCENES_LOAD_PROGRESS',
  SET_ACTIVE = 'SCENES_SET_ACTIVE',
  SET_SCENE_URL_ALIAS = 'SCENES_SET_SCENE_URL_ALIAS'
}

export type ScenesActions =
  ScenesLoadRequestAction
  | ScenesLoadFailedAction
  | ScenesLoadSuccessAction
  | ScenesLoadProgressAction
  | ScenesSetActiveAction
  | ScenesSetSceneUrlAliasAction;

export function loadScenesRequestAction(): ScenesLoadRequestAction {
  return {
    type: ScenesActionType.LOAD_REQUEST
  };
}

export function loadScenesFailedAction(): ScenesLoadFailedAction {
  return {
    type: ScenesActionType.LOAD_FAILED
  };
}

export function loadScenesSuccessAction(scenes: Scene[], themeGroups: SceneThemeGroup[]): ScenesLoadSuccessAction {
  return {
    type: ScenesActionType.LOAD_SUCCESS,
    scenes,
    themeGroups
  };
}

export function loadScenesProgressAction(): ScenesLoadProgressAction {
  return {
    type: ScenesActionType.LOAD_PROGRESS
  };
}

export function setActiveSceneAction(scene: StateEntity<Scene>): ScenesSetActiveAction {
  return {
    type: ScenesActionType.SET_ACTIVE,
    scene
  };
}

export function setScenesSceneUrlAliasAction(alias?: string): ScenesSetSceneUrlAliasAction {
  return {
    type: ScenesActionType.SET_SCENE_URL_ALIAS,
    alias
  };
}

interface ScenesLoadRequestAction {
  type: typeof ScenesActionType.LOAD_REQUEST
}

interface ScenesLoadProgressAction {
  type: typeof ScenesActionType.LOAD_PROGRESS
}

interface ScenesLoadFailedAction {
  type: typeof ScenesActionType.LOAD_FAILED
}

interface ScenesLoadSuccessAction {
  type: typeof ScenesActionType.LOAD_SUCCESS,
  scenes: Scene[],
  themeGroups: SceneThemeGroup[]
}

export interface ScenesSetActiveAction {
  type: typeof ScenesActionType.SET_ACTIVE,
  scene: StateEntity<Scene>
}

export interface ScenesSetSceneUrlAliasAction {
  type: typeof ScenesActionType.SET_SCENE_URL_ALIAS,
  alias?: string
}