import EntityStatus from '../../../store/entityStatus';
import { GlassTypeAssignmentsItemState } from '../../../store/glass/glassState';
import GlassType from '../../../service/domain/GlassType';
import GlassTypeAssignment from '../../../service/domain/GlassTypeAssignment';
import { StateEntity } from '../../../store/StateEntity';

/**
 * resolves active glass type if possible
 * @param glassTypeStatus status of the types, must not be undefined
 * @param glassTypes all types, can be undefined
 * @param glassTypesAssignments, assignments of the glass type to current scene variant and glass adjustment, can be undefined
 * @param activeGlassTypeUrl alias of the glass type from url, can be undefined
 * @param activeType current active glass type
 */
export default (
  glassTypeStatus: EntityStatus,
  activeType: StateEntity<GlassType>,
  glassTypes?: GlassType[],
  glassTypesAssignments?: GlassTypeAssignmentsItemState,
  activeGlassTypeUrl?: string
): StateEntity<GlassType> => {
  if (!glassTypes || glassTypeStatus !== EntityStatus.LOAD_SUCCESS || !glassTypesAssignments || glassTypesAssignments.status
    !== EntityStatus.LOAD_SUCCESS) {
    return {
      entity: activeType.entity,
      status: glassTypesAssignments?.status ?? EntityStatus.NOT_LOADED
    };
  }
  return {
    entity: findDefaultAdjustment(glassTypes, glassTypesAssignments.typeAssignments, activeGlassTypeUrl, activeType.entity),
    status: EntityStatus.LOAD_SUCCESS
  };
}

function findDefaultAdjustment(glassTypes: GlassType[], typeAssignments: GlassTypeAssignment[], activeGlassTypeUrl?: string,
  activeType?: GlassType) {
  typeAssignments.sort((left, right) => left.order - right.order);

  const assignedTypes = typeAssignments.map(assignment => glassTypes.find(type => type.id === assignment.typeId)).filter(type => type !== undefined);

  let foundType;
  if (activeType) {
    foundType = (assignedTypes as GlassType[]).find(type => type.id === activeType.id);
  }
  if (!foundType && activeGlassTypeUrl) {
    foundType = (assignedTypes as GlassType[]).find(type => type.alias === activeGlassTypeUrl);
  }
  if (!foundType && assignedTypes.length > 0) {
    foundType = assignedTypes[0];
  }
  return foundType;
}
