import configuration from './configuration';
import Scene from './domain/Scene';
import SceneThemeGroup from './domain/SceneThemeGroup';

export interface ScenesWithThemeGroups {
  scenes: Scene[],
  themeGroups: SceneThemeGroup[]
}

class SceneService {

  findScenesWithThemeGroups(): Promise<ScenesWithThemeGroups> {
    return fetch(`${configuration.publicApiUrl}/scenes-theme-groups`).then(value => {
      return value.json();
    });
  }

  findAllActive(): Promise<Array<Scene>> {
    return fetch(`${configuration.publicApiUrl}/scenes`).then(value => {
      return value.json();
    });
  }

  findAll(): Promise<Array<Scene>> {
    return fetch(`${configuration.adminApiUrl}/scenes`).then(value => {
      return value.json();
    });
  }

  findById(id: number): Promise<Scene> {
    return fetch(`${configuration.adminApiUrl}/scenes/${id}`).then(value => {
      return value.json();
    });
  }

  save(scene: Scene): Promise<Scene> {
    return fetch(`${configuration.adminApiUrl}/scenes/save/${scene.id}`, {
      method: 'POST',
      body: JSON.stringify(scene)
    }).then(value => {
      return value.json();
    });
  };
}

export default new SceneService();