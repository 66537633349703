import EntityStatus from './../entityStatus';
import { GlassAdjustmentsActions, GlassAdjustmentsActionType } from './glassAdjustmentsActions';
import { GlassAdjustmentsState } from './glassState';

const initialState: GlassAdjustmentsState = {
  adjustments: [],
  activeAdjustment: {
    entity: undefined,
    status: EntityStatus.NOT_LOADED
  },
  adjustmentUrlAlias: undefined,
  status: EntityStatus.NOT_LOADED
};

export default (state: GlassAdjustmentsState = initialState, action: GlassAdjustmentsActions): GlassAdjustmentsState => {
  switch (action.type) {
    case GlassAdjustmentsActionType.LOAD_SUCCESS:
      return {
        ...state,
        adjustments: action.adjustments,
        status: EntityStatus.LOAD_SUCCESS
      };
    case GlassAdjustmentsActionType.LOAD_FAILED:
      return {
        ...state,
        adjustments: [],
        status: EntityStatus.LOAD_FAILED
      };
    case GlassAdjustmentsActionType.LOAD_REQUEST:
      return {
        ...state,
        adjustments: [],
        status: EntityStatus.NOT_LOADED
      };
    case GlassAdjustmentsActionType.LOAD_PROGRESS:
      return {
        ...state,
        adjustments: [],
        status: EntityStatus.LOAD_IN_PROGRESS
      };
    case GlassAdjustmentsActionType.SET_ACTIVE:
      return {
        ...state,
        activeAdjustment: action.adjustment
      };
    case GlassAdjustmentsActionType.SET_URL_ALIAS:
      return {
        ...state,
        adjustmentUrlAlias: action.alias
      };
    default:
      return state;
  }
};